import { Referrer } from '@app/modules/superMortgage/types';

export enum UserRole {
  Broker = 'broker',
  BrokerManager = 'broker_manager',
  Manager = 'manager',
  Limited = 'limited',
  InternalBroker = 'internal_broker',
  PartialAcess = 'partial_broker_access'
}

export enum BrokerType {
  ExternalBroker = 'external_broker',
  InternalBroker = 'internal_broker'
}

export interface CompanyInfoRes {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  company_name: string;
  primary_color: string;
  company_logo: string;
  company_id: number;
  is_freelancing_company: boolean;
  total_users: number;
  managed_brokers: Referrer[];
}

export interface BrokerResponse {
  device_token: string;
  roles: string[];
  is_granted_access_to_mb_portal: string;
  user_type: string;
}

export interface BrokerRequestAccess {
  authenticatorId: string;
  userProfile : {
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    terms_conditions_version: string;
    accept_terms_conditions: string;
  };
}

export interface DecodeHIDRequest {
  clientType: string,
  hid: string
}

export interface DecodeHIDResponse {
  token: string;
  refresh_token: string;
  op?: string;
  met?: string;
}

export type DirectFeatureFlagResponse = {
  [flagKey: string]: {
    key: string;
  }
};

export const AMPLITUDE_FEATURE_FLAGS = {
  CLIENT_HUB_BANK_PRODUCTS: 'client-hub-bank-products',
  CLIENT_HUB_ACCOUNT_MANAGEMENT: 'client-hub-account-management',
} as const;

export type FeatureFlagResponse = {
  [K in keyof typeof AMPLITUDE_FEATURE_FLAGS as typeof AMPLITUDE_FEATURE_FLAGS[K]]: {
    key: string;
  }
};

export type FeatureFlags = {
  [K in keyof typeof AMPLITUDE_FEATURE_FLAGS as typeof AMPLITUDE_FEATURE_FLAGS[K]]: boolean
};
