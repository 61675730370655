import { BankAndRates, SelectedBankAndRate } from '@app/modules/bankProducts/domain/bankAndRates';
import type { RootState } from '@app/modules/core/infrastructure/store';
import { createSlice } from '@reduxjs/toolkit';
import { Bank } from '../domain/bank';
import { MortgageCaseAPIFullRes } from '../domain/mortgageApplicationApiRes';
// TODO: Cyclic dependencies
// eslint-disable-next-line import/no-cycle
import { matchSaveSelectedBanksUpdated, matchSingleCaseApplicationReceived } from './mortgageSlice';

const INITIAL_STATE: {
  banksList: Bank[];
  selectedBanks: SelectedBankAndRate[];
  mainSelectedBank: BankAndRates | null;
} = {
  banksList: [],
  selectedBanks: [],
  mainSelectedBank: null,
};

const caseBanksSelectionSlice = createSlice({
  name: 'caseBanksSelection',
  initialState: INITIAL_STATE,
  reducers: {
    setBanksList: (state, action) => {
      state.banksList = action.payload;
    },
    setSelectedBanks: (state, action) => {
      state.selectedBanks = action.payload;
    },
    setMainSelectedBank: (state, action) => {
      state.mainSelectedBank = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(matchSingleCaseApplicationReceived, (state, action) => {
        const { selectedBanks, mainSelectedBank } = action.payload as MortgageCaseAPIFullRes;
        state.selectedBanks = selectedBanks;
        state.mainSelectedBank = mainSelectedBank;
        // action is inferred correctly here if using TS
      })
      .addMatcher(matchSaveSelectedBanksUpdated, (state, action) => {
        state.selectedBanks = action.payload;
      });
  },
});

export const { setBanksList, setSelectedBanks, setMainSelectedBank } = caseBanksSelectionSlice.actions;
export default caseBanksSelectionSlice.reducer;
export const selectCaseBanks = (state: RootState) => state.mortgage.caseBanksDetails;
