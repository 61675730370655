/* eslint-disable max-len */
import { selectCurrentUser } from '@app/modules/core/infrastructure/authSlice';
import { superMortgageApi } from '@app/modules/superMortgage/api';
import { useQuery } from '@tanstack/react-query';
import React, {
  createContext, FunctionComponent, useState
} from 'react';
import { useSelector } from 'react-redux';
import { FeatureFlags } from '@app/modules/superMortgage/types';
import useGetFeatureFlags from '@app/modules/core/hooks/useFeatureFlags';

export type CES = 'proposal' | 'case submission' | null;

interface GlobalContextInterface {
  isSideBarOpen: boolean;
  setSideBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isSidebarHidden: boolean;
  setWelcomeModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  welcomeModalVisible: boolean;
  setProposalToCaseModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  proposalToCaseModalVisible: boolean;
  setSidebarHidden: React.Dispatch<React.SetStateAction<boolean>>;
  unsavedChanges: boolean;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  brokerCompanyLogo: string | null;
  setBrokerCompanyLogo: React.Dispatch<React.SetStateAction<string | null>>;
  customerEffortScoreType: CES;
  setCustomerEffortScoreType: React.Dispatch<React.SetStateAction<CES>>;
  showBankProductsDialog: boolean;
  setShowBankProductsDialog: React.Dispatch<React.SetStateAction<boolean>>;
  activeWidgetsList: any;
  setActiveWidgetsList: React.Dispatch<React.SetStateAction<any>>;
  activeWidgetsDisplayedList: any;
  setActiveWidgetsDisplayedList: React.Dispatch<React.SetStateAction<any>>;
  companyName: string;
  setCompanyName: React.Dispatch<React.SetStateAction<string>>;
  brokerName: string;
  setBrokerName: React.Dispatch<React.SetStateAction<string>>;
  companyTotalUsers: number;
  setCompanyTotalUsers: React.Dispatch<React.SetStateAction<number>>;
  managedBrokers: Record<string, string>;
  setManagedBrokers: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  isBetaUser: boolean;
  isLoadingBetaFeatureFlag: boolean;
  isOpportunityUser: boolean;
  userHasClientHubAccess: boolean;
  userHasClientHubProposalsAccess: boolean;
  featureFlags: FeatureFlags
}

export const GlobalContext = createContext<GlobalContextInterface>(
  {
    isBetaUser: false,
    isLoadingBetaFeatureFlag: true,
    isOpportunityUser: false,
  } as GlobalContextInterface
);

const { Provider } = GlobalContext;

export const GlobalProvider: FunctionComponent = ({ children }) => {
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [isSideBarOpen, setSideBarOpen] = useState(false);
  const [isSidebarHidden, setSidebarHidden] = useState(true);
  const [welcomeModalVisible, setWelcomeModalVisible] = useState(false);
  const [proposalToCaseModalVisible, setProposalToCaseModalVisible] = useState(false);
  const [brokerCompanyLogo, setBrokerCompanyLogo] = useState<string | null>('');
  const [customerEffortScoreType, setCustomerEffortScoreType] = useState<CES>(null);
  const [showBankProductsDialog, setShowBankProductsDialog] = useState(false);
  const [activeWidgetsList, setActiveWidgetsList] = useState(['topbar']);
  const [activeWidgetsDisplayedList, setActiveWidgetsDisplayedList] = useState([]);
  const [companyTotalUsers, setCompanyTotalUsers] = useState(0);
  const [managedBrokers, setManagedBrokers] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const [brokerName, setBrokerName] = useState('');
  const user = useSelector(selectCurrentUser);
  const { data, isLoading } = useQuery({
    queryKey: ['isBetaUser', user?.profile?.email],
    queryFn: () => superMortgageApi.checkIfUserHasBetaAccess(),
    enabled: !!user?.profile?.email,
    staleTime: Number.POSITIVE_INFINITY,
  });

  const { data: featureFlags } = useQuery({
    queryKey: ['getFeatureFlags', user?.profile?.email],
    queryFn: () => superMortgageApi.getFeatureFlags(),
    enabled: !!user?.profile?.email,
    staleTime: Number.POSITIVE_INFINITY,
  });

  const { data: amplitudeFeatureFlags } = useGetFeatureFlags(user?.profile?.email);

  return (
    <Provider
      value={ {
        isSideBarOpen,
        setSideBarOpen,
        isSidebarHidden,
        setSidebarHidden,
        setWelcomeModalVisible,
        welcomeModalVisible,
        proposalToCaseModalVisible,
        setProposalToCaseModalVisible,
        unsavedChanges,
        setUnsavedChanges,
        brokerCompanyLogo,
        setBrokerCompanyLogo,
        customerEffortScoreType,
        setCustomerEffortScoreType,
        showBankProductsDialog,
        setShowBankProductsDialog,
        activeWidgetsList,
        setActiveWidgetsList,
        activeWidgetsDisplayedList,
        setActiveWidgetsDisplayedList,
        companyName,
        setCompanyName,
        brokerName,
        setBrokerName,
        companyTotalUsers,
        setCompanyTotalUsers,
        managedBrokers,
        setManagedBrokers,
        isBetaUser: data,
        isLoadingBetaFeatureFlag: isLoading,
        isOpportunityUser: true,
        userHasClientHubAccess: featureFlags?.has_access_to_client_hub,
        userHasClientHubProposalsAccess: featureFlags?.has_access_to_client_hub_proposals,
        featureFlags: {
          ...featureFlags,
          ...amplitudeFeatureFlags,
        },
      } }
    >
      {children}
    </Provider>
  );
};
