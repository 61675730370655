// TODO: Dependency cycle
// eslint-disable-next-line import/no-cycle
import { apiSlice } from '@app/modules/core/infrastructure/api/apiSlice';
import {
  DOC_CENTER_ADDITIONAL_ENDPOINT,
  DOC_CENTER_DOWNLOAD_ENDPOINT,
  DOC_CENTER_LIST_ENDPOINT,
} from '@app/modules/core/infrastructure/api/constants';
import { BankDocumentsAPIRes, FormatedBankDocument } from '@app/modules/documents/domain/documents';

export const documentsCenterApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBankDocuments: builder.query<FormatedBankDocument[], void>({
      queryFn: async (_arg, _queryApi, _extraOptions, baseQuery) => {
        const [{ data: bankDocuments }, { data: additionalDocuments }] = await Promise.all([
          baseQuery(DOC_CENTER_LIST_ENDPOINT),
          baseQuery(DOC_CENTER_ADDITIONAL_ENDPOINT)
        ]);

        // format data
        const formatedData: FormatedBankDocument[] = [];

        const { response: documents } = bankDocuments as BankDocumentsAPIRes;
        const { response: additionalBankDocuments } = additionalDocuments as BankDocumentsAPIRes;
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in documents) {
          formatedData.push({
            bank: key,
            CONVENTIONAL: {
              documents: documents[key]?.CONVENTIONAL,
              additionalDocuments: additionalBankDocuments[key]
                ? additionalBankDocuments[key]?.CONVENTIONAL
                : [],
            },
            ISLAMIC: {
              documents: documents[key]?.ISLAMIC,
              additionalDocuments: additionalBankDocuments[key]
                ? additionalBankDocuments[key]?.ISLAMIC
                : [],
            },
            logo: documents[key]?.logo,
          });
        }

        return { data: formatedData };
      },
      providesTags: ['Documents'],
      keepUnusedDataFor: 86400, // 24 hours
    }),
    downloadZippedDocuments: builder.mutation<any, { bankType: string; bank: string }>({
      queryFn: async ({ bankType, bank }, _queryApi, _extraOptions, baseQuery) => baseQuery({
        url: `${DOC_CENTER_DOWNLOAD_ENDPOINT}?mortgage_type=${bankType.toUpperCase()}&bank=${bank}`,
        responseHandler: async (response: any) => {
          const blob = await response.blob();
          return window.URL.createObjectURL(blob);
        },
        cache: 'no-cache',
      }),
    }),
  }),
});

export const { useGetBankDocumentsQuery, useDownloadZippedDocumentsMutation } = documentsCenterApiSlice;
