/* eslint-disable unicorn/consistent-destructuring */
/* eslint-disable no-unsafe-optional-chaining */
import {
  Dependent,
  MortgageCaseAPIFullRes,
  PersonalDetails,
  PropertyAndMortgage,
} from '@app/modules/mortgage/domain/mortgageApplicationApiRes';
import { MortgageCase, MortgageCaseApiRes } from '@app/modules/mortgage/domain/mortgageCase';
import { apiMoneyFormatter } from '@app/utils/helpers/apiMoneyFormatter';

const extractCaseInfoFromApi = (brokerCase: MortgageCaseApiRes): MortgageCase => ({
  applicationId: brokerCase?.mortgageApplicationId,
  firstName: brokerCase?.firstName,
  lastName: brokerCase?.lastName,
  caseStatus: brokerCase?.maStatus,
  createdAt: brokerCase?.createdAt,
  joinedAt: brokerCase?.dateJoined,
  loanAmount: brokerCase?.loanAmount,
  isCaseSubmitted: brokerCase?.isCaseSubmitted,
  selectedBanks: brokerCase?.selectedBanks,
  submittedBy: brokerCase?.submittedBy,
  bankApplicationType: brokerCase?.bankApplicationType,
  mainApplicantAllSubmittedBanks: brokerCase?.mainApplicantAllSubmittedBanks,
  mainApplicantId: brokerCase?.cognitoId,
});

const extractMainApplicantInfo = (brokerCase: MortgageCaseAPIFullRes) => {
  const {
    firstName, lastName, email, mobile: phoneNumber, cognitoId: id,
  } = brokerCase?.userData;
  const {
    birthDate: date_of_birth,
    nationality: country,
    typeOfEmployment,
    citizenState,
    id: personalDetailsId,
  } = brokerCase?.personalDetails;
  return {
    first_name: firstName,
    last_name: lastName,
    email,
    phone_number: phoneNumber,
    date_of_birth,
    country,
    employment_status: typeOfEmployment,
    citizen_state: citizenState,
    id,
    personalDetailsId,
  };
};

export const extractDependentsInfo = (dependents: Dependent[]) => {
  const dependentsInfo = dependents?.map((dependent) => {
    const {
      relationWithMainApplicant,
      mainApplicantUuid: mainApplicantId,
      dependentType,
    } = dependent;
    const {
      firstName, lastName, email, mobile, cognitoId: id,
    } = dependent?.user;
    const { typeOfEmployment, citizenState } = dependent?.personalDetails;

    const dependentObj = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone_number: mobile,
      employment_status: typeOfEmployment,
      citizen_state: citizenState,
      relation_with_main_applicant: relationWithMainApplicant,
      dependent_type: dependentType,
      id,
      mainApplicantId,
    };
    // eslint-disable-next-line sonarjs/no-duplicate-string
    if (dependentType === 'co-borrower') {
      dependentObj.employment_status = typeOfEmployment;
    }
    return dependentObj;
  }) ?? [];

  const coBorrowers = dependentsInfo?.filter(
    (dependent) => dependent?.dependent_type === 'co-borrower'
  );
  const coApplicants = dependentsInfo?.filter(
    (dependent) => dependent?.dependent_type === 'co-applicant'
  );
  return {
    coBorrowers,
    coApplicants,
  };
};

const extractIncomeInfo = (personalDetails: PersonalDetails) => {
  // TODO continue from here - extract income info
  const {
    id,
    basicFixedSalary,
    additionalMonthlyIncome,
    additionalIncomeType,
    totalValueOfCreditCardLimits,
    totalValueExistingMortgage,
    typeOfEmployment,
  } = personalDetails;
  return {
    id,
    ...(typeOfEmployment === 'SELF EMPLOYMENT'
      ? {}
      : { basic_fixed_salary: apiMoneyFormatter(basicFixedSalary) }),
    additional_monthly_income: apiMoneyFormatter(additionalMonthlyIncome),
    additional_income_type: additionalIncomeType,
    total_value_of_credit_card_limits: apiMoneyFormatter(totalValueOfCreditCardLimits),
    total_value_existing_mortgage: apiMoneyFormatter(totalValueExistingMortgage),
  };
};

const extractMortgageInfo = (propertyAndMortgage: PropertyAndMortgage) => {
  const {
    id,
    typeOfTransaction: transactionType,
    typeOfMortgage: mortgageType,
    loanAmount,
    mortgageContractYears,
    downPaymentPercentage,
    isFeeFinanced,
    valueOfProperty: propertyValue,
    propertyStatus,
    emirate,
  } = propertyAndMortgage;
  return {
    id,
    is_fee_financed: isFeeFinanced === 'yes',
    type_of_transaction: transactionType,
    type_of_mortgage: mortgageType,
    loan_amount: apiMoneyFormatter(loanAmount),
    mortgage_contract_years: mortgageContractYears,
    down_payment_percentage: apiMoneyFormatter(downPaymentPercentage),
    value_of_property: apiMoneyFormatter(propertyValue),
    property_status: propertyStatus,
    emirate,
  };
};

const extractCaseClientDetailsInfoFromApi = (brokerCase: MortgageCaseAPIFullRes) => {
  // Case Client Details Info - First Step
  const mainApplicant = extractMainApplicantInfo(brokerCase);
  const { coApplicants, coBorrowers } = extractDependentsInfo(brokerCase.dependents);

  return {
    clientDetails: {
      mainApplicant,
      coBorrowers,
      coApplicants,
    },
  };
};

const extractIncomeMortgageInfoFromApi = (brokerCase: MortgageCaseAPIFullRes) => {
  const mainApplicant = extractMainApplicantInfo(brokerCase);
  const mainApplicantIncome = extractIncomeInfo(brokerCase?.personalDetails);
  const dependentsIncome = brokerCase?.dependents
    ?.filter((dep) => dep?.dependentType === 'co-borrower')
    .map((dependent: Dependent) => ({
      name: `${dependent?.user?.firstName} ${dependent?.user?.lastName}`,
      personalDetailsId: dependent?.personalDetails?.id,
      income: extractIncomeInfo(dependent?.personalDetails),
      dependentType: dependent?.dependentType,
      employment_status: dependent?.personalDetails?.typeOfEmployment,
    }));
  const mortgageInfo = extractMortgageInfo(brokerCase?.propertyAndMortgage);

  return {
    incomeDetails: {
      mainApplicant: {
        name: `${mainApplicant?.first_name} ${mainApplicant?.last_name}`,
        personalDetailsId: mainApplicant?.personalDetailsId,
        income: mainApplicantIncome,
        employmentType: mainApplicant?.employment_status,
      },
      dependentsIncome,
    },
    mortgageInfo,
  };
};

export {
  extractCaseClientDetailsInfoFromApi, extractCaseInfoFromApi, extractIncomeMortgageInfoFromApi
};
