import Box from '@app/components/common/basic/Containers/Box';
import theme from '@app/style/theme';

type Props = {
  color: string;
  w: number | string;
};

function HuspyLogo({ color = theme.colors.purple700, w, ...props }: Props) {
  return (
    <Box m='0 0 -0.5rem' { ...props }>
      <svg
        width={ w || '100%' }
        height='32'
        viewBox='0 0 93 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M19.2197 0.504181V23.2745H14.6002V15.7062L9.66722 13.2595L4.73425 15.7062V23.2745H0.114746V0.504181H4.73425V10.0299L9.66722 8.07255L14.6002 10.0299V0.504181H19.2197Z'
          fill={ color }
        />
        <path
          d='M38.3839 5.03868V23.1114H33.7314V20.8278C33.1375 21.6108 32.3566 22.2306 31.3887 22.6873C30.4428 23.1223 29.4089 23.3398 28.287 23.3398C26.8572 23.3398 25.5923 23.0462 24.4924 22.459C23.3925 21.85 22.5236 20.9692 21.8857 19.8166C21.2698 18.6422 20.9618 17.2503 20.9618 15.6409V5.03868H25.5813V14.9885C25.5813 16.4238 25.9443 17.533 26.6702 18.3159C27.3961 19.0771 28.386 19.4577 29.6399 19.4577C30.9157 19.4577 31.9166 19.0771 32.6425 18.3159C33.3685 17.533 33.7314 16.4238 33.7314 14.9885V5.03868H38.3839Z'
          fill={ color }
        />
        <path
          d='M47.2973 23.405C45.8015 23.405 44.4596 23.144 43.2718 22.6221C42.0839 22.0784 41.138 21.3498 40.4341 20.4364C39.7522 19.523 39.3782 18.5117 39.3122 17.4025H43.9647C44.0527 18.0985 44.3937 18.6748 44.9876 19.1315C45.6035 19.5882 46.3624 19.8166 47.2643 19.8166C48.1442 19.8166 48.8262 19.6426 49.3101 19.2946C49.8161 18.9466 50.069 18.5008 50.069 17.9571C50.069 17.3699 49.7611 16.9349 49.1451 16.6522C48.5512 16.3477 47.5943 16.0215 46.2744 15.6735C44.9106 15.3473 43.7887 15.0102 42.9088 14.6622C42.0509 14.3143 41.303 13.7814 40.6651 13.0638C40.0491 12.3461 39.7411 11.3783 39.7411 10.1604C39.7411 9.15995 40.0271 8.24653 40.5991 7.4201C41.193 6.59367 42.0289 5.94122 43.1068 5.46276C44.2067 4.9843 45.4935 4.74507 46.9674 4.74507C49.1451 4.74507 50.883 5.28878 52.1808 6.37619C53.4787 7.44185 54.1936 8.8881 54.3256 10.7149H49.9041C49.8381 9.99726 49.5301 9.4318 48.9802 9.01859C48.4522 8.58363 47.7373 8.36614 46.8354 8.36614C45.9995 8.36614 45.3505 8.51838 44.8886 8.82286C44.4486 9.12733 44.2287 9.55142 44.2287 10.0951C44.2287 10.7041 44.5366 11.1717 45.1526 11.4979C45.7685 11.8024 46.7254 12.1177 48.0233 12.4439C49.3431 12.7701 50.432 13.1072 51.2899 13.4552C52.1478 13.8032 52.8847 14.3469 53.5007 15.0863C54.1386 15.804 54.4686 16.7609 54.4906 17.9571C54.4906 19.001 54.1936 19.9362 53.5997 20.7626C53.0277 21.589 52.1918 22.2415 51.0919 22.7199C50.014 23.1767 48.7492 23.405 47.2973 23.405Z'
          fill={ color }
        />
        <path
          d='M60.2624 7.64845C60.8563 6.82202 61.6702 6.13696 62.7041 5.59325C63.76 5.0278 64.9589 4.74507 66.3007 4.74507C67.8625 4.74507 69.2704 5.12567 70.5243 5.88685C71.8001 6.64804 72.801 7.73545 73.5269 9.14908C74.2748 10.541 74.6488 12.1612 74.6488 14.0098C74.6488 15.8584 74.2748 17.5004 73.5269 18.9358C72.801 20.3494 71.8001 21.4477 70.5243 22.2306C69.2704 23.0135 67.8625 23.405 66.3007 23.405C64.9589 23.405 63.771 23.1332 62.7371 22.5895C61.7252 22.0457 60.9003 21.3607 60.2624 20.5342V31.7237H55.6429V5.03868H60.2624V7.64845ZM69.9303 14.0098C69.9303 12.9224 69.6993 11.9872 69.2374 11.2043C68.7974 10.3996 68.2035 9.79065 67.4556 9.37743C66.7297 8.96422 65.9378 8.75761 65.0798 8.75761C64.2439 8.75761 63.452 8.97509 62.7041 9.41006C61.9782 9.82327 61.3842 10.4322 60.9223 11.2369C60.4823 12.0416 60.2624 12.9876 60.2624 14.075C60.2624 15.1624 60.4823 16.1085 60.9223 16.9132C61.3842 17.7179 61.9782 18.3377 62.7041 18.7726C63.452 19.1859 64.2439 19.3925 65.0798 19.3925C65.9378 19.3925 66.7297 19.175 67.4556 18.74C68.2035 18.3051 68.7974 17.6852 69.2374 16.8806C69.6993 16.0759 69.9303 15.119 69.9303 14.0098Z'
          fill={ color }
        />
        <path
          d='M92.8541 5.03868L81.5363 31.6584H76.6198L80.5794 22.6547L73.2542 5.03868H78.4346L83.1531 17.6635L87.9376 5.03868H92.8541Z'
          fill={ color }
        />
      </svg>
    </Box>
  );
}

export default HuspyLogo;
