import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  BankProductAPIRes,
  BankProductsAPIResponse,
  BankProductsProps,
  BanksListAPIRes,
  EiborRatesRes,
  reportBankRateReqProps,
} from '@app/modules/bankProducts/domain/models/bankProduct';
// TODO: Dependency cycle
// eslint-disable-next-line import/no-cycle
import { apiSlice } from '@app/modules/core/infrastructure/api/apiSlice';
import {
  BANKS_LIST_ENDPOINT,
  BANK_PRODUCTS_ENDPOINT,
  EIBOR_RATES_ENDPOINT,
  EIBOR_RATES_FEEDBACK_ENDPOINT,
  REPORT_BANK_PRODUCT_RATES_ENDPOINT,
} from '@app/modules/core/infrastructure/api/constants';
import {
  getNextPage, getQueryParams, makeFiltersAsQueryParams
} from '../bankProductHelpers';
import { BankProductsFilters } from '../../types';

export const bankProductsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBankProductsList: builder.query<BankProductsAPIResponse, BankProductsProps>({
      queryFn: async (
        {
          currentPage, loanAmount, mortgageYears, filters, orderBy,
        },
        _queryApi,
        _extraOptions,
        baseQuery
      ) => {
        const processedFilters = filters && makeFiltersAsQueryParams(filters as BankProductsFilters);
        const queryParams = getQueryParams({
          pageParam: currentPage,
          loanAmount,
          mortgageYears,
          filters: processedFilters,
          orderBy,
          url: BANK_PRODUCTS_ENDPOINT,
        });
        const result = await baseQuery({ url: queryParams, refetchOnMountOrArgChange: true });

        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const response = result?.data as BankProductAPIRes;
        return { data: { ...response, nextPage: getNextPage(response?.next) } };
      },
    }),
    getBankList: builder.query<BanksListAPIRes, void>({
      queryFn: async (_arg, _queryApi, _extraOptions, baseQuery) => {
        const result = await baseQuery(BANKS_LIST_ENDPOINT);
        return { data: result as BanksListAPIRes };
      },
    }),
    getEiborRates: builder.query<EiborRatesRes, void>({
      queryFn: async (_arg, _queryApi, _extraOptions, baseQuery) => {
        const result = await baseQuery(`${EIBOR_RATES_ENDPOINT}/`);
        return { data: result.data as EiborRatesRes };
      },
    }),
    submitEiborRatesFeedback: builder.mutation({
      query: (data) => ({
        url: EIBOR_RATES_FEEDBACK_ENDPOINT,
        method: 'POST',
        body: data,
        contentType: 'application/json',
      }),
    }),
    reportBankProductRateReq: builder.mutation<any, reportBankRateReqProps>({
      queryFn: async (data, _queryApi, _extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: REPORT_BANK_PRODUCT_RATES_ENDPOINT,
          method: 'POST',
          body: data,
          contentType: 'application/json',
        });
        return { data: result.data };
      },
    }),
  }),
});

export const {
  useLazyGetBankProductsListQuery,
  useGetBankListQuery,
  useGetEiborRatesQuery,
  useSubmitEiborRatesFeedbackMutation,
  useReportBankProductRateReqMutation,
} = bankProductsApiSlice;
