import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '@app/modules/core/infrastructure/store';
import {
  CaseIncomeMortgageInfo, IncomeFields, MortgageInfo
} from '../domain/mortgageCase';
import { extractIncomeMortgageInfoFromApi } from './api/mortgageApiAdapters';
// eslint-disable-next-line import/no-cycle
import {
  matchSaveIncomeDataUpdated,
  matchSaveMortgageDataUpdated,
  matchSingleCaseApplicationReceived,
} from './mortgageSlice';

const INITIAL_STATE: CaseIncomeMortgageInfo = {
  mainApplicantIncome: {
    name: '',
    income: {},
    personalDetailsId: 0,
  },
  dependentsIncome: [],
  mortgage: {
    emirate: null,
    property_status: null,
    value_of_property: null,
    down_payment_percentage: '',
    loan_amount: null,
    type_of_mortgage: null,
    mortgage_contract_years: null,
    is_fee_financed: null,
    type_of_transaction: null,
  },
};

const caseIncomeMortgageSlice = createSlice({
  name: 'caseIncomeMortgageDetails',
  initialState: INITIAL_STATE,
  reducers: {
    saveMainApplicantIncomeInfo: (state, action) => {
      const { fieldName, fieldValue } = action.payload as {
        fieldName: keyof IncomeFields;
        fieldValue: any;
      };
      state.mainApplicantIncome.income[fieldName] = fieldValue;
    },
    saveDependentIncomeInfo: (state, action) => {
      const { index, fieldName, fieldValue } = action.payload as {
        index: number;
        fieldName: keyof IncomeFields;
        fieldValue: any;
      };
      state.dependentsIncome[index].income[fieldName] = fieldValue;
    },
    saveMortgageInfo: (state, action) => {
      const { fieldName, fieldValue } = action.payload as {
        fieldName: keyof MortgageInfo;
        fieldValue: any;
      };
      state.mortgage[fieldName as string] = fieldValue;
      state.mortgage = action.payload;
    },
  },
  extraReducers: async (builder) => {
    builder
      .addMatcher(matchSingleCaseApplicationReceived, (state, action) => {
        const {
          incomeDetails: { mainApplicant, dependentsIncome },
          mortgageInfo,
        } = extractIncomeMortgageInfoFromApi(action.payload);
        state.mainApplicantIncome = mainApplicant;
        state.dependentsIncome = dependentsIncome;
        state.mortgage = mortgageInfo;
        // action is inferred correctly here if using TS
      })
      .addMatcher(matchSaveIncomeDataUpdated, (state, action) => {
        const userType = action.meta.arg.originalArgs.type;
        if (userType === 'main') {
          state.mainApplicantIncome.income = {
            id: action.payload.id,
            ...action.meta.arg.originalArgs.body,
          };
        } else {
          const index = state.dependentsIncome.findIndex(
            (item) => item.personalDetailsId === action.payload.id
          );
          state.dependentsIncome[index].income = {
            id: action.payload.id,
            ...action.meta.arg.originalArgs.body,
          };
        }
      })
      .addMatcher(matchSaveMortgageDataUpdated, (state, action) => {
        state.mortgage = {
          id: action.payload.id,
          ...action.meta.arg.originalArgs.body,
        };
      });
  },
});

export const { saveMainApplicantIncomeInfo, saveDependentIncomeInfo, saveMortgageInfo } = caseIncomeMortgageSlice.actions;
export default caseIncomeMortgageSlice.reducer;
export const selectCaseIncomeMortgageDetails = (state: RootState) => state.mortgage.caseIncomeMortgageDetails;
