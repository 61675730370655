import { createSlice } from '@reduxjs/toolkit';
import { CountryType } from '@app/modules/core/domain/enums/countryType';
import type { RootState } from '@app/modules/core/infrastructure/store';
import { ClientApiModel } from '../domain/client';
import { extractCaseClientDetailsInfoFromApi, extractDependentsInfo } from './api/mortgageApiAdapters';
// eslint-disable-next-line import/no-cycle
import { matchSingleCaseApplicationReceived } from './mortgageSlice';
import { mortgageApiSlice } from './api/mortgageApiSlice';
import { MortgageDependent } from './types/dependent.types';

const INITIAL_APPLICANT_INFO: ClientApiModel = {
  first_name: '',
  last_name: '',
  email: '',
  phone_number: null,
  date_of_birth: '',
  country: '',
  employment_status: '',
  citizen_state: '',
};

const INITIAL_STATE: {
  mainApplicant: ClientApiModel;
  coBorrowers: ClientApiModel[];
  coApplicants: ClientApiModel[];
  countries: CountryType[];
  dependents: MortgageDependent[];
} = {
  mainApplicant: INITIAL_APPLICANT_INFO,
  coBorrowers: [],
  coApplicants: [],
  countries: [],
  dependents: [],
};

export const matchDependentsUpdated = mortgageApiSlice.endpoints.registerDependents.matchFulfilled;

const caseClientDetailsSlice = createSlice({
  name: 'caseClientDetails',
  initialState: INITIAL_STATE,
  reducers: {
    saveMainApplicantInfo: (state, action) => {
      state.mainApplicant = action.payload;
    },
    saveDependentsInfo: (state, action) => {
      const { coBorrowers, coApplicants } = action.payload;
      state.coBorrowers = coBorrowers;
      state.coApplicants = coApplicants;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(matchSingleCaseApplicationReceived, (state, action) => {
      const { clientDetails: { mainApplicant, coApplicants, coBorrowers } } = extractCaseClientDetailsInfoFromApi(action?.payload);
      state.mainApplicant = mainApplicant;
      state.coApplicants = coApplicants;
      state.coBorrowers = coBorrowers;
      state.dependents = action.payload.dependents;
      // action is inferred correctly here if using TS
    }).addMatcher(matchDependentsUpdated, (state, action) => {
      const { coApplicants, coBorrowers } = extractDependentsInfo(action?.payload);
      state.coApplicants = [...state.coApplicants, ...coApplicants];
      state.coBorrowers = [...state.coBorrowers, ...coBorrowers];
      state.dependents = [...state.dependents, ...action.payload];
    });
  },
});

export const { saveMainApplicantInfo, saveDependentsInfo } = caseClientDetailsSlice.actions;
export default caseClientDetailsSlice.reducer;
export const selectCaseClientDetails = (state: RootState) => state.mortgage.caseClientDetails;
