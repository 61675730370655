// TODO: Dependency cycle
// eslint-disable-next-line import/no-cycle
import { apiSlice } from '@app/modules/core/infrastructure/api/apiSlice';
import { ACCOUNT_MANAGEMENT_ENDPOINT } from '@app/modules/core/infrastructure/api/constants';

export const accountManagementApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAccountInfo: builder.query<any, void>({
      query: () => ACCOUNT_MANAGEMENT_ENDPOINT,
      providesTags: ['AccountSettings'],
    }),
    updateAccountInfo: builder.mutation<any, any>({
      query: (data: any) => ({
        url: ACCOUNT_MANAGEMENT_ENDPOINT,
        body: data,
        method: 'PUT',
        contentType: 'application/json',
      }),
      invalidatesTags: (result, error, args) => {
        console.log({ result, error, args });
        return ['AccountSettings'];
      },
    }),
  }),
});

export const { useGetAccountInfoQuery, useUpdateAccountInfoMutation } = accountManagementApiSlice;
