/* eslint-disable no-param-reassign */
import { FetchBankProductsProps } from '../domain/models/bankProduct';
import { BankProductsFilters } from '../types';

export const makeFiltersAsQueryParams = (
  filters: BankProductsFilters | Record<string, any>
// eslint-disable-next-line sonarjs/cognitive-complexity
): Record<string, any>[] => {
  const queryParam: Record<string, any>[] = [];
  if (JSON.stringify(filters).length === 0) return queryParam;

  Object.entries(filters).forEach(([key, value]) => {
    // Handle Selected CheckBoxes
    if (typeof value === 'object') {
      if (key === 'is_exclusive' && value.true && value.false) {
        value = {};
      }
      if (key === 'type_of_transaction' && value['PRIMARY/RESALE/HANDOVER']) {
        value = {
          ...value,
          'PRIMARY PURCHASE': true,
          'BUY A PROPERTY': true,
          'FINALE HANDOVER PAYMENT': true,
          'HANDOVER RESALE': true,
        };
        delete value['PRIMARY/RESALE/HANDOVER'];
      }
      Object.entries(value)
        .filter(([, v]) => v === true)
        .forEach(([item]) => {
          // TODO REMOVE THIS WHEN UAE NATIONALS RETURN PRODUCTS
          item = item === 'UAE NATIONAL' ? 'UAE RESIDENT' : item;
          queryParam.push({ [key]: item });
        });
    } else {
      queryParam.push({ [key]: value });
    }
  });
  return queryParam;
};

export const getQueryParams = (props: FetchBankProductsProps): string => {
  const {
    pageParam, loanAmount, mortgageYears, filters, orderBy, url,
  } = props;

  const qParams = new URLSearchParams();

  if (Array.isArray(filters)) {
    filters.forEach((obj) => {
      Object.entries(obj).forEach(([k, v]) => {
        qParams.append(k, v as string);
      });
    });
  }

  if (loanAmount) {
    qParams.append('loan_amount', `${Number(loanAmount)}`);
  }
  if (mortgageYears) {
    qParams.append('mortgage_contract_months', `${mortgageYears}`);
  }
  if (orderBy) {
    qParams.append('order_by', `${orderBy}`);
  }

  // eslint-disable-next-line sonarjs/no-nested-template-literals
  return `${url}?offset=${pageParam}${filters && `&${qParams.toString()}`}`;
};

export const getNextPage = (nextLink: string): number => {
  const urlSearchParams = new URLSearchParams(nextLink);
  const params = Object.fromEntries(urlSearchParams.entries());

  return +params.offset;
};
