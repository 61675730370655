const InitialLogo = () => (
  <svg fill='none' height='100' width='100'>
    <path
      d='M19.2197 0.504181V23.2745H14.6002V15.7062L9.66722 13.2595L4.73425 15.7062V23.2745H0.114746V0.504181H4.73425V10.0299L9.66722 8.07255L14.6002 10.0299V0.504181H19.2197Z'
      fill='#205EFF'
      transform='scale(3.5 3)'
    />
  </svg>
);

export default InitialLogo;
