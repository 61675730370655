import Box from '@app/components/common/basic/Containers/Box';
import { IconProps } from '@app/modules/core/domain/svgIcon';
import theme from '@app/style/theme';

interface CheckCircleProps extends IconProps {
  isFaded: boolean;
}

const CheckCircle = ({ size = 16, isFaded }: CheckCircleProps) => {
  let backgroundColor = theme.colors.success;
  let strokeColor = theme.colors.white;

  if (isFaded) {
    backgroundColor = theme.colors.successLight;
    strokeColor = theme.colors.success;
  }

  return (
    <Box w={ size } h={ size } m='0 0 -0.5rem 0'>
      <svg
        width={ size }
        height={ size }
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='8' cy='8' r='8' fill={ backgroundColor } />
        <path
          d='M12 5L6.5 10.5L4 8'
          stroke={ strokeColor }
          strokeWidth='1.4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Box>
  );
};

export default CheckCircle;
