const theme = {
  breakpoints: ['426px', '769px', '1001px', '1441px', '1801px'],
  colors: {
    huspyBlue100: '#E4ECFF',
    huspyBlue200: '#CAD8FA',
    huspyBlue300: '#A1BDFF',
    huspyBlue400: '#6F99FF',
    huspyBlue500: '#2655F5', // Main
    huspyBlue600: '#1F4DEA',
    huspyBlue700: '#1741DD',
    huspyBlue800: '#1136D2',
    huspyBlue900: '#031FC3',

    blue100: '#EEF2FD',

    purple100: '#F1EDFD',
    purple200: '#C6B9F7',
    purple300: '#AA96F2',
    purple400: '#8D73EE',
    purple500: '#7150EA', // Main
    purple600: '#603BE7',
    purple700: '#4E24E5',
    purple800: '#421AD6',
    purple900: '#3515AD',

    yellow100: '#FEF6DA',
    yellow200: '#FCEEB6',
    yellow300: '#FBE591',
    yellow400: '#F9DD6D',
    yellow500: '#F8D448',
    yellow600: '#F6C918', // Main
    yellow700: '#ECBD09',
    yellow800: '#D3AA08',
    yellow900: '#BB9607',

    green100: '#EAF8ED',
    green200: '#ABE3B9',
    green300: '#82D495',
    green400: '#58C672',
    green500: '#2EB84F', // Main
    green600: '#29A346',
    green700: '#248F3D',
    green800: '#1F7A35',
    green900: '#145223',

    rationalGreen100: '#EAF8ED',

    red100: '#F5C5CA',
    red200: '#ED8D8A', // Main
    red300: '#DF615F',
    red400: '#E03E3D',
    red500: '#E03027',
    red600: '#D32E26',
    red700: '#C32A22',
    red800: '#B6261C',
    red900: '#A82217',

    neutral100: '#FFFFFF',
    neutral200: '#FAFAFA',
    neutral300: '#F5F5F5',
    neutral400: '#E5E5E5',
    neutral500: '#CCCCCC',
    neutral600: '#999999',
    neutral700: '#666666',
    neutral800: '#2E3138',
    neutral900: '#000000',

    sand100: '#FEFEFD',
    sand200: '#FCFAF6',
    sand300: '#F7F2E9',
    sand400: '#F0E7D6',
    sand500: '#E9DCC3',
    sand600: '#E2D1B0',
    sand700: '#DCC69E',
    sand900: '#D5BA8B',

    feedbackAlertNegative: '#E33C5A',
    feedbackAlertNegativeTransparent: 'rgba(227, 60, 90, 0.1)', // #E33C5A %10

    feedbackAlertWarning: '#E56F43',
    feedbackAlertWarningTransparent: 'rgba(229, 111, 67, 0.1)', // #E56F43 %10

    feedbackAlertPositive: '#2EC4A0',
    feedbackAlertPositiveTransparent: 'rgba(46, 196, 160, 0.1)', // #2EC4A0 %10

    socialFacebook: '#3B5998',
    socialTwitter: '#1DA1F2',
    socialGoogle: '#E60023',

    // Broker Color Theme
    '#1B53CF': ['#1B53CF', '#f5f7fe', '#d5e0fb', '#c0d1f7'],
    '#7150EA': ['#7150EA', '#f7f4fe', '#ddd5fa', '#ccc0f7'],
    '#ECBD09': ['#ECBD09', '#fefae9', '#fef5d3', '#fdefbd'],
    '#FA4C00': ['#FA4C00', '#fef0e9', '#fde0d3', '#fbd1bd'],
    '#248F3D': ['#248F3D', '#f2fbf4', '#cdeed5', '#b4e5bf'],
    '#000000': ['#000000', '#fcfaf6', '#f7f2e9', '#f0e7d6'],

    // old theme vars
    // TODO to be removed
    lightBlack: '#131D24', // used for tooltip to be changed later
    gray: '#5E6985',

    clientTopBarBackground: '#E4ECFF',
    clientTopBarSaveColor: '#525763',
    clientsTableHead: '#7F8594',
    clientsTablefullName: '#505664',
    clientsTablePending: '#D98F00',
    clientsTablePendingBackground: '#FFEFD0',
    clientsTableDeclined: '#D83333',
    clientsTableDeclinedBackground: '#FFE8E8',
    clientsTableCompleted: '#70BA4D',
    clientsTableCompletedBackGround: '#E1F9D5',
    clientsTableGrayColor: '#ABB0BD',
    lightGray: '#DEE2E9',
    paleGray: '#9DA2AE',
    white: '#FFFFFF',
    transparentWhite: '#fffffffb',
    matteWhite: 'rgba(255, 255, 255, 0.64)',
    mask: 'rgba(0, 0, 0, 0.75)',
    transparent: '#ffffff00',
    green: '#2EBA87',
    greenLight: '#26D3C6',
    success: '#09CA85',
    whatsapp: '#30B945',
    successLight: '#E6FAF3',
    error: '#E94D2B',
    danger: '#E04040',
    orange100: '#FFE6DA',
    orange: '#FF8F0C',
    orangeLight: '#FFBC35',
    rose: '#FF8788',
    bodyBackgroundColor: '#FAFBFC',
  },
  fontFamilies: {
    main: 'PPTelegraf',
    title: 'PPTelegraf',
  },
};

export default theme;
