import { IconProps } from '@app/modules/core/domain/svgIcon';

const Close = ({ color, size, className }: IconProps) => (
  <svg
    width={ size }
    height={ size }
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={ className }
  >
    <path
      d='M4.93934 5.99951L0.78217 1.84234C0.489277 1.54945 0.489277 1.07457 0.78217 0.781682C1.07506 0.488788 1.54994 0.488788 1.84283 0.781682L6 4.93885L10.1572 0.781682C10.4501 0.488788 10.9249 0.488788 11.2178 0.781682C11.5107 1.07457 11.5107 1.54945 11.2178 1.84234L7.06066 5.99951L11.2178 10.1567C11.5107 10.4496 11.5107 10.9244 11.2178 11.2173C10.9249 11.5102 10.4501 11.5102 10.1572 11.2173L6 7.06017L1.84283 11.2173C1.54994 11.5102 1.07506 11.5102 0.78217 11.2173C0.489277 10.9244 0.489277 10.4496 0.78217 10.1567L4.93934 5.99951Z'
      fill={ color }
    />
  </svg>
);

Close.defaultProps = {
  color: '#585858',
  size: '12',
  className: '',
};

export default Close;
