import { init } from '@amplitude/analytics-browser';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import NewFeatureFixedNotification from '@app/components/common/NewFeatureNotification';
import ScrollToTop from '@app/components/common/ScrollToTop';
import { store } from '@app/modules/core/infrastructure/store';
import { GlobalStyle } from '@app/style/globalStyle/index';
import theme from '@app/style/theme';
import { initDatadogLogger, initRum } from '@app/utils/datadog';
import envProvider from '@app/utils/helpers/envProvider';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'antd/dist/antd.min.css';
import { GlobalProvider as GlobalContextProvider } from 'contexts/GlobalContext';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
import App from './LoadableApp';
import notify from './modules/core/application/notify';
import { muiTheme } from './common/theme';
import { globalMUIStyles } from './common/theme/globalStyles';

if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
  init(envProvider.AMPLITUDE_API_KEY);
  initDatadogLogger();
  initRum();
}

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } });

let app = <App />;

// Hook styled-components theme and GlobalStyle
app = (
  <QueryClientProvider client={ queryClient }>
    <GlobalContextProvider>
      <MuiThemeProvider theme={ muiTheme }>
        <ThemeProvider theme={ theme }>
          <GlobalStyle />
          <GlobalStyles styles={ globalMUIStyles } />
          <ScrollToTop />
          {app}
          <NewFeatureFixedNotification
            featureName='New: Payouts overview!'
            featureContent='We bring you the latest payout structure directly to the Portal. Never miss an update again!'
            containerId='mb-new-feature'
            allowedRoles={ ['broker_manager', 'manager'] }
          />
        </ThemeProvider>
      </MuiThemeProvider>
    </GlobalContextProvider>
    <ReactQueryDevtools initialIsOpen={ false } />
  </QueryClientProvider>
);

// Hook router
app = <BrowserRouter>{app}</BrowserRouter>;

// Hook redux
app = <Provider store={ store }>{app}</Provider>;

// Detect when user is offline
window.addEventListener('offline', () =>
  notify('error', {
    message: 'Disconnected',
    description: 'No internet connection. Please reconnect.',
    duration: 5,
  }));

const container = document.querySelector('#root');
const root = createRoot(container);

root.render(app);
