enum MortgageType {
  Conventional = 'CONVENTIONAL',
  Islamic = 'ISLAMIC',
  'Best Possible rate' = 'BEST_POSSIBLE_RATE'
}

type MortgageValueType = typeof MortgageType[keyof typeof MortgageType];

export { MortgageType };
export type { MortgageValueType };
