import Box from '@app/components/common/basic/Containers/Box';

import theme from '@app/style/theme';
import CheckCircle from '../../icons/CheckCircle';
import CrossCircle from '../../icons/CrossCircle';

const UploadDocumentsIcon = ({ type }) => {
  let hollowColor = theme.colors.purple700;
  let objectColor = theme.colors.purple700;

  let hollowOpacity = 0.1;

  switch (type) {
    case 'main': {
      hollowColor = theme.colors.purple700;
      objectColor = theme.colors.purple700;
      break;
    }
    case 'blurred': {
      hollowColor = theme.colors.gray;
      objectColor = theme.colors.gray;
      break;
    }
    case 'selected': {
      hollowColor = theme.colors.purple700;
      objectColor = theme.colors.white;
      hollowOpacity = 1;
      break;
    }
    case 'success': {
      hollowColor = theme.colors.green;
      objectColor = theme.colors.green;
      break;
    }
    case 'progress': {
      hollowColor = theme.colors.orangeLight;
      objectColor = theme.colors.orangeLight;
      break;
    }
    case 'white': {
      hollowColor = theme.colors.white;
      objectColor = theme.colors.white;
      break;
    }
    default: {
      break;
    }
  }

  return (
    <Box position='relative'>
      <Box position='absolute' top='-4px' right='-4px'>
        <Box hidden={ type !== 'accomplished' }>
          <CheckCircle />
        </Box>
        <Box hidden={ type !== 'rejected' }>
          <CrossCircle />
        </Box>
      </Box>
      <svg
        width='32'
        height='32'
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle opacity={ hollowOpacity } cx='16' cy='16' r='16' fill={ hollowColor } />
        <path
          d='M16.6667 9.33333H12C11.6464 9.33333 11.3072 9.4738 11.0572 9.72385C10.8071 9.9739 10.6667 10.313 10.6667 10.6667V21.3333C10.6667 21.687 10.8071 22.0261 11.0572 22.2761C11.3072 22.5262 11.6464 22.6667 12 22.6667H20C20.3536 22.6667 20.6928 22.5262 20.9428 22.2761C21.1929 22.0261 21.3333 21.687 21.3333 21.3333V14L16.6667 9.33333Z'
          stroke={ objectColor }
          strokeWidth='1.4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M16.6667 9.33333V14H21.3333'
          stroke={ objectColor }
          strokeWidth='1.4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Box>
  );
};

UploadDocumentsIcon.defaultProps = { type: 'main', // main, blurred, selected
};

export default UploadDocumentsIcon;
