import HuspyBrokersLogo from './HuspyBrokersLogo';
import HuspyLogo from './HuspyLogo';
import ArrowIcon from './icons/Arrow';
import ArrowCircleRightFilledIcon from './icons/ArrowCircleRightFilled';
import BottomOpenArrowIcon from './icons/BottomOpenArrow';
import Calendar from './icons/Calendar';
import CallIcon from './icons/Call';
import CheckCircle from './icons/CheckCircle';
import checkmarkIcon from './icons/checkmark.svg';
import Clock from './icons/Clock';
import Close from './icons/Close';
import Cross from './icons/Cross';
import CrossCircle from './icons/CrossCircle';
import DollarIcon from './icons/Dollar';
import InfoCircle from './icons/InfoCircle';
import InitialLogo from './icons/InitialLogo';
import NoBankProductsPlaceholder from './icons/no-bank-products-placeholder.svg';
import NoClientPlaceHolder from './icons/no-clients-placeholder.svg';
import PhoneIcon from './icons/Phone';
import RightOpenArrowIcon from './icons/RightOpenArrow';
import UploadHint from './icons/UploadHint';
import MortgageOverviewIcon from './sections/mortgageOverview/icon';
import OverviewIcon from './sections/overview/icon';
import PersonalDetailsIcon from './sections/personalDetails/icon';
import ProfileSetUpIcon from './sections/profileSetUp/icon';
import PropertyAndMortgageIcon from './sections/propertyAndMortgage/icon';
import UploadDocumentsIcon from './sections/uploadDocuments/icon';

const assets = {
  HuspyLogo,
  HuspyBrokersLogo,
  checkmarkIcon,
  DollarIcon,
  PhoneIcon,
  ArrowIcon,
  ArrowCircleRightFilledIcon,
  CallIcon,
  Cross,
  OverviewIcon,
  ProfileSetUpIcon,
  PersonalDetailsIcon,
  MortgageOverviewIcon,
  RightOpenArrowIcon,
  BottomOpenArrowIcon,
  PropertyAndMortgageIcon,
  UploadDocumentsIcon,
  CheckCircle,
  CrossCircle,
  InfoCircle,
  Close,
  Calendar,
  Clock,
  UploadHint,
  InitialLogo,
  NoClientPlaceHolder,
  NoBankProductsPlaceholder,
};

export default assets;
