// eslint-disable-next-line import/no-cycle
import http from '@app/modules/core/http';
import {
  BROKER_ENDPOINT, COMPANY_INFO_ENDPOINT, COUNTRIES_LIST_ENDPOINT, DECODE_HID_ENDPOINT
} from '@app/modules/core/infrastructure/api/constants';
import {
  BrokerRequestAccess, CompanyInfoRes, DecodeHIDRequest, DecodeHIDResponse,
  DirectFeatureFlagResponse, FeatureFlagResponse
} from '@app/modules/core/types';
import envProvider from '@app/utils/helpers/envProvider';

export const coreApi = {
  getCompanyInfo: () =>
    http.get<CompanyInfoRes>(COMPANY_INFO_ENDPOINT),
  getCountriesList: () => http.get(`${COUNTRIES_LIST_ENDPOINT}?limit=260`),

  getBrokerByAuthenticatorId: (authenticator_id: string) =>
    http.get<any>(`${BROKER_ENDPOINT}/${authenticator_id}`),

  requestAccessForBroker: ({ authenticatorId, userProfile }: BrokerRequestAccess) =>
    http.post<any>(`${BROKER_ENDPOINT}/${authenticatorId}/request-access`, userProfile),

  decodeHID: ({ clientType, hid }: DecodeHIDRequest) =>
    http.post<DecodeHIDResponse>(DECODE_HID_ENDPOINT, { hid, clientType }, null, false),

  getFeatureFlagDirectly: (userId: string, flagKey: string) =>
    http.get<DirectFeatureFlagResponse>(
      `${envProvider.AMPLITUDE_FEATURE_FLAGS_API_URL}?user_id=${userId}&flag_key=${flagKey}`,
      {
        Authorization: `Api-Key ${envProvider.AMPLITUDE_FEATURE_FLAGS_API_KEY}`,
        Accept: 'application/json;charset=utf-8',
      }
    ),
  getFeatureFlags: (userId: string) =>
    http.get<FeatureFlagResponse>(
      `${envProvider.AMPLITUDE_FEATURE_FLAGS_API_URL}?user_id=${userId}`,
      {
        Authorization: `Api-Key ${envProvider.AMPLITUDE_FEATURE_FLAGS_API_KEY}`,
        Accept: 'application/json;charset=utf-8',
      }
    ),
};
