import { IconProps } from '@app/modules/core/domain/svgIcon';
import theme from '@app/style/theme';

const Calendar = ({ size }: IconProps) => (
  <svg
    width={ size }
    height={ size }
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M15.8333 3.33333H4.16667C3.24619 3.33333 2.5 4.07952 2.5 5V16.6667C2.5 17.5871 3.24619 18.3333 4.16667 18.3333H15.8333C16.7538 18.3333 17.5 17.5871 17.5 16.6667V5C17.5 4.07952 16.7538 3.33333 15.8333 3.33333Z'
      stroke={ theme.colors.purple700 }
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.3334 1.66667V5'
      stroke={ theme.colors.purple700 }
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.66663 1.66667V5'
      stroke={ theme.colors.purple700 }
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.5 8.33333H17.5'
      stroke={ theme.colors.purple700 }
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

Calendar.defaultProps = { size: 20 };

export default Calendar;
