import styled from 'styled-components';
import envProvider from '@app/utils/helpers/envProvider';
import Img from '../common/basic/Img';

const InitialLoadingIndicatorStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const InitialLoadingIndicator: React.FC = (props) => (
  <InitialLoadingIndicatorStyled { ...props }>
    <Img src={ `${envProvider.PUBLIC_URL}/logo.svg` } w='200px' />
  </InitialLoadingIndicatorStyled>
);

export default InitialLoadingIndicator;
