import { createSlice, nanoid } from '@reduxjs/toolkit';
import { AlertMethodType, AlertType } from '../domain/enums/alertMsgsType';
import type { RootState } from './store';

export interface AlertState {
  title: string;
  message: string;
  type: keyof AlertType;
  method: AlertMethodType;
  key: string;
}

const initialAlertState: AlertState[] = [];

const alertSlice = createSlice({
  name: 'alert',
  initialState: { alerts: initialAlertState },
  reducers: {
    addAlert: (state, action) => {
      state.alerts.push({
        title: action.payload.title,
        message: action.payload.message,
        type: action.payload.type,
        method: action.payload.method,
        key: nanoid(),
      });
    },
  },
});

export default alertSlice.reducer;
export const selectCurrentAlerts = (state: RootState) => state.alert.alerts;
export const { addAlert } = alertSlice.actions;
