import React from 'react';
import {
  AlignItemsProps,
  BackgroundProps,
  BorderProps,
  ColorProps,
  FlexBasisProps,
  FlexboxProps,
  FlexDirectionProps,
  FlexProps,
  JustifyContentProps,
  LayoutProps,
  PositionProps,
  ShadowProps,
  SpaceProps,
  TypographyProps,
  GridColumnProps,
  GridTemplateColumnsProps
} from 'styled-system';
import BoxStyled from './style';

type StyledSystemProps = LayoutProps &
FlexProps &
FlexDirectionProps &
JustifyContentProps &
AlignItemsProps &
PositionProps &
SpaceProps &
TypographyProps &
ColorProps &
BackgroundProps &
BorderProps &
FlexDirectionProps &
FlexboxProps &
FlexBasisProps &
GridColumnProps &
GridTemplateColumnsProps &
ShadowProps;

export interface BoxPropsType extends StyledSystemProps {
  cursor?: string;
  gap?: string | number;
  onClick?: (e: Event) => void;
  className?: string;
  customCss?: object;
  ref?: React.ForwardedRef<unknown>
}

const Box: React.FC<BoxPropsType> = React.forwardRef((props, ref) => {
  const { children, ...rest } = props;

  return (
    <BoxStyled { ...rest } ref={ ref }>
      { children }
    </BoxStyled>
  );
});

export default Box;
