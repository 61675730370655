import { coreApi } from '@app/modules/core/api';
import { useQuery } from '@tanstack/react-query';
import { FeatureFlagResponse, FeatureFlags } from '@app/modules/core/types';

const useGetFeatureFlags = (userId: string) => {
  const {
    data, isLoading, error, refetch, isRefetching,
  } = useQuery({
    queryKey: ['featureFlags', userId],
    queryFn: () => coreApi.getFeatureFlags(userId),
    retry: 3,
    enabled: !!userId,
    staleTime: Number.POSITIVE_INFINITY,
    select: (featureFlags: FeatureFlagResponse): FeatureFlags => Object.fromEntries(
      Object.entries(featureFlags).map(([key, { key: flagKey }]) => [key, ['on', 'treatment'].includes(flagKey)])
    ) as FeatureFlags,
  });

  return {
    data,
    isLoading,
    error,
    refetch,
    isRefetching,
  };
};

export default useGetFeatureFlags;
