import { themeConfig } from './configuration';

export const globalMUIStyles = `
  button.MuiButtonBase-root {
    border-radius: ${themeConfig.borderRadius.normal};
    font-weight: 600;
    letter-spacing: -0.14px;
    text-transform: none;
    box-shadow: none;
    
    &:hover {
      box-shadow: none;
    }
  }
  .MuiBreadcrumbs-separator {
    color: ${themeConfig.colors.neutral[700]};
    font-size: 1.4rem;
  }
`;
