import { nanoid } from '@reduxjs/toolkit';
// TODO: Cycle dependency
// eslint-disable-next-line import/no-cycle
import { apiSlice } from '@app/modules/core/infrastructure/api/apiSlice';
import {
  BANKS_LIST_ENDPOINT,
  CLIENT_PERSONAL_DETAILS_ENDPOINT,
  DOCUMENTS_ENDPOINT,
  DOCUMENT_CATEGORY_ENDPOINT,
  MORTGAGE_ALL_CASES_ENDPOINT,
  MORTGAGE_APPLICATION_ENDPOINT,
  MORTGAGE_CASE_SUBMISSION_ENDPOINT,
  MORTGAGE_REGISTER_CLIENT_ENDPOINT,
  MORTGAGE_REGISTER_DEPENDENTS_ENDPOINT,
  OPERATIONAL_DETAILS_LIST_API,
  SAVE_OPERATIONAL_DETAILS_API,
} from '@app/modules/core/infrastructure/api/constants';
import { UserSectionsStepMapper } from '@app/modules/mortgage/domain/enums/caseApplicationUserSections';
import {
  ApplicationFormCategory,
  CustomUploadFile,
  UploadBankDocumentApiRes,
  UploadDocumentRes,
} from '@app/modules/mortgage/domain/mortgageCase';
// eslint-disable-next-line import/no-cycle
import {
  addBankDocument,
  removeBankDocument,
  updateBankDocument,
} from '../createCaseApplicationFormsSlice';
import {
  addClientDocument,
  removeClientDocument,
  updateClientDocument,
} from '../createCaseClientDocumentsSlice';

export const mortgageApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllCases: builder.query({
      query: () => MORTGAGE_ALL_CASES_ENDPOINT,
      providesTags: ['MortgageCases'],
    }),
    getCaseById: builder.query({
      query: (id: number) => `${MORTGAGE_APPLICATION_ENDPOINT}/${id}`,
      providesTags: ['MortgageCaseApplication'],
      keepUnusedDataFor: 3,
    }),
    getCaseByExternalId: builder.query({
      query: (id: string) => `${MORTGAGE_APPLICATION_ENDPOINT}/${id}/get_mortgage_application_by_external_id`,
      keepUnusedDataFor: 0,
    }),
    getOriginalCaseById: builder.query({
      query: (id: number) => `${MORTGAGE_APPLICATION_ENDPOINT}/${id}`,
      providesTags: ['MortgageCaseApplication'],
      keepUnusedDataFor: 3,
    }),
    registerClient: builder.mutation({
      query: (body) => ({
        url: MORTGAGE_REGISTER_CLIENT_ENDPOINT,
        method: 'POST',
        body,
      }),
    }),
    registerDependents: builder.mutation({
      query: (body) => ({
        url: `${MORTGAGE_REGISTER_DEPENDENTS_ENDPOINT}`,
        method: 'POST',
        body,
      }),
    }),
    saveIncomeInfo: builder.mutation({
      query: ({ body, userPersonalDetailsId }) => ({
        url: `${CLIENT_PERSONAL_DETAILS_ENDPOINT}/${userPersonalDetailsId}`,
        method: 'PUT',
        body,
      }),
    }),
    saveMortgageInfo: builder.mutation({
      query: ({ body, mortgageApplicationId }) => ({
        url: `${MORTGAGE_APPLICATION_ENDPOINT}/${mortgageApplicationId}`,
        method: 'PUT',
        body,
      }),
    }),
    getAllBanks: builder.query({ query: () => BANKS_LIST_ENDPOINT }),
    getRequiredBanksAppForms: builder.query<ApplicationFormCategory[] | null, string | number>({
      query: (mortgageApplicationId) => ({ url: `${MORTGAGE_APPLICATION_ENDPOINT}/${mortgageApplicationId}/required_banks_document_categories` }),
      providesTags: ['MortgageCaseRequiredBanks'],
      transformResponse: (responseData: ApplicationFormCategory[]) => responseData?.map((cat) => ({ ...cat, title: cat?.maName?.trim() })),
    }),
    saveSelectedBanks: builder.mutation({
      query: ({ selectedBanks, mainApplicantId }) => ({
        url: `${BANKS_LIST_ENDPOINT}/${mainApplicantId}/select_client_bank`,
        method: 'PUT',
        body: { bank_ids: selectedBanks },
      }),
      invalidatesTags: ['MortgageCaseRequiredBanks'],
    }),
    uploadBankDocument: builder.mutation<
    UploadBankDocumentApiRes,
    { document: FormData; categoryId: number; originalFile: File }
    >({
      query: ({ document, categoryId }) => ({
        url: `${DOCUMENT_CATEGORY_ENDPOINT}/${categoryId}`,
        method: 'PUT',
        body: document,
      }),
      invalidatesTags: ['MortgageCaseBankDocuments'],
      async onQueryStarted({ categoryId, document, originalFile }, { dispatch, queryFulfilled }) {
        // `updateQueryData` requires the endpoint name and cache key arguments,
        // so it knows which piece of cache state to update
        const uniqueId = nanoid();
        // debugger;
        dispatch(
          addBankDocument({
            id: uniqueId,
            nameOfFile: originalFile.name,
            isUploading: true,
            categoryId,
          })
        );
        try {
          const response = await queryFulfilled;
          const updatedCategoryDocs = response?.data?.documentCategory?.find(
            (cat) => cat.id === categoryId
          )?.documents;
          const docId = updatedCategoryDocs?.find((doc) => doc.nameOfFile === originalFile.name)?.id
            ?? uniqueId;

          dispatch(
            updateBankDocument({
              id: uniqueId,
              changes: {
                id: docId,
                isUploading: false,
              },
            })
          );
        } catch {
          dispatch(removeBankDocument(document));
        }
      },
    }),
    uploadDocument: builder.mutation<
    UploadDocumentRes,
    { document: FormData; originalFile: CustomUploadFile; isBankDoc: boolean }
    >({
      query: ({ document, originalFile }) => ({
        url: `${DOCUMENTS_ENDPOINT}?files_owner_uuid=${originalFile?.cognitoId}`,
        method: 'POST',
        body: document,
      }),
      invalidatesTags: (result, error, { isBankDoc }) => (isBankDoc ? ['MortgageCaseBankDocuments'] : ['MortgageCaseClientDocuments']),
      async onQueryStarted({ originalFile, isBankDoc }, { dispatch, queryFulfilled }) {
        // `updateQueryData` requires the endpoint name and cache key arguments,
        // so it knows which piece of cache state to update

        const uniqueId = nanoid();
        // debugger;
        if (isBankDoc) {
          dispatch(
            addBankDocument({
              id: uniqueId,
              nameOfFile: originalFile?.name,
              isUploading: true,
              categoryId: originalFile?.categoryId,
            })
          );
        } else {
          dispatch(
            addClientDocument({
              id: uniqueId,
              nameOfFile: originalFile?.name,
              isUploading: true,
              clientCognitoId: originalFile?.cognitoId,
            })
          );
        }
        try {
          const response = await queryFulfilled;
          const docId = response?.data?.id;

          if (isBankDoc) {
            dispatch(
              updateBankDocument({
                id: uniqueId,
                changes: {
                  id: docId,
                  isUploading: false,
                },
              })
            );
          } else {
            dispatch(
              updateClientDocument({
                id: uniqueId,
                changes: {
                  id: docId,
                  isUploading: false,
                },
              })
            );
          }
        } catch {
          if (isBankDoc) {
            dispatch(removeBankDocument(uniqueId));
          } else {
            dispatch(removeClientDocument(uniqueId));
          }
        }
      },
    }),
    removeDocument: builder.mutation({
      query: ({ documentId, cognitoId }) => ({
        url: `${DOCUMENTS_ENDPOINT}/${documentId}?files_owner_uuid=${cognitoId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { isBankDoc }) => (isBankDoc ? ['MortgageCaseBankDocuments'] : ['MortgageCaseClientDocuments']),
      async onQueryStarted({ documentId, isBankDoc }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          if (isBankDoc) {
            dispatch(removeBankDocument(documentId));
          } else {
            dispatch(removeClientDocument(documentId));
          }
        } catch (error) {
          console.log(error);
        }
      },
    }),
    updateDocumentCategory: builder.mutation({
      query: ({
        documentId, categoryId, cognitoId, mortgageApplicationId,
      }) => ({
        url: `${DOCUMENTS_ENDPOINT}/${documentId}?files_owner_uuid=${cognitoId}`,
        method: 'PUT',
        body: {
          documents_category: categoryId,
          mortgage_application_id: mortgageApplicationId,
        },
      }),
      async onQueryStarted({ documentId, categoryId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            updateClientDocument({
              id: documentId,
              changes: { categoryId },
            })
          );
        } catch (error) {
          console.log(error);
        }
      },
    }),
    getOperationalDetailsOptions: builder.query({ query: () => OPERATIONAL_DETAILS_LIST_API }),
    saveOperationalDetails: builder.mutation({
      query: (body) => ({
        url: SAVE_OPERATIONAL_DETAILS_API,
        method: 'POST',
        body,
      }),
    }),
    submitCase: builder.mutation({
      query: ({ mortgageApplicationId, mainApplicantId, comment }) => ({
        url: MORTGAGE_CASE_SUBMISSION_ENDPOINT,
        method: 'POST',
        body: {
          mortgage_application_id: mortgageApplicationId,
          applicant_cognito: mainApplicantId,
          comment,
        },
      }),
      invalidatesTags: ['MortgageCases'],
    }),
    setCaseCurrentSection: builder.mutation({
      query: ({ mortgageApplicationId, currentSection }) => ({
        url: `${MORTGAGE_APPLICATION_ENDPOINT}/${mortgageApplicationId}`,
        method: 'PUT',
        body: { ma_section: UserSectionsStepMapper[currentSection] },
      }),
    }),
  }),
});

export const {
  useGetAllCasesQuery,
  useGetCaseByIdQuery,
  useGetCaseByExternalIdQuery,
  useGetOriginalCaseByIdQuery,
  useGetAllBanksQuery,
  useGetRequiredBanksAppFormsQuery,
  useGetOperationalDetailsOptionsQuery,
  useRegisterClientMutation,
  useRegisterDependentsMutation,
  useSetCaseCurrentSectionMutation,
  useSaveIncomeInfoMutation,
  useSaveMortgageInfoMutation,
  useSaveSelectedBanksMutation,
  useUploadBankDocumentMutation,
  useUploadDocumentMutation,
  useRemoveDocumentMutation,
  useUpdateDocumentCategoryMutation,
  useSaveOperationalDetailsMutation,
  useSubmitCaseMutation,
} = mortgageApiSlice;
