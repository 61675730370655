import {
  identify, resetIdentity, track
} from '@app/utils/amplitude';
import { performInProductionOrPartnerEnvironment } from '@app/utils/helpers/getCurrentEnvironment';

interface logEventProps {
  eventName: string;
  eventParams?: Record<string, any>;
}

interface TrackedUser {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
}

const resetIdentityFunction = () => {
  resetIdentity();
};

const logEvent = ({ eventName, eventParams = {} }: logEventProps) => {
  const eventLoggingFunction = () => {
    track({ eventName, eventParams });
  };
  performInProductionOrPartnerEnvironment(eventLoggingFunction);
};

export const identifyBroker = (broker: TrackedUser & Record<string, any>) => {
  const identifyFunction = () => {
    identify(broker);
  };
  performInProductionOrPartnerEnvironment(identifyFunction);
};

export const resetBrokerIdentity = () => {
  performInProductionOrPartnerEnvironment(resetIdentityFunction);
};

export default logEvent;
