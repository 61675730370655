// eslint-disable-next-line import/no-cycle
import { coreApi } from '../api';

const fetchBrokerProfile = async (authenticator_id) => {
  const response = await coreApi.getBrokerByAuthenticatorId(authenticator_id);
  const transformedResponse = {
    deviceToken: response?.device_token,
    isGrantedAccessToMbPortal: response?.is_granted_access_to_mb_portal,
    userType: response?.user_type,
    roles: response?.roles,
  };
  return { data: transformedResponse };
};

export { fetchBrokerProfile };
