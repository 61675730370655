export class HuspyApiError extends Error {
  name = 'HuspyApiError';

  constructor(
    public readonly message: string,
    public readonly details: Record<string, any>,
    public readonly statusCode: number
  ) {
    super();
  }
}
