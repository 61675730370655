/* eslint-disable max-len */
export const BASE_URL: string = process.env.REACT_APP_SERVERPATH as string;
export const BASE_NEXUS_URL: string = process.env.REACT_APP_NEXUS_SERVERPATH as string;
export const ROOT_API = `${BASE_URL}/api`;
export const ROOT_AUTH_API = `${ROOT_API}/v1/auth`;
export const ROOT_IDENTITY_API = `${ROOT_API}/v1/identity`;
export const AUTH_ENDPOINT = `${ROOT_AUTH_API}/broker_auth`;
export const LOGIN_ENDPOINT = `${AUTH_ENDPOINT}/broker_login`;
export const REQUEST_ACCESS_ENDPOINT = `${AUTH_ENDPOINT}/request_access`;
export const FORGOT_PASSWORD_ENDPOINT = `${AUTH_ENDPOINT}/broker_forgot_password`;
export const FORGOT_PASSWORD_VERIFY_ENDPOINT = `${AUTH_ENDPOINT}/broker_verify_activation_code`;
export const RESET_PASSWORD_ENDPOINT = `${AUTH_ENDPOINT}/broker_reset_password_by_broker`;
export const RESET_PASSWORD_SESSION_ENDPOINT = `${AUTH_ENDPOINT}/broker_reset_password`;
export const REFRESH_TOKEN_ENDPOINT = `${ROOT_IDENTITY_API}/token/refresh`;
export const DECODE_HID_ENDPOINT = `${process.env.REACT_APP_ID_2_0_FE_BASE_URL}/api/decodeHid`;

export const MORTGAGE_ALL_CASES_ENDPOINT = `${ROOT_API}/v2/referrals/broker_referred_applications`;
export const MORTGAGE_REGISTER_CLIENT_ENDPOINT = `${ROOT_API}/v2/referrals/broker/register_new_client`;
export const MORTGAGE_REGISTER_DEPENDENTS_ENDPOINT = `${ROOT_API}/v2/referrals/broker_client_dependents`;

export const CLIENT_PERSONAL_DETAILS_ENDPOINT = `${ROOT_API}/v1/broker_clients_personal_details`;
export const MORTGAGE_APPLICATION_ENDPOINT = `${ROOT_API}/v2/mortgage_application/broker_property_mortgage`;
export const BANKS_LIST_ENDPOINT = `${ROOT_API}/v2/banks/list`;
export const BROKER_ENDPOINT = `${ROOT_API}/v1/brokers`;
export const BANKS_ENDPOINT = `${ROOT_API}/v2/banks`;
export const BANKS_AND_DISTINCT_RATES_ENDPOINT = `${BANKS_ENDPOINT}/banks_with_distinct_products`;
export const DOCUMENT_CATEGORY_ENDPOINT = `${ROOT_API}/v1/document_category/broker_document_category`;
export const DOCUMENTS_ENDPOINT = `${ROOT_API}/v1/document_category/broker/document_files`;
export const MORTGAGE_CASE_SUBMISSION_ENDPOINT = `${ROOT_API}/v2/referrals/external_broker_case_submission`;

export const OPERATIONAL_DETAILS_LIST_API = `${ROOT_API}/v2/referrals/broker_referrers`;
export const SAVE_OPERATIONAL_DETAILS_API = `${ROOT_API}/v2/mortgage_application/broker_property_mortgage/add_operational_data`;

export const COMPANIES_LIST_ENDPOINT = `${ROOT_API}/v2/companies/`;
export const COUNTRIES_LIST_ENDPOINT = `${ROOT_API}/v2/base/country`;
export const COMPANY_INFO_ENDPOINT = `${ROOT_API}/v2/referrals/company_brokers`;
export const PROPOSALS_ENDPOINT = `${ROOT_API}/v2/proposals`;
export const BANK_PRODUCTS_ENDPOINT = `${BANKS_ENDPOINT}/bank_products`;
export const REPORT_BANK_PRODUCT_RATES_ENDPOINT = `${ROOT_API}/v1/feedbacks/bank_rates`;
export const GOVERNMENT_FEES_ENDPOINT = `${ROOT_API}/v2/proposals/government_fees/`;
export const ACCOUNT_MANAGEMENT_ENDPOINT = `${ROOT_API}/v2/referrals/broker_account_management`;
export const DOC_CENTER_LIST_ENDPOINT = `${ROOT_API}/v2/document_center/list/`;
export const DOC_CENTER_ADDITIONAL_ENDPOINT = `${ROOT_API}/v2/document_center/additional_documents/`;
export const DOC_CENTER_DOWNLOAD_ENDPOINT = `${ROOT_API}/v2/document_center/download_zip/`;

// EIBOR
export const EIBOR_RATES_ENDPOINT = `${ROOT_API}/v2/eibor_rates`;
export const EIBOR_RATES_FEEDBACK_ENDPOINT = `${ROOT_API}/v1/feedbacks/eibor_rates`;

// CES
export const SUBMIT_CES_ENDPOINT = `${ROOT_API}/v1/feedbacks/mb_feedback_submission`;

export const GET_DIGITAL_BANK_DOCUMENTS = `${ROOT_API}/v2/referral/digital_document`;
export const GENERATE_DOCUMENT_FROM_TEMPLATE = `${ROOT_API}/v2/referral/digital_document`;
export const CHECK_IF_DIGITAL_DOCUMENT_LINK_GENERATED = `${ROOT_API}/v2/referral/digital_document/send`;
export const CHECK_IF_USER_HAS_BETA_ACCESS = `${ROOT_API}/v2/referral/digital_document/has_digital_document`;
export const GET_FEATURE_FLAGS = `${BROKER_ENDPOINT}/feature-flags`;
export const CLIENT_DETAILS_FROM_NEXUS = `${BASE_NEXUS_URL}/clients`;

// Referrals
export const REFERRALS_ENDPOINT = `${ROOT_API}/v2/referral/`;

// Opportunity
export const OPPORTUNITIES_ENDPOINT = `${ROOT_API}/v2/opportunities/`;
export const OPPORTUNITIES_META_ENDPOINT = `${OPPORTUNITIES_ENDPOINT}meta`;

// Vaults
export const VAULTS_ENDPOINT = `${ROOT_API}/v2/vaults`;
