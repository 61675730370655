import styled from 'styled-components';

export const Title = styled.div`
  background-image: linear-gradient(53.95deg, #5788A5 0%, #9FC6D1 88.07%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-size: 2em;
  font-weight: 500;
  line-height: 40px;
`;

export const Percentage = styled.div`
  font-size: 6em;
  line-height: 104px;
  font-weight: 600;
`;

export const Rate = styled.div`
  color: #4B5563;
  font-size: 1.5em;
  line-height: 32px;
`;

export const RateComment = styled.div`
  color: #111827;
  font-size: 1.25em;
  line-height: 28px;
  font-weight: 600;
`;

export const Picture = styled.img`
  height: 272px;
  width: 100%;
  border-radius: 24px;
  background-size: cover;
  background-position: center;
`;

export const Comment = styled.div`
  font-size: 1.125em;
  color: #9CA3AF;
`;

export const Link = styled.a`
  width: 100%;
  border-radius: 24px;
  background-color: #111827;
  color: #fff;
  padding: 1em;
  border: 0;
  transition: ease 0.3s;

  &:hover {
    cursor: pointer;
    opacity: 0.9;
    color: #fff;
  }
`;
