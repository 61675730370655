const capitalizeString = (str: string) => {
  if (!str) {
    return '';
  }

  const [first, ...rest] = str;
  return first.toUpperCase() + rest.join('').toLowerCase();
};

export default capitalizeString;
