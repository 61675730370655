import { devices } from '@app/style/constants';
import styled, { css } from 'styled-components';
import commonCss from '../../common';

const getCss = (suffix = '') => css`
  grid-template-columns: ${({ [`gridTemplateColumns${suffix}`]: gridTemplateColumns }) => gridTemplateColumns};
  grid-template-rows: ${({ [`gridTemplateRows${suffix}`]: gridTemplateRows }) => gridTemplateRows};
  column-gap: ${({ [`columnGap${suffix}`]: columnGap }) => columnGap};
  grid-row-gap: ${({ [`rowGap${suffix}`]: rowGap }) => rowGap};
  grid-auto-flow: ${({ [`gridAutoFlow${suffix}`]: gridAutoFlow }) => gridAutoFlow};
  flex-direction: ${({ [`flexDirection${suffix}`]: flexDirection }) => flexDirection};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  gap: ${({ gap }) => gap};
  justify-content: ${({ [`justifyContent${suffix}`]: justifyContent }) => justifyContent};
  justify-items: ${({ [`justifyItems${suffix}`]: justifyItems }) => justifyItems};
  align-items: ${({ [`alignItems${suffix}`]: alignItems }) => alignItems};
  align-content: ${({ [`alignContent${suffix}`]: alignContent }) => alignContent};
`;

/**
 * @deprecated This component is deprecated. Use `SystemDesign/Box` instead.
 */
const Box = styled.div`
  ${commonCss}
  ${getCss()}
  ${({ customCss }) => customCss};

  @media ${devices.desktop} {
    ${getCss('D')}
  }
  @media ${devices.laptop} {
    ${getCss('L')}
  }
  @media ${devices.miniLaptop} {
    ${getCss('ML')}
  }
  @media ${devices.tablet} {
    ${getCss('T')}
  }
  @media ${devices.mobile} {
    ${getCss('M')}
  }
`;

Box.defaultProps = {
  m: 0,
  p: 0,
  display: 'block',
  hidden: false,
};

/**
 * @deprecated This component is deprecated. Use `SystemDesign/Box` instead.
 */
export default Box;
