import theme from '@app/style/theme';

const AlertSparks = ({ size, color }) => (
  <svg
    width={ size }
    height={ size }
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 16 16'
  >
    <path
      d='M15.047 4a.333.333 0 0 0 0-.66l-.222-.031a1.34 1.34 0 0 1-1.133-1.134l-.031-.221a.333.333 0 0 0-.66 0l-.032.22a1.341 1.341 0 0 1-1.133 1.134l-.22.031a.333.333 0 0 0 0 .66l.22.032a1.341 1.341 0 0 1 1.134 1.133l.03.221a.333.333 0 0 0 .661 0l.032-.22a1.34 1.34 0 0 1 1.133-1.134l.22-.031ZM4.155 7.333h.01a.5.5 0 0 0 .502-.474c.055-1.137 1.21-2.526 2.499-2.526a.5.5 0 0 0 .01-1A2.75 2.75 0 0 1 4.667.855V.833a.5.5 0 1 0-1-.001 2.751 2.751 0 0 1-2.509 2.501.498.498 0 0 0-.491.504.503.503 0 0 0 .503.496 2.69 2.69 0 0 1 2.497 2.505.5.5 0 0 0 .488.495ZM9.15 14.329h.013a.643.643 0 0 0 .644-.61c.071-1.461 1.556-3.245 3.211-3.245a.643.643 0 0 0 .013-1.286 3.534 3.534 0 0 1-3.224-3.185v-.027A.642.642 0 0 0 8.71 5.52a.642.642 0 0 0-.189.453 3.536 3.536 0 0 1-3.223 3.214.64.64 0 0 0-.631.647.647.647 0 0 0 .647.638 3.458 3.458 0 0 1 3.208 3.219c.003.347.28.629.627.637Z'
      fill={ color }
    />
  </svg>
);

AlertSparks.defaultProps = {
  color: theme.colors.neutral900,
  size: 16,
};

export default AlertSparks;
