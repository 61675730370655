import theme from '@app/style/theme';

const RightOpenArrow = ({ color, size }) => (
  <svg
    width={ size }
    height={ size }
    viewBox='0 0 8 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1 1L7 7L1 13'
      stroke={ color }
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

RightOpenArrow.defaultProps = {
  color: theme.colors.gray,
  size: 16,
};

export default RightOpenArrow;
