import { FunctionComponent, Suspense } from 'react';
import Loadable from 'react-loadable';
import InitialLoadingIndicator from '@app/components/InitialLoadingIndicator';

const App = Loadable({
  loader: () => import('./App'),
  loading: InitialLoadingIndicator,
});

const LoadableApp: FunctionComponent = () => <App />;

const AppWithSuspence = () => (
  <Suspense fallback={ <InitialLoadingIndicator /> }>
    <LoadableApp />
  </Suspense>
);

export default AppWithSuspence;
