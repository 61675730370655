/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
// TODO: Dependency cycle
// eslint-disable-next-line import/no-cycle
import { matchDocsCategoriesReceived } from '@app/modules/mortgage/infrastructure/mortgageSlice';
import capitalizeString from '@app/utils/helpers/capitalizeString';
import { BrokerColorTheme } from '../domain/accountManagementInfo';
import { CountryType } from '../domain/enums/countryType';
import { Email, ImageUrl } from '../domain/shared';
import { accountManagementApiSlice } from './api/accountManagementApiSlice';
import { countriesApiSlice } from './api/countriesApiSlice';
import type { RootState } from './store';

const matchCountriesListReceived = countriesApiSlice.endpoints.getCountriesList.matchFulfilled;
const accountManagementReceived = accountManagementApiSlice.endpoints.getAccountInfo.matchFulfilled;

const documentsCategoriesInitial: { label: string; value: number }[] = [];

const INITIAL_STATE: {
  countries: CountryType[];
  defaultCategoryId: number;
  documentsCategories: { label: string; value: number }[];
  brokerInfo: {
    name: string;
    companyLogo: ImageUrl;
    companyName: string;
    primaryColor: BrokerColorTheme;
    email: Email;
  };
} = {
  countries: [],
  defaultCategoryId: 0,
  documentsCategories: documentsCategoriesInitial,
  brokerInfo: {
    name: '',
    companyLogo: '',
    companyName: '',
    primaryColor: '#000000',
    email: 'sample@domain.com',
  },
};

export const commonSlice = createSlice({
  name: 'common',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(accountManagementReceived, (state, action) => {
        const {
          firstName, lastName, email, companyName, companyLogo, primaryColor,
        } = action.payload;
        const colorThemes = ['#1B53CF', '#7150EA', '#ECBD09', '#FA4C00', '#248F3D', '#000000'];
        const brokerColorTheme = colorThemes?.includes(primaryColor) ? primaryColor : '#000000';
        state.brokerInfo = {
          name: String(`${firstName} ${lastName}`),
          companyLogo,
          companyName,
          primaryColor: brokerColorTheme,
          email,
        };
      })
      .addMatcher(matchCountriesListReceived, (state, action) => {
        state.countries = action.payload;
      })
      .addMatcher(matchDocsCategoriesReceived, (state, action) => {
        const defaultCategoryId = action.payload?.find((i) => i.maName.toLowerCase() === 'uncategorized')?.id ?? 0;
        const docsCategoriesMapped = action.payload.map((cat) => ({
          label: capitalizeString(cat.maName),
          value: cat.id,
        })) ?? [];

        state.documentsCategories = docsCategoriesMapped;
        state.defaultCategoryId = defaultCategoryId;
      });
  },
});

export default commonSlice.reducer;

export const selectCountriesList = (state: RootState) => state.common.countries;
export const selectDocumentsCategoriesList = (state: RootState) => state.common.documentsCategories;
export const selectDefaultCategorieId = (state: RootState) => state.common.defaultCategoryId;
export const selectBrokerInfo = (state: RootState) => state.common.brokerInfo;
