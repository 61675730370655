import { IconProps } from '@app/modules/core/domain/svgIcon';
import theme from '@app/style/theme';

const Clock = ({ size, color }: IconProps) => (
  <svg
    width={ size }
    height={ size }
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9.99996 18.3333C14.6023 18.3333 18.3333 14.6024 18.3333 10C18.3333 5.39762 14.6023 1.66666 9.99996 1.66666C5.39759 1.66666 1.66663 5.39762 1.66663 10C1.66663 14.6024 5.39759 18.3333 9.99996 18.3333Z'
      stroke={ color }
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10 5V10L13.3333 11.6667'
      stroke={ color }
      strokeWidth='1.4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

Clock.defaultProps = {
  size: 20,
  color: theme.colors.purple700,
};

export default Clock;
