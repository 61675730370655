import { createGlobalStyle } from 'styled-components';
import { mainScrollWidthInRem, overwrites } from './globalOverwrites';

export const GlobalStyle = createGlobalStyle`
  
  ::selection {
    background: ${({ theme }) => theme.colors.purple700Dark}
  }

  body,html {
    margin: 0;
    padding: 0;
    background-color: ${({ theme }) => theme.colors.white};
  }

  * {
    box-sizing: border-box;

    ::-webkit-scrollbar {
      width: 0.7rem;
    }

    ::-webkit-scrollbar-track {
      background: transparent; 
    }
    
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.neutral500}; 
      border-radius: 0.3rem;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.colors.neutral600};
    }
  }

  html {
    font-size: 62.5%;
    overflow-y: scroll;
  }

  body {
    font-family: PPTelegraf, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #081029;
    line-height: 21px;
    
    ::-webkit-scrollbar {
      width: ${mainScrollWidthInRem}rem;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 1rem;
    }
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }

  hr {
    border: ${({ theme }) => `.04rem solid ${theme.colors.neutral300}`};
    width: 100%;
  }

  ${overwrites}
`;
