import { SVGProps } from 'react';
import theme from '@app/style/theme';

interface ArrowDiagonalIconProps {
  color?: string;
  props?: SVGProps<SVGSVGElement> & any;
}
const ArrowDiagonalIcon = ({ color = theme.colors.white, ...props }: ArrowDiagonalIconProps) => (
  <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg' { ...props }>
    <path fillRule='evenodd' clipRule='evenodd' d='M0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1V11C12 11.5523 11.5523 12 11 12C10.4477 12 10 11.5523 10 11V2H1C0.447715 2 0 1.55228 0 1Z' fill={ color } />
    <path fillRule='evenodd' clipRule='evenodd' d='M11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893Z' fill={ color } />
  </svg>
);
export default ArrowDiagonalIcon;
