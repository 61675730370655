import styled from 'styled-components';
import { devices } from '@app/style/constants';

const Img = styled.img`
  user-select: none;
  height: ${({ h }) => h};
  display: ${({ display }) => display};
  object-fit: ${({ objectFit }) => objectFit};
  object-position: ${({ objectPosition }) => objectPosition};
  background-color: ${({ backgroundColor }) => backgroundColor};
  max-width: ${({ maxW }) => maxW};
  max-height: ${({ maxH }) => maxH};
  cursor: ${({ pointer }) => pointer && 'pointer'};
  border-radius: ${({ borderRadius }) => borderRadius};

  @media ${devices.desktop} {
    margin: ${({ m, md }) => md || m};
    width: ${({ w, wd }) => wd || w};
  }
  @media ${devices.laptop} {
    margin: ${({ m, ml, md }) => ml || md || m};
    width: ${({ w, wl, wd }) => wl || wd || w};
  }
  @media ${devices.miniLaptop} {
    margin: ${({
    m, mml, ml, md,
  }) => mml || ml || md || m};
    width: ${({
    w, wml, wl, wd,
  }) => wml || wl || wd || w};
  }
  @media ${devices.tablet} {
    margin: ${({
    m, mt, mml, ml, md,
  }) => mt || mml || ml || md || m};
    width: ${({
    w, wt, wml, wl, wd,
  }) => wt || wml || wl || wd || w};
  }
  @media ${devices.mobile} {
    margin: ${({
    m, mm, mt, mml, ml, md,
  }) => mm || mt || mml || ml || md || m};
    width: ${({
    w, wm, wt, wml, wl, wd,
  }) => wm || wt || wml || wl || wd || w};
  }
`;

Img.defaultProps = {
  m: 0,
  md: null,
  ml: null,
  mt: null,
  mm: null,
};

export default Img;
