import styled from 'styled-components';
import {
  background,
  border,
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  typography,
} from 'styled-system';

const CustomBox = styled.div`
  cursor: ${({ cursor = 'inherit' }) => cursor};
  visibility: ${({ visibility }) => visibility};
  transition: ${({ transition }) => transition};
  gap: ${({ gap }) => gap};
  box-sizing: 'border-box';
  ${({ hover }) => hover}
  ${({ customCss }) => customCss};
`;

const BoxStyled = styled(CustomBox)(
  compose(space, color, layout, border, position, shadow, background, flexbox, typography, grid)
);

BoxStyled.defaultProps = {
  m: null,
  md: null,
  ml: null,
  mt: null,
  mm: null,
  p: null,
  pd: null,
  pl: null,
  pt: null,
  pm: null,
  display: 'block',
  hidden: false,
};

export default BoxStyled;
