import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '@app/modules/core/infrastructure/store';
import {
  ClientDocsWithCatIdAndName,
  ClientDocsWithCognitoAndZoneInfo,
} from '../domain/mortgageCase';
import { extractAllClientsDocs } from './mortgageHelpers';
// TODO: Cycle dependency
// eslint-disable-next-line import/no-cycle
import {
  matchSingleCaseApplicationReceived,
  matchSingleOriginalCaseApplicationReceived,
} from './mortgageSlice';

export const documentFileEntityAdapter = createEntityAdapter<ClientDocsWithCatIdAndName>({ selectId: (file) => file.id });

const clientsInitial: ClientDocsWithCognitoAndZoneInfo[] = [];

const caseClientDocumentsSlice = createSlice({
  name: 'caseClientDocuments',
  initialState: {
    clients: clientsInitial,
    clientsDocuments: documentFileEntityAdapter.getInitialState(),
  },
  reducers: {
    addClientDocument(state, action) {
      documentFileEntityAdapter.addOne(state.clientsDocuments, action.payload);
    },
    updateClientDocument(state, action) {
      documentFileEntityAdapter.updateOne(state.clientsDocuments, action.payload);
    },
    removeClientDocument(state, action) {
      documentFileEntityAdapter.removeOne(state.clientsDocuments, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(matchSingleCaseApplicationReceived, (state, action) => {
      // TODO Continue Refactoring here
      const clientDocs = extractAllClientsDocs(action.payload);
      const documents = clientDocs?.map((client) => client?.files).flat();
      state.clients = clientDocs;
      documentFileEntityAdapter.setAll(state.clientsDocuments, documents);
    });
    builder.addMatcher(matchSingleOriginalCaseApplicationReceived, (state, action) => {
      // TODO Continue Refactoring here
      const clientDocs = extractAllClientsDocs(action.payload);
      const documents = clientDocs?.map((client) => client?.files).flat();
      state.clients = clientDocs;
      documentFileEntityAdapter.setAll(state.clientsDocuments, documents);
    });
  },
});

export const { addClientDocument, updateClientDocument, removeClientDocument } = caseClientDocumentsSlice.actions;
export default caseClientDocumentsSlice.reducer;

// Can create a set of memoized selectors based on the location of this entity state

export const {
  selectAll: selectAllClientsDocuments,
  selectById: selectClientDocumentsById,
  selectEntities: selectClientDocumentsEntities,
  // selectIds: selectRequiredApplicationFormsCategoryIds,
} = documentFileEntityAdapter.getSelectors(
  (state: RootState) => state.mortgage.caseClientDocumentsDetails.clientsDocuments
);
export const selectCaseClientsStep = (state: RootState) => state.mortgage.caseClientDocumentsDetails;
export const selectCaseClientsSections = (state: RootState) => [...state.mortgage.caseClientDocumentsDetails.clients]?.sort((a, _b) => (a?.uploadZoneSubTitle?.includes('Main applicant') ? -1 : 1));

// And then use the selectors to retrieve values
// const allBooks = clientDocsSelectors.selectAll(store.getState())
