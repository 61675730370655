import { datadogLogs } from '@datadog/browser-logs';
import { User } from '@app/modules/core/domain/user';
import {
  FORGOT_PASSWORD_ENDPOINT,
  FORGOT_PASSWORD_VERIFY_ENDPOINT,
  REFRESH_TOKEN_ENDPOINT,
  RESET_PASSWORD_ENDPOINT,
  RESET_PASSWORD_SESSION_ENDPOINT,
} from '@app/modules/core/infrastructure/api/constants';

const isSensetiveDataEndpoint = (url: string) => {
  const endpointsToExclude = [
    FORGOT_PASSWORD_ENDPOINT,
    FORGOT_PASSWORD_VERIFY_ENDPOINT,
    RESET_PASSWORD_ENDPOINT,
    RESET_PASSWORD_SESSION_ENDPOINT,
    REFRESH_TOKEN_ENDPOINT
  ];
  return endpointsToExclude.includes(url);
};

const dataDogErrorLogger = (broker: User, err: any, reqArgs: any) => {
  datadogLogs.logger.setContext({
    logger_severity: 'Error',
    broker,
  });

  datadogLogs.logger.error(JSON.stringify(err), reqArgs);
};

const dataDogInfoLogger = (broker: User, data: any) => {
  datadogLogs.logger.setContext({ broker });

  datadogLogs.logger.info(JSON.stringify(data), data);
};

export {
  isSensetiveDataEndpoint, dataDogErrorLogger, dataDogInfoLogger
};
