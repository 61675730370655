import Box from '@app/components/common/basic/Containers/Box';
import theme from '@app/style/theme';
import CheckCircle from '../../icons/CheckCircle';

const PropertyAndMortgageIcon = ({ type }) => {
  let hollowColor = theme.colors.purple700;
  let objectColor = theme.colors.purple700;

  let hollowOpacity = 0.1;

  switch (type) {
    case 'main': {
      hollowColor = theme.colors.purple700;
      objectColor = theme.colors.purple700;
      break;
    }
    case 'blurred': {
      hollowColor = theme.colors.gray;
      objectColor = theme.colors.gray;
      break;
    }
    case 'selected': {
      hollowColor = theme.colors.purple700;
      objectColor = theme.colors.white;
      hollowOpacity = 1;
      break;
    }
    case 'success': {
      hollowColor = theme.colors.green;
      objectColor = theme.colors.green;
      break;
    }
    case 'progress': {
      hollowColor = theme.colors.orangeLight;
      objectColor = theme.colors.orangeLight;
      break;
    }
    case 'white': {
      hollowColor = theme.colors.white;
      objectColor = theme.colors.white;
      break;
    }
    default: {
      break;
    }
  }

  return (
    <Box position='relative'>
      <Box position='absolute' top='-4px' right='-4px' hidden={ type !== 'accomplished' }>
        <CheckCircle />
      </Box>

      <svg
        width='32'
        height='32'
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle opacity={ hollowOpacity } cx='16' cy='16' r='16' fill={ hollowColor } />
        <path
          d='M10 14L16 9.33333L22 14V21.3333C22 21.687 21.8595 22.0261 21.6095 22.2761C21.3594 22.5262 21.0203 22.6667 20.6667 22.6667H11.3333C10.9797 22.6667 10.6406 22.5262 10.3905 22.2761C10.1405 22.0261 10 21.687 10 21.3333V14Z'
          stroke={ objectColor }
          strokeWidth='1.4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14 22.6667V16H18V22.6667'
          stroke={ objectColor }
          strokeWidth='1.4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Box>
  );
};

PropertyAndMortgageIcon.defaultProps = { type: 'main', // main, blurred, selected
};

export default PropertyAndMortgageIcon;
