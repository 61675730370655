import { apiSlice } from '@app/modules/core/infrastructure/api/apiSlice';
import {
  GOVERNMENT_FEES_ENDPOINT,
  PROPOSALS_ENDPOINT,
} from '@app/modules/core/infrastructure/api/constants';
import {
  bankProductsReq,
  GovFeesRes,
  ProposalAPIRes,
  ProposalCreationReq,
  ProposalUpdateReq,
} from '@app/modules/proposal/domain/proposals';

export const proposalApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProposals: builder.query<[], void>({
      query: () => `${PROPOSALS_ENDPOINT}/`,
      providesTags: ['Proposals'],
    }),
    getProposalById: builder.query<ProposalAPIRes, { id: number }>({
      query: ({ id }) => `${PROPOSALS_ENDPOINT}/${id}`,
      keepUnusedDataFor: 0,
      providesTags: ['Proposals'],
    }),
    getGovernmentFees: builder.query<GovFeesRes, void>({
      queryFn: async (_args, _queryApi, _extraOptions, baseQuery) => {
        const result = await baseQuery(`${GOVERNMENT_FEES_ENDPOINT}`);
        return { data: result.data as GovFeesRes };
      },
    }),

    createProposalRequest: builder.mutation<any, ProposalCreationReq>({
      queryFn: async (
        {
          clientInfoPayload, mortgageInfoPayload, onSuccess, onError,
        },
        _queryApi,
        _extraOptions,
        baseQuery
      ) => {
        const saveClientDetails = () => baseQuery({
          url: `${PROPOSALS_ENDPOINT}/client_details/`,
          method: 'POST',
          body: clientInfoPayload,
          // eslint-disable-next-line sonarjs/no-duplicate-string
          contentType: 'application/json',
        });
        const saveMortgageResponse = (id: number) => baseQuery({
          url: `${PROPOSALS_ENDPOINT}/mortgage_details/${id}/`,
          method: 'PUT',
          body: mortgageInfoPayload,
          contentType: 'application/json',
        });
        const saveClientDetailsResponse = (await saveClientDetails()) as any;
        if (!saveClientDetailsResponse?.data) {
          onError(saveClientDetailsResponse.error);
          return { data: saveClientDetailsResponse };
        }
        const response = (await saveMortgageResponse(
          saveClientDetailsResponse.data?.response?.id as number
        )) as any;
        if (response.data) {
          response.data.response.id = saveClientDetailsResponse.data?.response?.id;
          onSuccess(response.data);
        } else onError(response.error);
        return { data: response };
      },
    }),

    updateProposalRequest: builder.mutation<any, ProposalUpdateReq>({
      queryFn: async (
        {
          clientInfoPayload, mortgageInfoPayload, onSuccess, onError,
        },
        _queryApi,
        _extraOptions,
        baseQuery
      ) => {
        const { id: clientId, ...clientInfo } = clientInfoPayload;
        const { id: mortgageId, ...mortgageDetails } = mortgageInfoPayload;
        const saveClientDetails = async () => baseQuery({
          url: `${PROPOSALS_ENDPOINT}/client_details/${clientId}/`,
          method: 'PUT',
          body: clientInfo,
          contentType: 'application/json',
        });
        const saveMortgageResponse = async () => baseQuery({
          url: `${PROPOSALS_ENDPOINT}/mortgage_details/${mortgageId}/`,
          method: 'PUT',
          body: mortgageDetails,
          contentType: 'application/json',
        });
        const clientResponse = await saveClientDetails();
        const mortgageResponse = await saveMortgageResponse();
        const data = { mortgageInfo: mortgageResponse?.data, clientInfo: clientResponse?.data };
        if (mortgageResponse && clientResponse) {
          onSuccess(data);
        } else onError(mortgageResponse?.error || clientResponse?.error);
        return { data };
      },
      invalidatesTags: ['Proposals'],
    }),
    updateProposalBankProductsReq: builder.mutation<any, bankProductsReq>({
      queryFn: async ({ id, selectedProducts }, _queryApi, _extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `${PROPOSALS_ENDPOINT}/bank_offers_details/${id}/`,
          method: 'PUT',
          body: selectedProducts,
          contentType: 'application/json',
        });

        return { data: result.data };
      },
    }),
    generateProposalPreview: builder.query<any, { id: number }>({
      queryFn: async ({ id }, _queryApi, _extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `${PROPOSALS_ENDPOINT}/${id}/preview`,
          contentType: 'application/json',
        });
        return { data: result.error?.data };
      },
    }),

    duplicateProposal: builder.mutation({
      query: ({ proposalId }) => ({
        url: `${PROPOSALS_ENDPOINT}/${proposalId}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: (_result, error) => (error ? [] : ['Proposals']),
    }),

    addOpportunityToProposal: builder.mutation({
      query: ({ proposalId, mortgageExternalId }) => ({
        url: `${PROPOSALS_ENDPOINT}/${proposalId}/add-opportunity-to-proposal`,
        method: 'PUT',
        body: { opportunity_external_id: mortgageExternalId },
      }),
    }),

    generateProposalPdf: builder.query({
      query: ({ id }) => ({
        url: `${PROPOSALS_ENDPOINT}/generate_pdf/${id}`,
        method: 'PUT',
      }),
    }),
  }),
});

export const {
  useGetProposalsQuery,
  useGetProposalByIdQuery,
  useGetGovernmentFeesQuery,
  useUpdateProposalRequestMutation,
  useCreateProposalRequestMutation,
  useUpdateProposalBankProductsReqMutation,
  useLazyGenerateProposalPdfQuery,
  useLazyGenerateProposalPreviewQuery,
  useDuplicateProposalMutation,
  useAddOpportunityToProposalMutation,
} = proposalApiSlice;
