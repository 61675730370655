export const colors = {
  primary: {
    100: '#F1EDFD',
    200: '#C6B9F7',
    300: '#AA96F2',
    400: '#8D73EE',
    500: '#7150EA',
    600: '#603BE7',
    700: '#4E24E5',
    800: '#421AD6',
    900: '#3515AD',
  },
  neutral: {
    100: '#FFFFFF',
    200: '#FAFAFA',
    300: '#F5F5F5',
    400: '#E5E5E5',
    500: '#CCCCCC',
    600: '#999999',
    700: '#666666',
    800: '#2E3138',
    900: '#000000',
  },
  emotional: { yellow: { 500: '#F8D448', 700: '#ECBD09' } },
} as const;
