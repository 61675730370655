import { createSlice } from '@reduxjs/toolkit';
// TODO: Cycle dependency
// eslint-disable-next-line import/no-cycle
import { RootState } from '@app/modules/core/infrastructure/store';
import { ImageUrl } from 'modules/core/domain/shared';
import { Dependent, MortgageCaseAPIFullRes } from '../domain/mortgageApplicationApiRes';
import {
  AssignedAccountManager,
  CaseTimeLineStatus,
  DocumentCategoryFile,
  OperationalDataUI,
} from '../domain/mortgageCase';
import {
  CaseIncomeInfo,
  CaseMainApplicant,
  CaseMortgageInfo,
  DependentDetailsInfo,
  DependentIncomeInfo,
} from '../domain/mortgageCaseStatus';
import { extractCaseStatusData } from './mortgageHelpers';
import { matchSingleCaseApplicationReceived } from './mortgageSlice';

const INITIAL_STATE: {
  mortgageId: number;
  mainApplicantId: string;
  mainApplicant: CaseMainApplicant | null;
  incomeforMainApplicant: CaseIncomeInfo | null;
  incomeForCoBorrowers: {
    name: string;
    info: DependentIncomeInfo;
  }[];
  mortgageInfo: CaseMortgageInfo | null;
  createdAt: Date | null;
  assignedAccountManager: AssignedAccountManager | null;
  banks: { id: number; name: string; logo: ImageUrl }[];
  mainApplicantAllSubmittedBanks: { id: number; title: string }[];
  bankApplicationType: string;
  banksDocs: Record<string, any>;
  mainApplicantDocs: { clientName?: string; clientDocs?: Record<string, DocumentCategoryFile[]> };
  depenedentsDetails: DependentDetailsInfo[];
  coBorrowersDocs: Record<string, { [key: string]: DocumentCategoryFile[] }>;
  statusHistory: CaseTimeLineStatus[];
  caseCurrentStatus: string;
  operationalDetails: OperationalDataUI | null;
  caseComments: string | null;
} = {
  mortgageId: null,
  mainApplicant: null,
  mainApplicantId: null,
  mortgageInfo: null,
  assignedAccountManager: null,
  createdAt: null,
  incomeforMainApplicant: null,
  incomeForCoBorrowers: [],
  banks: [],
  bankApplicationType: '',
  mainApplicantAllSubmittedBanks: [],
  banksDocs: {},
  mainApplicantDocs: {},
  depenedentsDetails: [],
  coBorrowersDocs: {},
  statusHistory: [],
  caseCurrentStatus: '',
  operationalDetails: null,
  caseComments: null,
};

const mortgageCaseStatusSlice = createSlice({
  name: 'mortgageCaseStatus',
  initialState: INITIAL_STATE,
  reducers: {
    setMortgageCaseStatus: (state, action) => {
      state.mainApplicant = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(matchSingleCaseApplicationReceived, (state, action) => {
      const mortgageCaseData = action.payload as MortgageCaseAPIFullRes & Dependent;
      const caseFormattedData = extractCaseStatusData(mortgageCaseData);
      state.mortgageId = caseFormattedData?.mortgageId;
      state.mainApplicant = caseFormattedData?.mainApplicant;
      state.mainApplicantId = caseFormattedData?.mainApplicantId;
      state.incomeforMainApplicant = caseFormattedData?.incomeforMainApplicant;
      state.mortgageInfo = caseFormattedData?.mortgageInfo;
      state.banks = caseFormattedData?.banks;
      state.bankApplicationType = caseFormattedData?.bankApplicationType;
      state.mainApplicantAllSubmittedBanks = caseFormattedData?.mainApplicantAllSubmittedBanks;
      state.banksDocs = caseFormattedData?.banksDocs;
      state.mainApplicantDocs = caseFormattedData?.mainApplicantDocs;
      state.depenedentsDetails = caseFormattedData?.depenedentsDetails;
      state.coBorrowersDocs = caseFormattedData?.coBorrowersDocs;
      state.incomeForCoBorrowers = caseFormattedData?.incomeForCoBorrowers;
      state.assignedAccountManager = caseFormattedData?.assignedAccountManager;
      state.statusHistory = caseFormattedData?.statusHistory;
      state.createdAt = caseFormattedData?.createdAt;
      state.operationalDetails = caseFormattedData?.operationalDetails;
      state.caseComments = caseFormattedData?.caseComments;
      state.caseCurrentStatus = caseFormattedData?.caseCurrentStatus;
      return state;
    });
  },
});

export const { setMortgageCaseStatus } = mortgageCaseStatusSlice.actions;
export default mortgageCaseStatusSlice.reducer;
export const selectMortgageCaseDetails = (state: RootState) => state.mortgage.mortgageCaseStatusDetails;
