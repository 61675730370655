const eventNames = {
  login: 'ma_login_success',
  logout: 'ma_logout_success',
  requestAccess: 'ma_request_access',

  signUpNewFlowCompanyInput: 'ma_signup_company_name_input',
  signUpNewFlowContinueAsFreelancer: 'ma_signup_continue_as_freelancer',
  signUpNewFlowUploadedLogo: 'ma_signup_uploaded_logo',
  signUpNewFlowProfileSaveChanges: 'ma_signup_profile_Save_changes',
  signUpNewFlowProfileSkipped: 'ma_signup_profile_skipped',

  onBoardingSkipped: 'ma_onboarding_skipped',
  onBoardingCompleted: 'ma_onboarding_completed',
  caseCreationStarted: 'ma_case_creation_started',

  createCaseFormInitiated: 'ma_case_creation_form_initiated',
  createCaseClientDetailsStep: 'ma_case_client_details_step',
  createCaseIncomeMortgageStep: 'ma_case_income_mortgage_step',
  createCaseBankSelectionStep: 'ma_case_banks_selection_step',

  createCaseBankRateSelected: 'ma_case_Bank_rate_Selected',
  createCaseBankManualRateSelected: 'ma_case_Manual_Rate_Entered',

  createCaseApplicationFormsStep: 'ma_case_application_forms_step',
  createCaseClientDocsStep: 'ma_case_client_docs_step',
  createCaseReviewSubmitStep: 'ma_case_review_submit_step',
  createCaseSubmitted: 'ma_case_submitted_successfully',
  createCaseHSBCTagClicked: 'ma_case_HSBC_tag_clicked',

  casesOverviewFilterMacroTabClicked: 'ma_filter_macro_tab_clicked',
  casesOverviewSearchFilter: 'ma_search_filter_used',
  casesOverviewFilterByStatus: 'ma_overview_filter_by_case_status',
  casesOverviewFilterBySubmitter: 'ma_overview_filter_by_submitter',
  casesOverviewFilterByNeedsInput: 'ma_overview_filter_by_needs_input',
  casesOverviewResumeCaseSubmission: 'ma_overview_resume_case_submission',
  casesOverviewOpenCaseDetailsPage: 'ma_overview_open_case_details_page',

  casesSinglePageViewAllStatus: 'ma_case_details_view_all_statuses_clicked',
  casesSinglePageBackToOverview: 'ma_case_details_back_to_overview_clicked',
  caseHideColumnsDropdownClicked: 'ma_case_overview_hide_column_dropdown_clicked',
  caseHideColumnsDropdownApplyButtonClicked:
    'ma_case_overview_hide_column_dropdown_apply_button_clicked',

  // Post Submission
  caseOverviewSubmitAdditionalBankButtonClicked: 'ma_case_overview_submit_additionalbank_clicked',
  caseDetailsSubmitAdditionalBankButtonClicked: 'ma_case_details_submit_additionalbank_clicked',
  casePostSubmissionAdditionalBankSelected: 'ma_case_addtionalbank_banks_selected',
  casePostSubmissionAdditionalDocsUploaded: 'ma_case_addtionalbank_documents_uploaded',
  casePostSubmissionAdditionalSubmitted: 'ma_case_addtionalbank_submitted',

  brokerMenuToggled: 'ma_account_broker_menu_toggled',
  accountSettingsOptionsBtnClicked: 'ma_account_settings_clicked',
  accountSettingsChangedLogo: 'ma_account_changed_logo',
  accountSettingsColorScheme: 'ma_account_color_scheme_changed',
  accountSettingsSaveChanges: 'ma_account_settings_save_changes',
  accountSettingsCancelChanges: 'ma_account_settings_cancel_changes',
  accountSettingsConfirmChanges: 'ma_account_settings_confirm_changes',

  documentCenterSidebarBtnClicked: 'ma_document_center_sidebar_button_clicked',
  bankProductsSidebarBtnClicked: 'ma_bank_products_sidebar_button_clicked',
  portalGuideSidebarBtnClicked: 'ma_portal_guide_sidebar_button_clicked',
  reportProblemSidebarBtnClicked: 'ma_report_a_problem_sidebar_button_clicked',

  archivedTabClicked: 'ma_archived_tab_clicked',
  proposalsOnboardingUploadLogoBtnClicked: 'ma_proposals_Onboarding_Upload_Logo_button_clicked',
  proposalsOnboardingCreateBtnClicked: 'ma_proposals_Onboarding_Create_button_clicked',
  proposalsCreateFormInitiated: 'ma_proposals_creation_form_initiated',
  proposalsClientDetails: 'ma_proposals_client_details_step',
  proposalsMortgageDetails: 'ma_proposals_mortgage_details_step',
  proposalsBankProducts: 'ma_proposals_bank_products_step',
  proposalsGenerateClicked: 'ma_proposals_generate_button_clicked',
  proposalsDownloadPDFClicked: 'ma_proposals_download_pdf_clicked',
  casesOverviewSidebarBtnClicked: 'ma_cases_overview_sidebar_btn_clicked',
  proposalsOverviewSidebarBtnClicked: 'ma_proposals_overview_sidebar_btn_clicked',
  proposalsCreateSidebarBtnClicked: 'ma_proposals_create_sidebar_btn_clicked',
  proposalsCreatePrimaryBtnClicked: 'ma_proposals_create_primary_btn_clicked',
  proposalsViewIconClicked: 'ma_proposals_view_icon_clicked',
  proposalsEditBtnClicked: 'ma_proposals_edit_btn_clicked',
  proposalsTableRowClicked: 'ma_proposals_table_row_clicked',
  proposalsBankProductsFilterChange: 'ma_proposals_bank_products_filter_change',
  proposalsBankProductsFilterReset: 'ma_proposals_bank_products_filter_reset',
  proposalsBankProductsSortingChange: 'ma_proposals_bank_products_sorting_change',
  proposalsBankProductsSelected: 'ma_proposals_bank_products_selected',
  proposalsHSBCTagClicked: 'ma_proposals_bank_products_HSBC_tag_clicked',
  proposalsExportPDFClicked: 'ma_proposals_export_pdf_clicked',
  proposalsExportCSVClicked: 'ma_proposals_export_csv_clicked',
  proposalsDuplicateClicked: 'ma_proposals_duplicate_clicked',
  proposalsCreateCaseClicked: 'ma_proposals_create_case_clicked',
  proposalsToCaseModalClosed: 'ma_proposals_create_case_modal_closed',

  // EIBOR and Disclaimer rates calculations

  reportProblemBEiborDisclaimerModalBtnClicked:
    'ma_report_a_problem_eibor_disclaimer_modal_button_clicked',
  eiborRatesViewAllBtnClicked: 'eibor_rates_view_all_btn_clicked',
  bankRatesCalculationsModalCtaBtnClicked: 'bank_rates_calculations_modal_Cta_btn_clicked',
  bankProductsCardRatesCalculationsModalIconClicked:
    'bank_products_card_rates_calculations_modal_icon_clicked',
  bankRatesCalculationsModalNegativeFeedbackBtnClicked:
    'bank_rates_calculations_modal_negative_feedback_btn_clicked',
  bankRatesCalculationsModalPositiveFeedbackBtnClicked:
    'bank_rates_calculations_modal_positive_feedback_btn_clicked',

  // Bank Products Page
  bankProductCardDetailsDownloadBtnClicked:
    'ma_bank_products_bank_card_download_pdf_button_clicked',
  bankProductNewFeatureDialogCloseBtnClicked:
    'ma_bank_products_new_feature_dialog_close_button_clicked',
  bankProductNewFeatureDialogStartExploringBtnClicked:
    'ma_bank_products_new_feature_dialog_start_exploring_button_clicked',
  bankProductCardExpanded: 'ma_bank_products_expanded',

  // Proposal: Pending tasks
  proposalPendingTasksUploadLogoNowBtnClicked:
    'proposal_pending_tasks_upload_logo_now_button_clicked',
  proposalPendingTasksUploadLogoBtnClicked: 'proposal_pending_tasks_upload_logo_button_clicked',
  proposalPendingTasksCreateProposalBtnClicked:
    'proposal_pending_tasks_create_proposal_button_clicked',

  // Bank rates bug report
  bankRatesBugReportModalIconClicked: 'bank_rates_bug_report_modal_icon_clicked',
  bankRatesBugReportSubmitBtnClicked: 'bank_rates_bug_report_submit_button_clicked',

  // New Features Badges/Modal
  newFeatureTooltipCloseIconClicked: 'new_feature_tooltip_close_icon_clicked',

  // Attach Rate Top Bar
  attachRateTypeFormLinkClicked: 'attach_rate_typeform_clicked',
  attachRateCloseIconClicked: 'attach_rate_close_icon_clicked',

  // Bank updates
  bankUpdatesSidebarBtnClicked: 'bank_updates_sidebar_btn_clicked',

  // Bank updates
  payoutSidebarBtnClicked: 'payouts_sidebar_btn_clicked',

  // Vault Configurator
  vaultBankSelectionConfigurator: 'BankSelection-configurator',
};

export default eventNames;
