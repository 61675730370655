import AntPopover from 'antd/lib/popover';
import { FunctionComponent } from 'react';
import Span from '@app/components/common/basic/Containers/Span';
import { screenSizes } from '@app/style/constants';
import useMedia from '@hooks/useMedia';
import Box from '@app/components/DesignSystem/Box';

interface ToolTipProps {
  title?: string;
  content?: any;
  trigger?: string;
  onOpenChange?: (visible: boolean) => void;
  children: React.ReactElement<any, any> | null;
  visible?: boolean;
  placement?: any;
  placementT?: any;
  overlayClassName?: any;
  autoAdjustOverflow?: boolean;
  noSpan?: boolean;
  [props: string]: any;
}

const Tooltip: FunctionComponent<ToolTipProps> = ({
  title,
  content,
  trigger = 'hover',
  onOpenChange,
  children,
  visible,
  placement = 'top',
  placementT = 'left',
  overlayClassName,
  autoAdjustOverflow,
  noSpan = false,
  ...props
}) => {
  const isTablet = useMedia({ maxWidth: screenSizes.tablet });

  return title || content ? (
    <AntPopover
      trigger={ trigger }
      { ...(typeof visible === 'boolean' ? { visible } : {}) }
      title={ title }
      content={ content }
      onOpenChange={ onOpenChange }
      placement={ isTablet ? placementT || placement : placement }
      overlayClassName={ overlayClassName }
      autoAdjustOverflow={ autoAdjustOverflow }
      { ...props }
    >
      {noSpan ? <Box display='flex'>{children}</Box> : <Span>{children}</Span>}
    </AntPopover>
  ) : (
    children
  );
};

export default Tooltip;
