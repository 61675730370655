/* eslint-disable max-len */
import http from '@app/modules/core/http';
import {
  CHECK_IF_DIGITAL_DOCUMENT_LINK_GENERATED,
  CHECK_IF_USER_HAS_BETA_ACCESS,
  GENERATE_DOCUMENT_FROM_TEMPLATE,
  GET_DIGITAL_BANK_DOCUMENTS,
  REFERRALS_ENDPOINT,
  OPPORTUNITIES_ENDPOINT,
  OPPORTUNITIES_META_ENDPOINT,
  MORTGAGE_APPLICATION_ENDPOINT,
  DOCUMENT_CATEGORY_ENDPOINT,
  VAULTS_ENDPOINT,
  CLIENT_DETAILS_FROM_NEXUS,
  GET_FEATURE_FLAGS
} from '@app/modules/core/infrastructure/api/constants';

import {
  CreateOpportunityParams,
  Opportunity,
  SelectedOpportunityBankApplicationsPayload,
  SelectedOpportunityBankApplicationPayload,
  UpdateOpportunityApplicantParams,
  UpdateOpportunityParams,
  UploadBankApplicationDocumentRequest,
  UploadOpportunityApplicantDocumentRequest,
  DeleteBankApplicationDocumentRequest,
  DeleteOpportunityApplicantDocumentRequest,
  DocumentCategory,
  UpdateOpportunityApplicantDocumentRequest,
  BrokerOpportunitiesRes,
  BankApplication,
  SubmitOpportunityRequest,
  OpportunityVaultConfig,
  OpportunityVaultConfigReq,
  OpportunityVaultConfigPassCodeReq,
} from '@app/modules/superMortgage/types/opportunity';
import { Referral, ReferralRes } from '@app/modules/superMortgage/types/referral';
import { UserSectionsStepMapper } from '@app/modules/mortgage/domain/enums/caseApplicationUserSections';
import {
  BrokerOpportunitiesMetaRes,
  DigitalDocument,
  GenerateDigitalBankApplication,
  GetDigitalBankApplicationRequest,
  GetDigitalBankApplicationResponse,
  GetDigitalDocuments,
  SendDigitalBankApplication,
  FeatureFlags
} from '../types';

type SelectedBankAndRatesReq = {
  selectedBanks: SelectedOpportunityBankApplicationsPayload;
  opportunityExternalId: string;
};

type AddAdditionalBankApplication = {
  payload: SelectedOpportunityBankApplicationPayload;
  opportunityExternalId: string;
};

export const superMortgageApi = {
  // Retrieve data
  getBrokerOpportunities: (url: RequestInfo | URL) => http.get<BrokerOpportunitiesRes>(url),
  getBrokerOpportunitiesMeta: () => http.get<BrokerOpportunitiesMetaRes>(OPPORTUNITIES_META_ENDPOINT),
  getBankDigitalDocuments: (opportunityExternalId: string) =>
    http.get<GetDigitalDocuments>(`${GET_DIGITAL_BANK_DOCUMENTS}/opportunities/${opportunityExternalId}`),
  getBankApplicationById: (opportunityId: string, bankApplicationId: string) =>
    http.get<{ bank_application: BankApplication }>(
      `${OPPORTUNITIES_ENDPOINT}${opportunityId}/bank-applications/${bankApplicationId}`
    ),
  getOpportunityById: (id: string) =>
    http.get<{ opportunity: Opportunity }>(`${OPPORTUNITIES_ENDPOINT}${id}`),
  getVaultConfig: (id: string) =>
    http.get<OpportunityVaultConfig>(`${VAULTS_ENDPOINT}/${id}/config`),
  checkIfUserHasBetaAccess: () =>
    http.get<boolean>(CHECK_IF_USER_HAS_BETA_ACCESS),
  getFeatureFlags: () => http.get<FeatureFlags>(GET_FEATURE_FLAGS),
  getClientDetailsFromNexus: (client_external_id: string) =>
    http.get<{ first_name?: string, last_name?: string }>(`${CLIENT_DETAILS_FROM_NEXUS}/${client_external_id}`),

  // Data modification
  generateDocumentFromTemplate: (referral_external_id: string, document_template_external_id: string) =>
    http.post<{ document: DigitalDocument }>(GENERATE_DOCUMENT_FROM_TEMPLATE, {
      referral_external_id,
      document_template_external_id,
    }),
  checkIfDigitalDocumentLinkGenerated: (document_external_id: string) =>
    http.post<DigitalDocument>(CHECK_IF_DIGITAL_DOCUMENT_LINK_GENERATED, { document_external_id }),
  saveLatestCaseStep: (step: number, mortgageApplicationId: number) =>
    http.update<any>(
      `${MORTGAGE_APPLICATION_ENDPOINT}/${mortgageApplicationId}`,
      { ma_section: UserSectionsStepMapper[step] }
    ),
  createReferral: (referral: Referral) =>
    http.post<ReferralRes>(REFERRALS_ENDPOINT, referral),
  createOpportunity: ({ referral_external_id, applicants }: CreateOpportunityParams) =>
    http.post<{ opportunity_external_id: string }>(OPPORTUNITIES_ENDPOINT, { referral_external_id, applicants }),
  updateOpportunity: (opportunityExternalId: string, updateOpportunityParams: UpdateOpportunityParams) =>
    http.patch<any>(
      `${OPPORTUNITIES_ENDPOINT}${opportunityExternalId}`,
      updateOpportunityParams
    ),
  updateOpportunityApplicant: ({
    opportunityExternalId,
    opportunityApplicantId,
    payload,
  }: UpdateOpportunityApplicantParams) =>
    http.patch<any>(
      `${OPPORTUNITIES_ENDPOINT}${opportunityExternalId}/applicants/${opportunityApplicantId}`,
      payload
    ),
  setOpportunityBankApplications: ({ selectedBanks, opportunityExternalId }: SelectedBankAndRatesReq) =>
    http.post(`${OPPORTUNITIES_ENDPOINT}${opportunityExternalId}/bank-applications`, selectedBanks),
  uploadBankApplicationDocument: ({
    opportunityExternalId,
    bankApplicationExternalId,
    payload,
  }: UploadBankApplicationDocumentRequest) => http.post(
    `${OPPORTUNITIES_ENDPOINT}${opportunityExternalId}/bank-applications/${bankApplicationExternalId}/documents`,
    payload
  ),
  uploadOpportunityApplicantDocument: ({
    opportunityExternalId,
    opportunityApplicantExternalId,
    payload,
  }: UploadOpportunityApplicantDocumentRequest) => http.post(
    `${OPPORTUNITIES_ENDPOINT}${opportunityExternalId}/applicants/${opportunityApplicantExternalId}/documents`,
    payload
  ),
  deleteBankApplicationDocument: ({
    opportunityExternalId,
    bankApplicationExternalId,
    documentExternalId,
  }: DeleteBankApplicationDocumentRequest) => http.delete(
    `${OPPORTUNITIES_ENDPOINT}${opportunityExternalId}/bank-applications/${bankApplicationExternalId}/documents/${documentExternalId}`
  ),
  deleteOpportunityApplicantDocument: ({
    opportunityExternalId,
    opportunityApplicantExternalId,
    documentExternalId,
  }: DeleteOpportunityApplicantDocumentRequest) => http.delete(
    `${OPPORTUNITIES_ENDPOINT}${opportunityExternalId}/applicants/${opportunityApplicantExternalId}/documents/${documentExternalId}`
  ),
  getCategoryList: () => http.get<DocumentCategory[]>(
    `${DOCUMENT_CATEGORY_ENDPOINT}/categories_list`
  ),
  updateOpportunityApplicantDocument: ({
    opportunityExternalId,
    opportunityApplicantExternalId,
    documentExternalId,
    document_category_id,
  }: UpdateOpportunityApplicantDocumentRequest) => http.patch(
    `${OPPORTUNITIES_ENDPOINT}${opportunityExternalId}/applicants/${opportunityApplicantExternalId}/documents/${documentExternalId}`,
    { document_category_id }
  ),
  submitOpportunity: ({ opportunityExternalId, payload }: SubmitOpportunityRequest) =>
    http.post(`${OPPORTUNITIES_ENDPOINT}${opportunityExternalId}/submit`, payload),
  addAdditionalBankApplication: ({ opportunityExternalId, payload }: AddAdditionalBankApplication) =>
    http.post<{ bank_application_external_id: string }>(
      `${OPPORTUNITIES_ENDPOINT}${opportunityExternalId}/additional-bank-applications`,
      payload
    ),
  createVault: (opportunityExternalId: string, { selected_banks }: Omit<OpportunityVaultConfigReq, 'require_kyc_details'>) =>
    http.post(`${VAULTS_ENDPOINT}/${opportunityExternalId}/config`, {
      opportunity_id: opportunityExternalId,
      selected_banks,
    }),
  updateVault: (opportunityExternalId: string, { selected_banks, require_kyc_details, applicants }: OpportunityVaultConfigReq) =>
    http.update(`${VAULTS_ENDPOINT}/${opportunityExternalId}/config`, {
      opportunity_id: opportunityExternalId,
      selected_banks,
      require_kyc_details,
      applicants,
    }),
  setVaultPassCode: (opportunityExternalId: string, { passcode }: OpportunityVaultConfigPassCodeReq) =>
    http.post(`${VAULTS_ENDPOINT}/${opportunityExternalId}/passcode`, { passcode }),
  getDigitalBankApplications: ({ opportunityExternalId }: GetDigitalBankApplicationRequest) =>
    http.get<GetDigitalBankApplicationResponse>(`${OPPORTUNITIES_ENDPOINT}${opportunityExternalId}/digital-bank-applications`),
  generateDigitalBankApplication: ({ opportunityExternalId, bankApplicationExternalId }: GenerateDigitalBankApplication) =>
    http.post<{ digital_bank_application_external_id: string }>(`${OPPORTUNITIES_ENDPOINT}${opportunityExternalId}/bank-applications/${bankApplicationExternalId}/digital-bank-applications`, {}),
  sendDigitalBankApplication: ({ opportunityExternalId, bankApplicationExternalId, digitalDocumentId }: SendDigitalBankApplication) =>
    http.post(`${OPPORTUNITIES_ENDPOINT}${opportunityExternalId}/bank-applications/${bankApplicationExternalId}/digital-bank-applications/${digitalDocumentId}/send`, {}),
};
