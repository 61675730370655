import { AnyAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '@app/modules/core/infrastructure/store';
import { clientInfoFields, mortgageInfoFields } from '../domain/proposals';
import { proposalApiSlice } from './api/proposalApiSlice';

const matchProposalsListReceived = proposalApiSlice.endpoints.getProposals.matchFulfilled;
const matchSingleProposalReceived = proposalApiSlice.endpoints.getProposalById.matchFulfilled;
const matchProposalCreated = proposalApiSlice.endpoints.createProposalRequest.matchFulfilled;
const matchProposalUpdated = proposalApiSlice.endpoints.updateProposalRequest.matchFulfilled;
const matchProposalUpdateFailed = proposalApiSlice.endpoints.updateProposalRequest.matchRejected;

const INITIAL_STATE: {
  proposals: any[];
  proposalApplication: any | null;
  proposalId: number;
  currentStep: number;
  shouldSaveClientAndMortgage: boolean;
  selectedProducts: any[];
  selectedProductsIds: any[];
  mortgageInfo: mortgageInfoFields;
  clientInfo: clientInfoFields;
} = {
  proposals: [],
  proposalApplication: null,
  proposalId: 0,
  currentStep: 0,
  shouldSaveClientAndMortgage: false,
  selectedProducts: [],
  selectedProductsIds: [],
  mortgageInfo: {
    is_documents_required: true,
    show_client_name: true,
    show_real_estate_fee: true,
    show_dld_fee: true,
    show_mortgage_registration_fee: true,
    show_transfer_center_fee: true,
  },
  clientInfo: {},
};

const proposalSlice = createSlice({
  name: 'proposal',
  initialState: INITIAL_STATE,
  reducers: {
    setProposals: (state, action) => {
      state.proposals = action.payload;
    },
    setProposalApplication: (state, action) => {
      state.proposalApplication = action.payload;
    },
    setProposaId: (state, action) => {
      state.proposalId = action.payload;
    },
    setClientInfo: (state, action) => {
      state.clientInfo = action.payload;
    },
    setMortgageInfo: (state, action) => {
      state.mortgageInfo = action.payload;
    },
    setCurrentProposalStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setShouldSaveClientAndMortgage: (state, action) => {
      state.shouldSaveClientAndMortgage = action.payload;
    },
    setSelectedProducts: (state, action) => {
      state.selectedProducts = action.payload;
    },
    setSelectedProductsIds: (state, action) => {
      state.selectedProductsIds = action.payload;
    },
    resetProposalApplication: (state) => {
      state.proposalApplication = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(matchProposalsListReceived, (state, action) => {
        state.proposals = action.payload;
      })
      .addMatcher(matchSingleProposalReceived, (state, action) => {
        state.proposalApplication = action.payload;
      })
      .addMatcher(matchProposalCreated, (state, action) => {
        state.proposalId = action.payload.data?.response?.id;
      })
      .addMatcher(matchProposalUpdated, (state) => {
        state.shouldSaveClientAndMortgage = false;
      })
      .addMatcher(matchProposalUpdateFailed, (state) => {
        state.shouldSaveClientAndMortgage = false;
      });
  },
});

export const {
  setProposals,
  setProposalApplication,
  setProposaId,
  setClientInfo,
  setMortgageInfo,
  resetProposalApplication,
  setCurrentProposalStep,
  setShouldSaveClientAndMortgage,
  setSelectedProducts,
  setSelectedProductsIds,
} = proposalSlice.actions;
const proposalsReducer = proposalSlice.reducer;
export const selectProposals = (state: RootState) => state.proposal.proposals;
export const selectProposalApplication = (state: RootState) => state.proposal.proposalApplication;
export const selectProposalId = (state: RootState) => state.proposal.proposalId;
export const selectClientInfo = (state: RootState) => state.proposal.clientInfo;
export const selectMortgageInfo = (state: RootState) => state.proposal.mortgageInfo;
export const selectCurrentStep = (state: RootState) => state.proposal.currentStep;
export const selectSelectedProducts = (state: RootState) => state.proposal.selectedProducts;
export const selectSelectedProductIds = (state: RootState) => state.proposal.selectedProductsIds;
export const selectShouldSaveClientAndMortgage = (state: RootState) => state.proposal.shouldSaveClientAndMortgage;

const resettableProposalReducer = (
  state: ReturnType<typeof proposalsReducer> | undefined,
  action: AnyAction
) => {
  if (action.type === 'proposal/proposalApplication/resetProposalApplication') {
    return proposalsReducer(undefined, action);
  }

  return proposalsReducer(state, action);
};

export default resettableProposalReducer;
