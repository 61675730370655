import styled from 'styled-components';
import theme from '@app/style/theme';
import commonCss from '../common';

/**
 * @deprecated This component is deprecated. Use `DesignSystem/Typography` instead.
 */
const Text = styled.p`
  ${commonCss}
  font-weight: ${({ type, fw }) => (fw || (type === 'h1' ? '600' : 'normal'))};
  pointer-events: ${({ pointerEvents }) => pointerEvents || 'initial'};
  text-transform: ${({ textTransform }) => textTransform};
  text-decoration: ${({ textDecoration }) => textDecoration};
  user-select: ${({ noSelect }) => noSelect && 'none'};
  flex-grow: ${({ flexGrow }) => flexGrow};
  font-family: ${({ titleFont }) => titleFont && theme.fontFamilies.title};
  &:before {
    ${({ before }) => before}
  }

  &:after {
    ${({ after }) => after}
  }
`;

Text.defaultProps = {
  m: '1rem 0',
  md: null,
  ml: null,
  mt: null,
  mm: null,
  type: '',
  lh: '21px',
  color: theme.black,
  hideOnMobile: false,
  pointer: false,
  noSelect: false,
  textTransform: 'inherit',
  align: 'inherit',
  display: 'block',
};

/**
 * @deprecated This component is deprecated. Use `SystemDesign/Text` instead.
 */
export default Text;
