import theme from '@app/style/theme';

const Dollar = ({ color, size }) => (
  <svg
    width={ size }
    height={ size }
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0)'>
      <path
        d='M20 1.66675V38.3334'
        stroke={ color }
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M28.3333 8.33325H15.8333C14.2862 8.33325 12.8025 8.94783 11.7085 10.0418C10.6146 11.1358 10 12.6195 10 14.1666C10 15.7137 10.6146 17.1974 11.7085 18.2914C12.8025 19.3853 14.2862 19.9999 15.8333 19.9999H24.1667C25.7138 19.9999 27.1975 20.6145 28.2915 21.7085C29.3854 22.8024 30 24.2862 30 25.8333C30 27.3804 29.3854 28.8641 28.2915 29.958C27.1975 31.052 25.7138 31.6666 24.1667 31.6666H10'
        stroke={ color }
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='40' height='40' fill={ color } />
      </clipPath>
    </defs>
  </svg>
);

Dollar.defaultProps = {
  color: theme.colors.green,
  size: 24,
};

export default Dollar;
