import { css } from 'styled-components';
import { devices } from '@app/style/constants';

export const getFontSize = (type, fz) => (type === 'p'
  ? '1.4rem'
  : type === 'small'
    ? '1.2rem'
    : type === 'title'
      ? '3rem'
      : type === 'heading'
        ? '7.2rem'
        : type === 'headingSmall'
          ? '5.8rem'
          : type === 'main'
            ? '4.8rem'
            : type === 'submain'
              ? '4.4rem'
              : type === 'big'
                ? '3.6rem'
                : type === 'h1'
                  ? '2.4rem'
                  : type === 'h2'
                    ? '2.1rem'
                    : type === 'h3'
                      ? '1.8rem'
                      : type === 'h4'
                        ? '1.6rem'
                        : fz || '1.4rem');
const adjustLineHeight = (size) => `${Number.parseFloat(size) + 1.4}rem`;

const getCommonCss = (suffix = '') => css`
  display: ${({ [`display${suffix}`]: display, hidden }) => (hidden ? 'none' : display)};
  visibility: ${({ [`visibility${suffix}`]: visibility }) => visibility};
  background-color: ${({ [`bgc${suffix}`]: bgc }) => bgc};
  background-size: ${({ [`bgs${suffix}`]: bgs }) => bgs};
  background-image: ${({ [`bgi${suffix}`]: bgi }) => bgi};
  background-repeat: ${({ [`bgr${suffix}`]: bgr }) => bgr};
  background-position: ${({ [`bgp${suffix}`]: bgp }) => bgp};
  color: ${({ [`color${suffix}`]: color }) => color};
  border-radius: ${({ [`borderRadius${suffix}`]: borderRadius }) => borderRadius};
  border-bottom: ${({ [`bb${suffix}`]: bb }) => bb};
  border-top: ${({ [`bt${suffix}`]: bt }) => bt};
  border-right: ${({ [`br${suffix}`]: br }) => br};
  border-left: ${({ [`bl${suffix}`]: bl }) => bl};
  border: ${({ [`b${suffix}`]: b }) => b};
  width: ${({ [`w${suffix}`]: w }) => w};
  height: ${({ fullHeight, [`h${suffix}`]: h }) => (fullHeight ? '100%' : h)};
  max-width: ${({ [`maxW${suffix}`]: maxW }) => maxW};
  min-width: ${({ [`minW${suffix}`]: minW }) => minW};
  min-height: ${({ [`minH${suffix}`]: minH }) => minH};
  max-height: ${({ [`maxH${suffix}`]: maxH }) => maxH};
  flex: ${({ [`flex${suffix}`]: flex }) => flex};
  filter: ${({ [`filter${suffix}`]: filter }) => filter};
  padding: ${({ [`p${suffix}`]: p }) => p};
  margin: ${({ [`m${suffix}`]: m }) => m};
  background-color: ${({ [`bgc${suffix}`]: bgc }) => bgc};

  overflow-x: ${({ [`overflowX${suffix}`]: overflowX }) => overflowX};
  overflow-y: ${({ [`overflowY${suffix}`]: overflowY }) => overflowY};
  overflow: ${({ [`overflow${suffix}`]: overflow }) => overflow};
  text-align: ${({ [`textAlign${suffix}`]: textAlign }) => textAlign};
  align-self: ${({ [`alignSelf${suffix}`]: alignSelf }) => alignSelf};
  justify-self: ${({ [`justifySelf${suffix}`]: justifySelf }) => justifySelf};
  transform: ${({ [`transform${suffix}`]: transform }) => transform};
  transition: ${({ [`transition${suffix}`]: transition }) => transition};
  position: ${({ [`position${suffix}`]: position }) => position};
  top: ${({ [`top${suffix}`]: top }) => top};
  bottom: ${({ [`bottom${suffix}`]: bottom }) => bottom};
  left: ${({ [`left${suffix}`]: left }) => left};
  right: ${({ [`right${suffix}`]: right }) => right};
  opacity: ${({ [`opacity${suffix}`]: opacity }) => opacity};
  visibility: ${({ [`visibility${suffix}`]: visibility }) => visibility};
  box-shadow: ${({ [`boxShadow${suffix}`]: boxShadow }) => boxShadow};
  z-index: ${({ [`zIndex${suffix}`]: zIndex }) => zIndex};
  letter-spacing: ${({ [`ls${suffix}`]: ls }) => ls || 'inherit'};
  word-spacing: ${({ [`ws${suffix}`]: ws }) => ws || 'inherit'};
  word-break: ${({ [`wordBreak${suffix}`]: wordBreak }) => wordBreak || 'inherit'};
  white-space: ${({ [`whiteSpace${suffix}`]: whiteSpace }) => whiteSpace};
  font-size: ${({ [`type${suffix}`]: type, [`fz${suffix}`]: fz }) => (type ? getFontSize(type, fz) : fz)};
  flex-direction: ${({ [`flexDirection${suffix}`]: flexDirection }) => flexDirection};
  flex-grow: ${({ [`flexGrow${suffix}`]: flexGrow }) => flexGrow};
  flex-basis: ${({ [`flexBasis${suffix}`]: flexBasis }) => flexBasis};
  justify-content: ${({ [`justifyContent${suffix}`]: justifyContent }) => justifyContent};
  align-items: ${({ [`alignItems${suffix}`]: alignItems }) => alignItems};
  text-align: ${({ [`align${suffix}`]: align }) => align};
  user-select: ${({ [`noSelect${suffix}`]: noSelect }) => noSelect && 'none'};
  pointer-events: ${({ [`pointerEvents${suffix}`]: pointerEvents }) => pointerEvents || 'initial'};
  line-height: ${({
    [`type${suffix}`]: type,
    [`fz${suffix}`]: fz,
    [`adjustedlh${suffix}`]: adjustedlh,
    [`lh${suffix}`]: lh,
  }) => (adjustedlh ? adjustLineHeight(getFontSize(type, fz)) : lh)};
  cursor: ${({ pointer, cursor }) => (pointer ? 'pointer !important' : (cursor || 'inherit'))};
`;

const commonCss = css`
  ${getCommonCss()};
  ${({ customCss }) => customCss}

  @media ${devices.desktop} {
    ${getCommonCss('D')}
  }
  @media ${devices.laptop} {
    ${getCommonCss('L')}
  }
  @media ${devices.miniLaptop} {
    ${getCommonCss('ML')}
  }
  @media ${devices.tablet} {
    ${getCommonCss('T')}
  }
  @media ${devices.mobile} {
    ${getCommonCss('M')}
  }
`;

export default commonCss;
