import { AUTHENTICATION_REFRESH_COOKIE } from '@app/utils/constants';
import Cookies from '@app/utils/cookies';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import camelcaseKeys from 'camelcase-keys';
import { addAlert } from '../alertSlice';
import { logOut, setCredentials } from '../authSlice';
import type { RootState } from '../store';
import { apiErrorAlertHandler } from './apiErrorHandler';
import { dataDogErrorLogger } from './apiErrorsLogger';
import { REFRESH_TOKEN_ENDPOINT } from './constants';

interface loginApiResponse {
  access_token: string;
  expires_in: number;
  token_type: string;
}

export const BASE_URL: string = process.env.REACT_APP_SERVERPATH as string;

const baseQuery = fetchBaseQuery({
  baseUrl: `${BASE_URL}/api`,
  prepareHeaders: (headers, { getState }) => {
    const { token } = (getState() as RootState).auth;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
      headers.set('x-source-product', 'brokers');
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error) {
    const { user } = api.getState().auth;
    // Log errors Datadog
    dataDogErrorLogger(user?.profile?.raw, result.error, args);

    // Handle Generic Errors
    const { alertMethod, errorMessage, errorStatus } = apiErrorAlertHandler(result?.error);

    // Handle Not Authorized Error / Session Expired
    if (errorStatus === 401) {
      const refreshToken = Cookies.get(AUTHENTICATION_REFRESH_COOKIE);
      // send refresh token to get new access token
      const refreshResult = await baseQuery(
        {
          url: REFRESH_TOKEN_ENDPOINT,
          method: 'POST',
          body: { refresh_token: refreshToken },
        },
        api,
        extraOptions
      );
      if (refreshResult?.data) {
        const { user } = api.getState().auth;
        // store the new token
        const { access_token } = refreshResult?.data as loginApiResponse;
        api.dispatch(setCredentials({ token: access_token, user }));
        // retry the original query with new access token
        result = await baseQuery(args, api, extraOptions);
      } else {
        api.dispatch(logOut());
        // Show error message
        api.dispatch(
          addAlert({
            title: 'Error',
            message: errorMessage,
            type: 'error',
            method: alertMethod,
          })
        );
      }
    }
  }
  // No error - procceed with result
  const camelCaseExclusionEndpoints = [
    'getBankProductsList',
    'getBankDocuments',
    'getProposalById',
    'updateProposalBankProductsReq',
    'updateProposalRequest',
    'createProposalRequest',
    'getEiborRates',
    'getGovernmentFees'
  ];

  if (result.data && !camelCaseExclusionEndpoints.includes(api.endpoint)) {
    const transformedData = await camelcaseKeys(result.data as any, {
      deep: true,
      stopPaths: [
        // TODO Ask Tarek to change API response to avoid this
        'status_history',
        'selected_banks',
        'main_selected_bank'
      ],
    });
    result = {
      ...result,
      data: transformedData,
    };
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  endpoints: (builder) => ({}),
  tagTypes: [
    'MortgageCases',
    'MortgageCaseApplication',
    'MortgageCaseRequiredBanks',
    'MortgageCaseDocumentCategoriesTypes',
    'MortgageCaseBankDocuments',
    'MortgageCaseClientDocuments',
    'AccountSettings',
    'Documents',
    'Proposals'
  ],
});
