import {
  identify as amplitudeIdentify,
  Identify,
  reset as amplitudeReset,
  setUserId,
  track as amplitudeTrack,
} from '@amplitude/analytics-browser';

export type AmplitudeUser = {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
};

export type AmplitudeEvent = {
  eventName: string;
  eventParams: Record<string, any> | undefined;
};

const identify = ({
  userId, firstName, lastName, email,
}: AmplitudeUser) => {
  const identifyObj = new Identify();
  amplitudeIdentify(identifyObj);
  setUserId(userId);

  amplitudeIdentify(identifyObj.set('name', `${firstName} ${lastName}`).set('email', email));
};

const track = ({ eventName, eventParams }: AmplitudeEvent) => {
  amplitudeTrack(eventName, eventParams);
};

const resetIdentity = () => amplitudeReset();

export {
  identify, track, resetIdentity
};
