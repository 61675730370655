import { createTheme } from '@mui/material/styles';
// eslint-disable-next-line no-restricted-imports
import type { ColorPartial } from '@mui/material/styles/createPalette';
import { themeConfig } from './configuration';

const { colors } = themeConfig;

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: colors.primary[700],
      100: colors.primary[100],
      200: colors.primary[200],
      300: colors.primary[300],
      400: colors.primary[400],
      500: colors.primary[500],
      600: colors.primary[600],
      700: colors.primary[700],
      800: colors.primary[800],
      900: colors.primary[900],
    },
    neutral: {
      100: colors.neutral[100],
      200: colors.neutral[200],
      300: colors.neutral[300],
      400: colors.neutral[400],
      500: colors.neutral[500],
      600: colors.neutral[600],
      700: colors.neutral[700],
      800: colors.neutral[800],
      900: colors.neutral[900],
    },
    emotional: { yellow: { 500: colors.emotional.yellow[500], 700: colors.emotional.yellow[700] } },
    text: {
      primary: colors.neutral[900],
      secondary: colors.primary[700],
    },
  },
  breakpoints: {
    values: {
      tablet: 768,
      smallLaptop: 1000,
      laptop: 1440,
      desktop: 1800,
    },
  },
  typography: {
    fontFamily: 'PPTelegraf',
    fontSize: 14,
    htmlFontSize: 10,
    h1: {
      fontSize: '6.4rem', fontWeight: 600, letterSpacing: '-2.56px', lineHeight: '100%',
    },
    h2: {
      fontSize: '5.2rem', fontWeight: 600, letterSpacing: '-2.08px', lineHeight: '100%',
    },
    h3: {
      fontSize: '4rem', fontWeight: 600, letterSpacing: '-1.6px', lineHeight: '100%',
    },
    h4: {
      fontSize: '2.8rem', fontWeight: 600, letterSpacing: '-1.12px', lineHeight: '100%',
    },
    h5: {
      fontSize: '2rem', fontWeight: 600, letterSpacing: '-0.8px', lineHeight: '100%',
    },
    xl: { fontSize: '2.4rem', fontWeight: 400, letterSpacing: '-0.48px' },
    lg: { fontSize: '2rem', fontWeight: 400, letterSpacing: '-0.2px' },
    md: { fontSize: '1.6rem', fontWeight: 400, letterSpacing: '-0.16px' },
    sm: {
      fontSize: '1.4rem', fontWeight: 400, letterSpacing: '-0.14px', lineHeight: '18,2px',
    },
    xs: { fontSize: '1.2rem', fontWeight: 400, letterSpacing: '-0.12px' },
  },
  components: {
    MuiButton: {
      variants: [{
        props: { color: 'neutral' },
        style: ({ theme }) => ({
          backgroundColor: 'transparent',
          color: theme.palette.neutral[600],
          border: `1px solid ${theme.palette.neutral[600]}`,
        }),
      }],
      defaultProps: { size: 'medium', variant: 'contained' },
      styleOverrides: {
        sizeMedium: {
          padding: '0 16px',
          height: '40px',
        },
        outlinedSecondary: ({ theme }) => ({
          borderColor: theme.palette.neutral[900],
          color: theme.palette.neutral[900],
          ':hover': {
            borderColor: theme.palette.neutral[900],
            backgroundColor: theme.palette.neutral[200],
            color: theme.palette.neutral[900],
          },
        }),
      },
    },
  },
});

type HuspyTypography = {
  xl: React.CSSProperties;
  lg: React.CSSProperties;
  md: React.CSSProperties;
  sm: React.CSSProperties;
  xs: React.CSSProperties;
};

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}
declare module '@mui/material/styles' {

  interface TypographyVariants extends HuspyTypography {}

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions extends HuspyTypography {}
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    // mobile: true;
    tablet: true;
    smallLaptop: true;
    laptop: true;
    desktop: true;
  }

  interface Palette {
    neutral: ColorPartial;
    emotional: {
      yellow: ColorPartial;
    }
  }

  interface PaletteOptions {
    neutral: ColorPartial;
    emotional: {
      yellow: ColorPartial;
    }
  }

  interface PaletteColor extends ColorPartial {}

  interface SimplePaletteColorOptions extends ColorPartial {}
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides extends HuspyTypography {
    body1: false;
    body2: false;
    button: false;
    caption: false;
    h6: false;
    xl: true;
    lg: true;
    md: true;
    sm: true;
    xs: true;
  }
}
