import figmaTheme from '@app/style/theme';

const Close = ({ color, size }) => (
  <svg
    width={ size }
    height={ size }
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.7279 11.6919C13.6828 11.6469 13.6574 11.5858 13.6574 11.522C13.6574 11.4583 13.6828 11.3971 13.7279 11.3521L22.6204 2.46059C23.1827 1.89752 23.182 0.98526 22.6189 0.42299C22.0559 -0.13928 21.1436 -0.138635 20.5814 0.42443L11.6898 9.31211C11.6448 9.35725 11.5837 9.38262 11.5199 9.38262C11.4562 9.38262 11.395 9.35725 11.35 9.31211L2.45847 0.42443C1.8962 -0.138105 0.984366 -0.13832 0.421831 0.42395C-0.140705 0.98622 -0.140919 1.89805 0.421351 2.46059L9.31191 11.3521C9.35705 11.3971 9.38242 11.4583 9.38242 11.522C9.38242 11.5858 9.35705 11.6469 9.31191 11.6919L0.421351 20.5844C0.0576257 20.9483 -0.0843336 21.4786 0.0489475 21.9756C0.182229 22.4725 0.570501 22.8606 1.06751 22.9936C1.56451 23.1267 2.09475 22.9845 2.45847 22.6206L11.35 13.7281C11.395 13.683 11.4562 13.6576 11.5199 13.6576C11.5837 13.6576 11.6448 13.683 11.6898 13.7281L20.5814 22.6206C21.1436 23.1831 22.0555 23.1833 22.618 22.6211C23.1805 22.0588 23.1807 21.147 22.6185 20.5844L13.7279 11.6919Z'
      fill={ color }
    />
  </svg>
);

Close.defaultProps = {
  color: figmaTheme.colors.neutral900,
  size: 24,
};

export default Close;
