import theme from '@app/style/theme';

const CrossCircle = ({ size }) => (
  <svg
    width={ size }
    height={ size }
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='8' cy='8' r='8' fill={ theme.colors.error } />
    <path
      d='M11 5L5 11'
      stroke='white'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5 5L11 11'
      stroke='white'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

CrossCircle.defaultProps = { size: 16 };

export default CrossCircle;
