export const AUTHENTICATION_COOKIE = 'hus_a';
export const AUTHENTICATION_REFRESH_COOKIE = 'hus_ar';
export const AUTHENTICATION_USER_ROLES = 'hus_roles';
export const AUTHENTICATION_USER_HS_ID = 'hus_hs_id';
export const AUTHENTICATION_USER_ACCESS = 'hus_access';

export const DEFAULT_BANK_IMAGE = 'https://freeiconshop.com/wp-content/uploads/edd/bank-flat.png';

export const DEFAULT_PERSON_IMAGE = 'https://cardiffstudentmedia.co.uk/xpress/wp-content/uploads/sites/5/2016/12/person-placeholder-5.png';

export const SAVED_PREVIOUSLY_PASSED_REFERRAL_CODE = 'H_previouslyPassedReferralCode';

export const SAVED_USER_UTM_SOURCE = 'H_sourceOfUtm';

export const AGENTS_APP_IOS_LINK = 'https://apps.apple.com/us/app/huspy-agent/id1550965254';
export const WHATSAPP_LINK = 'https://wa.me/+971526365950';
