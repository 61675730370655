import theme from '@app/style/theme';

const OverviewIcon = ({ type }) => {
  let hollowColor = theme.colors.purple700;
  let objectColor = theme.colors.purple700;

  let hollowOpacity = 0.1;

  switch (type) {
    case 'main': {
      hollowColor = theme.colors.purple700;
      objectColor = theme.colors.purple700;
      break;
    }
    case 'blurred': {
      hollowColor = theme.colors.gray;
      objectColor = theme.colors.gray;
      break;
    }
    case 'selected': {
      hollowColor = theme.colors.purple700;
      objectColor = theme.colors.white;
      hollowOpacity = 1;

      break;
    }
    case 'white': {
      hollowColor = theme.colors.white;
      objectColor = theme.colors.white;
      break;
    }
    default: {
      break;
    }
  }

  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle opacity={ hollowOpacity } cx='16' cy='16' r='16' fill={ hollowColor } />
      <path
        d='M14.6667 10H10V14.6667H14.6667V10Z'
        stroke={ objectColor }
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22 10H17.3333V14.6667H22V10Z'
        stroke={ objectColor }
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22 17.3333H17.3333V22H22V17.3333Z'
        stroke={ objectColor }
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.6667 17.3333H10V22H14.6667V17.3333Z'
        stroke={ objectColor }
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

OverviewIcon.defaultProps = { type: 'main', // main, blurred, selected
};

export default OverviewIcon;
