import { css } from 'styled-components';
import theme from '@app/style/theme';
import ArrowRight from '@assets/chevron-right-arrow.svg';

export const mainScrollWidthInRem = 0.7;

export const overwrites = css`
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
  .ant-select-item-option-selected:hover:not(.ant-select-item-option-selected-disabled) {
    background-color: ${theme.colors.purple700Light};
  }

  .ant-select-item-option-selected-active:not(.ant-select-item-option-selected-disabled) {
    background-color: ${theme.colors.purple700Light};
  }

  .ant-picker-dropdown {
    & .ant-picker-cell-selected {
      & .ant-picker-cell-inner {
        background: ${theme.colors.purple700};
        border-radius: 50%;
      }
    }

    & .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
      border: none;
    }
  }

  .ant-btn-primary {
    background-color: ${theme.colors.purple700};
    border: none;

    &:hover {
      background-color: ${theme.colors.purple700};
    }
  }

  .ant-message {
    top: unset;
    bottom: 2rem;

    & .ant-message-notice-content {
      background-color: ${theme.colors.neutral700};
      color: ${theme.colors.white};
    }

    /* Ant Toast Success Style */
    & .ant-message-notice-content {
      padding: 0;
      background: transparent;
      box-shadow: none;
    }

    & .ant-message-success,
    & .ant-message-error {
      border-radius: 80px;
      color: #fff;
      padding: 10px 16px;
      box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
        0 9px 28px 8px rgb(0 0 0 / 5%);
      pointer-events: all;
    }

    & .ant-message-success {
      background: ${theme.colors.feedbackAlertPositive};
    }

    & .ant-message-error {
      background: ${theme.colors.feedbackAlertNegative};
    }

    & .ant-message-success,
    & .ant-message-error {
      .anticon {
        color: #ffffff;
      }
    }
  }

  .ant-popover {
    .ant-popover-inner {
      border-radius: 8px;
      background-color: ${theme.colors.lightBlack};
    }

    .ant-popover-inner-content {
      color: ${theme.colors.white};
      font-size: 1rem;
      font-weight: 200;
    }

    &.bank-product-tag-w-tooltip {
      padding-top: 1.5rem;
      background: none !important;
      transform: translateY(2.9rem) !important;
      & .ant-popover-inner {
        border: 1px solid #000;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08) !important;
      }
      & .ant-popover-arrow {
        transform: translateY(-95%) translateX(-50%);
      }
      & .ant-popover-arrow-content {
        width: 11.99137085px;
        height: 11.99137085px;
      }
    }

    &.bank-product-tag-w-tooltip,
    &.bank-product-tag-w-tooltip .ant-popover-inner {
      background: #fff;
    }

    &.mb-new-feature-tooltip {
      position: fixed;
      &.mb-new-feature-tooltip-mobile {
        left: auto !important;
        right: 1rem;
      }

      .ant-popover-arrow {
        left: 13px;
      }

      .ant-popover-arrow-content:before {
        border: 2px solid #8d73ee;
        clip-path: none;
        position: absolute;
        background-color: #f1edfd;
      }

      .ant-popover-inner {
        border: 2px solid #8d73ee;
        background-color: #f1edfd;
      }

      &.mb-new-feature-tooltip-mobile .ant-popover-arrow {
        left: auto !important;
        right: 1rem;
        top: 1.2rem;
      }
    }
  }

  #mb-new-feature:after {
    content: 'New';
    position: absolute;
    right: 0.7rem;
    top: calc(50% - 1.4rem);
    opacity: 1;
    bottom: auto;
    transform: initial;
    border: none;
    background-color: rgb(236, 189, 9);
    padding: 0.2rem 1rem;
    line-height: 2;
    color: #fff;
    font-weight: 600;
    font-size: 1.2rem;
    border-radius: 5rem;
  }

  .side-bar-tooltip {
    left: 6.5rem !important;
    pointer-events: none;

    .ant-popover-arrow-content::before {
      background: ${theme.colors.neutral900} !important;
    }
  }
  .ant-popover-arrow {
    border-right-color: ${theme.colors.lightBlack} !important;
    border-left-color: ${theme.colors.lightBlack} !important;
    border-top-color: ${theme.colors.lightBlack} !important;
    border-bottom-color: ${theme.colors.lightBlack} !important;
  }
  .ant-popover-arrow {
    background: transparent;
  }
  .ant-popover-arrow-content {
    background: ${theme.colors.lightBlack};
  }

  #portal {
    z-index: 9999;
    position: relative;
  }

  .ant-modal-mask {
    background-color: ${theme.colors.mask};
  }

  .ant-modal-wrap {
    overflow-y: scroll;
    z-index: 1050;
    ::-webkit-scrollbar {
      width: ${mainScrollWidthInRem}rem;
    }
  }

  .ant-menu-inline-collapsed-tooltip {
    display: none;
  }

  .clients-docs-tooltip {
    .ant-tooltip-inner {
      border-radius: 0;
      padding: 2.4rem;
      color: ${theme.colors.neutral900};
      min-width: 300px;
    }
  }

  .sidebar-menu {
    background: transparent;

    &.limited-sidebar {
      opacity: 0.3;
      cursor: not-allowed;
    }

    .ant-menu-item,
    .ant-menu-item.ant-menu-item-selected,
    .ant-menu-submenu {
      margin-bottom: 2rem;
      cursor: pointer;
    }

    .ant-menu-submenu-title:active {
      background: transparent;
    }
    .ant-menu-item-disabled {
      background: transparent;
      opacity: 0.4;
      pointer-events: none;
    }

    .ant-menu-item.ant-menu-item-selected {
      background-color: ${theme.colors.purple700};

      a {
        color: #fff;
      }
    }
  }

  .dropdown-action-menu {
    cursor: pointer;
  }

  .broker-action-menu ul {
    border-radius: 0;

    li {
      padding: 0.75rem 2rem;

      &.ant-dropdown-menu-item-divider {
        display: none;
      }
    }
  }

  .huspy-action-menu ul {
    padding: 0;
    background-color: ${theme.colors.neutral100};
    li,
    li.ant-dropdown-menu-item-active {
      background-color: ${theme.colors.neutral100} !important;
      &:hover {
        background-color: ${theme.colors.neutral300} !important;
      }
    }

    > li {
      padding: 0.25rem 0.5rem;
      &:not(:last-child) {
        border-bottom: 1px solid ${theme.colors.neutral300};
      }
    }
  }

  .ant-drawer-header.ant-drawer-header-close-only {
    background-color: transparent;
    margin: 0 !important;
    padding: 0;
  }

  .ant-drawer-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    display: block;
    padding: 20px;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 700;
    font-size: 16px;
    font-style: normal;
    line-height: 1;
    text-align: center;
    text-transform: none;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
    text-rendering: auto;
  }

  .proposal-nav-item {
    .proposal-nav-item-box {
      text-align: center;
      height: 100%;
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;

      &:after {
        content: '';
        background-image: url(${ArrowRight});
        position: absolute;
        width: 12px;
        height: 12px;
        left: calc(100% + 16px);
        top: calc(50% - 6px);
      }
    }

    &.proposal-nav-item-done .proposal-nav-item-box {
      color: ${theme.colors.neutral900};
    }

    &.proposal-nav-item-active .proposal-nav-item-box {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -1px;
        width: 100%;
        height: 2px;
        background-color: ${theme.colors.purple700};
      }
    }

    &:last-child .proposal-nav-item-box:after {
      content: none;
    }
  }

  .ant-drawer-header.ant-drawer-header-close-only {
    background-color: transparent;
    margin: 0 !important;
    padding: 0;
  }

  .ant-drawer-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    display: block;
    padding: 20px;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 700;
    font-size: 16px;
    font-style: normal;
    line-height: 1;
    text-align: center;
    text-transform: none;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
    text-rendering: auto;
  }

  /* Responsive Sidebar */

  .sidebar-toggle svg {
    transition: all ease-in-out 0.5s;
  }

  .sidebar-collapsed {
    #mb-new-feature:after {
      content: '';
      width: 0.8rem;
      height: 0.8rem;
      padding: 0;
    }

    .broker-menu {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .ant-avatar {
        margin-right: 0 !important;
        margin-bottom: 1rem;
      }
    }

    .broker-action-menu {
      margin: 0 !important;
      height: 20px;
      transform: rotate(90deg);
    }

    .broker-welcome {
      display: none;
    }

    .ma-sidebar > div:first-child {
      padding: 1rem;

      .ant-menu-inline .ant-menu-item,
      .ant-menu-submenu-title {
        ${'' /* padding: 2rem !important; */}
        padding: 0 !important;
        justify-content: center;
        align-items: stretch;
        max-width: 6rem;

        .sidemenu-tooltip-wrapper {
          width: 100%;
          justify-content: center;
          align-items: stretch;
          display: flex;
          flex: auto;
          & > span {
            margin: 0;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .ant-menu-title-content,
      .ant-menu-sub,
      .ant-menu-submenu-arrow {
        display: none;
      }

      .ant-menu-item-selected,
      .ant-menu-item:hover {
        background-color: ${({ theme }) => theme.colors.purple700};
      }

      svg {
        margin-right: 0 !important;
      }
    }

    .sidebar-toggle svg {
      transform: rotate(180deg);
    }
  }

  .ma-sidebar > div:first-child {
    padding: 1rem;

    .ant-menu-inline .ant-menu-item,
    .ant-menu-submenu-title {
      ${'' /* padding: 2rem !important; */}
      padding: 0 !important;
      justify-content: center;
      align-items: stretch;

      .sidemenu-tooltip-wrapper {
        justify-content: center;
        align-items: stretch;
        display: flex;
        flex: none;
        min-width: 6rem;
        & > span {
          margin: 0;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        & ~ .ant-menu-title-content {
          line-height: 5.4rem;
          margin-left: 0;
        }
      }
    }

    .ant-menu-item-selected,
    .ant-menu-item:hover {
      background-color: ${({ theme }) => theme.colors.purple700};
    }

    svg {
      margin-right: 0 !important;
    }
  }

  .body--sidebar-open {
    .side-bar-tooltip {
      display: none !important;
    }
  }

  .top-bar-active {
    padding-top: 5.25rem;
    & > div {
      padding-top: 3rem;
    }
  }

  .TypeAheadDropDown {
    width: 100%;
    position: relative;
    z-index: 999;
  }

  .TypeAheadDropDown input {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum', 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
    letter-spacing: initial;
    color: #000000;
    border-radius: 0;
    padding-left: 1.5rem;
    height: 6rem;
    font-size: 1.6rem;

    &:hover,
    &:focus {
      outline: none;
      box-shadow: none;
      border-color: #dee2e9;
      border: 1px solid#DEE2E9;
    }
    &::placeholder {
      color: ${({ theme }) => theme.colors.neutral500};
      opacity: 1;
      font-size: ${({ $fz }) => $fz || '12px'};
    }
  }
  .TypeAheadDropDown ul::before {
    content: '';
  }

  .TypeAheadDropDown ul {
    list-style-type: none;
    text-align: left;
    margin: 6px 0 0 0;
    padding: 0;
    border: 1px solid #d9d9d9;
    max-height: 250px;
    overflow-y: scroll;
    position: relative;
    background: #fff;
    width: 100%;
    z-index: 999;
  }

  .TypeAheadDropDown li {
    padding: 10px 5px 10px 12px;
    cursor: pointer;
  }

  .TypeAheadDropDown li:hover {
    background: #f5f5f5;
  }

  .bank-filter-dropdown {
    .ant-select-selector {
      height: 5.6rem;

      .ant-select-selection-placeholder {
        color: ${theme.colors.neutral700};
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 4.1rem;
      }
    }

    &.bank-filter-dropdown__with-prefix-icon .ant-select-selector {
      padding-left: 4.5rem;
    }

    &.bank-filter-dropdown__mini {
      .ant-select-selector {
        height: 4rem;
        border-radius: 5rem;
        padding: 0.8rem 1.6rem;

        .ant-select-selection-placeholder {
          font-size: 1.2rem;
          line-height: 2.5rem;
        }
      }
    }
  }

  .black-arrow-tooltip {
    z-index: 9999999;
    & .ant-popover-arrow-content {
      --antd-arrow-background-color: #131d24;
    }
  }

  .table-sortable-column {
    background-repeat: no-repeat;
    &:hover {
      background-color: ${theme.colors.neutral400} !important;
      .table-sorting-arrows {
        background-position: -29px -1px;
      }
    }

    &.table-column-sorted__asc {
      background-color: ${theme.colors.neutral400} !important;
      .table-sorting-arrows {
        background-position: -57px -1px;
      }
    }
    &.table-column-sorted__desc {
      background-color: ${theme.colors.neutral400} !important;
      .table-sorting-arrows {
        background-position: -85px -1px;
      }
    }
  }

  .proposals-overview-table {
    .ant-table-tbody td {
      min-width: 2rem;
      max-width: 2rem;
      overflow-wrap: break-word !important;
      white-space: pre-wrap !important;
    }

    .ant-table-tbody tr th:nth-child(n + 7) {
      min-width: 5rem !important;
      max-width: 5rem !important;
    }
  }

  .exported-proposals-header-column {
    margin-left: 1rem;
    position: relative;

    &:first-child {
      margin-left: 0;
    }

    &:after {
      content: '|';
      position: absolute;
      right: 1rem;
      top: calc(50% - 1rem);
    }

    &:last-child:after {
      content: none;
    }
  }

  .exported-proposals-body-row:nth-child(odd) {
    background-color: ${theme.colors.neutral200};
  }

  .product-details-modal .ant-modal-close {
    top: 2rem;
    right: 1rem;
  }


  .vault-config-modal {   
    .ant-modal-body {
      padding: 0 !important;
    }
  }
  
  .ant-notification {
    z-index: 99999;
  }
`;
