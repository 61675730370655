enum UserSectionsStepMapper {
  'mb_portal_client_basic_info' = 0,
  'mb_portal_income_mortgage' = 1,
  'mb_portal_select_banks' = 2,
  'mb_portal_application_form' = 3,
  'mb_portal_client_docs' = 4,
  'mb_portal_review_and_submit' = 5
}

export { UserSectionsStepMapper };
