/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { AnyAction, combineReducers } from 'redux';
import type { RootState } from '@app/modules/core/infrastructure/store';

const INITIAL_CASE_APPLICATION_STATE: {
  opportunityExternalId: string;
  referralExternalId: string;

  mainApplicantPersonalDetailsId: number | null;
  currentStep: number;
  lastSavedSection: number;
  additionalComment: string | null;
  // operationalDetails?: OperationalDataAPIRes | null;
} = {
  opportunityExternalId: null,
  referralExternalId: null,

  mainApplicantPersonalDetailsId: null,
  currentStep: 0,
  lastSavedSection: 0,
  additionalComment: '',

};

const caseSubmissionSlice = createSlice({
  name: 'caseSubmissionSlice',
  initialState: INITIAL_CASE_APPLICATION_STATE,
  reducers: {
    setCaseReferralId: (state, action) => {
      state.referralExternalId = action.payload;
    },
    setCaseOpportunityId: (state, action) => {
      state.opportunityExternalId = action.payload;
    },
    setCaseCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setCaseLastSavedSection: (state, action) => {
      state.lastSavedSection = action.payload;
    },
    resetCaseApplication: () => INITIAL_CASE_APPLICATION_STATE,
  },
});

export const {
  setCaseOpportunityId,
  setCaseReferralId,
  setCaseCurrentStep,
  setCaseLastSavedSection,
  resetCaseApplication,
} = caseSubmissionSlice.actions;

export const selectCaseSubmission = (state: RootState) => state.opportunity.caseSubmission;

// Root MortgageCases Reducer
const opportunityCaseSubmissionReducer = combineReducers({
  caseSubmission: caseSubmissionSlice.reducer,
  // caseClientDetails: createCaseClientDetailsSlice,
  // caseIncomeMortgageDetails: createCaseIncomeMortgageSlice,
  // caseBanksDetails: createCaseBanksSlice,
  // caseApplicationFormDetails: createCaseApplicationFormsSlice,
  // caseClientDocumentsDetails: createCaseClientDocumentsSlice,
  // caseStatusDetails: caseStatusSlice,
});

const resettableOpportunityCaseSubmissionReducer = (
  state: ReturnType<typeof opportunityCaseSubmissionReducer> | undefined,
  action: AnyAction
) => {
  if (action.type === 'opportunity/caseSubmission/resetCaseSubmission') {
    return opportunityCaseSubmissionReducer(undefined, action);
  }

  return opportunityCaseSubmissionReducer(state, action);
};

export default resettableOpportunityCaseSubmissionReducer;
