// TODO: Dependency cycle
// eslint-disable-next-line import/no-cycle
import { apiSlice } from '@app/modules/core/infrastructure/api/apiSlice';
import { DOCUMENT_CATEGORY_ENDPOINT } from '@app/modules/core/infrastructure/api/constants';
import { ApplicationFormCategory } from '@app/modules/mortgage/domain/mortgageCase';

export const documentsCategoriesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDocsCategoriesList: builder.query<ApplicationFormCategory[], null>({
      query: () => `${DOCUMENT_CATEGORY_ENDPOINT}/categories_list`,
      providesTags: ['MortgageCaseDocumentCategoriesTypes'],
    }),
  }),
});

export const { useGetDocsCategoriesListQuery } = documentsCategoriesApiSlice;
