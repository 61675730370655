import InfoCircleFilled from '@ant-design/icons/InfoCircleFilled';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';

const InfoCircle = ({ size, color, isFilled }) => (isFilled ? (
  <InfoCircleFilled style={ { color, fontSize: size } } />
) : (
  <InfoCircleOutlined style={ { color, fontSize: size } } />
));

InfoCircle.defaultProps = {
  size: 16,
  isFilled: false,
};

export default InfoCircle;
