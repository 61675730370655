import { createSlice } from '@reduxjs/toolkit';
import { BrokerType, UserRole } from '@app/modules/core/types';
import { User, UserApiRes } from '../domain/user';
import { mapTokenToUser } from './authHelpers';
import type { RootState } from './store';

const formatUserApiInfo: (user: UserApiRes) => User = (user: UserApiRes) => {
  const isLimitedAccess = user?.isGrantedAccessToMbPortal === false;
  const isInternalBroker = user.userType === BrokerType.InternalBroker;
  let userRoles = user?.roles;
  if (isLimitedAccess || !userRoles?.length) {
    userRoles = [UserRole.Limited];
  } else if (isInternalBroker) {
    userRoles = [UserRole.InternalBroker];
  }
  return {
    hubSpotId: user?.hsObjectId,
    isFirstLogin: user?.isFirstLogin,
    isGrantedFullAccess: user?.isGrantedAccessToMbPortal,
    roles: userRoles,
    type: user?.type,
    userType: user?.userType,
  };
};

const INITIAL_STATE: {
  user: User | null;
  token: string | null;
  refreshToken: string | null;
} = { user: null, token: null, refreshToken: null };

export const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    setCredentials: (state, action) => {
      const { token, refreshToken, user } = action.payload;
      const userInfo = formatUserApiInfo(user);
      const profile = mapTokenToUser(token);

      state.user = { ...userInfo, profile };
      state.token = token;
      state.refreshToken = refreshToken;
    },
    setNewTokens: (state, action) => {
      const { token } = action.payload;
      state.token = token;
    },
    logOut: (state) => {
      state.user = null;
      state.token = null;
    },
  },
});

export const { setCredentials, setNewTokens, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectUserAuthenticated = (state: RootState) => !!state.auth.token;
