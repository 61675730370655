import envProvider from '@app/utils/helpers/envProvider';

export const ENVIRONMENTS_MAPPER = {
  development: 'development',
  partner: 'partner',
  production: 'production',
};

const getCurrentEnvironment = () => (envProvider.ENVIRONMENT === ENVIRONMENTS_MAPPER.production
  ? ENVIRONMENTS_MAPPER.production
  : (envProvider.ENVIRONMENT === ENVIRONMENTS_MAPPER.partner
    ? ENVIRONMENTS_MAPPER.partner
    : ENVIRONMENTS_MAPPER.development));

export default getCurrentEnvironment;

export const isDevelopmentEnvironment = envProvider.ENVIRONMENT === ENVIRONMENTS_MAPPER.development;

export const isPartnerEnvironment = envProvider.ENVIRONMENT === ENVIRONMENTS_MAPPER.partner;

export const isProductionEnvironment = envProvider.ENVIRONMENT === ENVIRONMENTS_MAPPER.production;

export const performInDevelopmentEnvironment = (callback: () => void) => {
  const currentEnvironment = getCurrentEnvironment();
  if (currentEnvironment === ENVIRONMENTS_MAPPER.development) {
    callback();
  }
};

export const performInProductionOrPartnerEnvironment = (callback: () => void) => {
  const currentEnvironment = getCurrentEnvironment();
  if (
    currentEnvironment === ENVIRONMENTS_MAPPER.production
    || currentEnvironment === ENVIRONMENTS_MAPPER.partner
  ) {
    callback();
  }
};
