/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { datadogRum } from '@datadog/browser-rum';
import envProvider from '@app/utils/helpers/envProvider';

export function initRum() {
  datadogRum.init({
    applicationId: envProvider.DATADOG_APP_ID!,
    clientToken: envProvider.DATADOG_CLIENT_TOKEN!,
    site: 'datadoghq.eu',
    service: 'huspy-ma-web',
    env: envProvider.DATADOG_ENV,
    sampleRate: 10,
    sessionReplaySampleRate: 10,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
  datadogRum.startSessionReplayRecording();
}
