import { CountryType } from '@app/modules/core/domain/enums/countryType';
// TODO: Dependency cycle
// eslint-disable-next-line import/no-cycle
import { apiSlice } from '@app/modules/core/infrastructure/api/apiSlice';
import { COUNTRIES_LIST_ENDPOINT } from '@app/modules/core/infrastructure/api/constants';

export const countriesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCountriesList: builder.query<any, void>({
      query: () => `${COUNTRIES_LIST_ENDPOINT}?limit=260`,
      transformResponse: (response: { results: CountryType[] }) => response?.results
        ?.sort((a, b) => a.name.localeCompare(b.name))
        ?.map((c) => ({
          label: c?.name,
          value: c?.id,
          ...c,
        }))
        ?.sort((c) => (c?.label?.toLowerCase()?.includes('united arab emirates') ? -1 : 1)),
    }),
  }),
});

export const { useGetCountriesListQuery } = countriesApiSlice;
