import Box from '@app/components/common/basic/Containers/Box';
import { IconProps } from '@app/modules/core/domain/svgIcon';
import theme from '@app/style/theme';

interface ArrowCircleRightFilledIconProps extends IconProps {
  direction: 'bottom' | 'left' | 'top';
}

const ArrowCircleRightFilledIcon = ({
  size = 16,
  color = theme.colors.purple700,
  direction,
  ...props
}: ArrowCircleRightFilledIconProps) => (
  <Box m='0 0 -0.5rem' transform={ direction === 'left' ? 'rotate(180deg)' : 'none' } { ...props }>
    <svg
      width={ size }
      height={ size }
      viewBox='0 0 55 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <ellipse cx='27.5' cy='28' rx='27.5' ry='28' fill={ color } />
      <path
        d='M20.625 28H34.375'
        stroke='white'
        strokeWidth='2.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M27.5 21L34.375 28L27.5 35'
        stroke='white'
        strokeWidth='2.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </Box>
);

export default ArrowCircleRightFilledIcon;
