/* eslint-disable no-param-reassign */
import { combineReducers, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '@app/modules/core/infrastructure/store';
import { BankProductsAPIResponse } from '../domain/models/bankProduct';
import { bankProductsApiSlice } from './api/bankProductsApiSlice';

export const matchBankProductsReceived = bankProductsApiSlice
  .endpoints.getBankProductsList.matchFulfilled;

const INITIAL_BANK_PRODUCTS_STATE: {
  bankProducts: BankProductsAPIResponse;
} = {
  bankProducts: {
    count: 0,
    next: '',
    prev: '',
    results: [],
    nextPage: 0,
  },
};

const bankProductsListSlice = createSlice({
  name: 'bankProductsListSlice',
  initialState: INITIAL_BANK_PRODUCTS_STATE,
  reducers: {
    setBankProducts: (state, action) => {
      state.bankProducts = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(matchBankProductsReceived, (state, action) => {
      state.bankProducts = action.payload;
    });
  },
});

export const { setBankProducts } = bankProductsListSlice.actions;
export const selectBankProducts = (state: RootState) => state.bankProducts.bankProducts;

const bankProductsReducer = combineReducers({ bankProducts: bankProductsListSlice.reducer });

export default bankProductsReducer;
