import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
// TODO: Cycle dependency
// eslint-disable-next-line import/no-cycle
import { RootState } from '@app/modules/core/infrastructure/store';
import { MortgageCaseAPIFullRes } from '../domain/mortgageApplicationApiRes';
import { ApplicationFormCategory, ApplicationFormFileApiRes } from '../domain/mortgageCase';
import { mapCategoryDocsWithCatId } from './mortgageHelpers';
import {
  matchCaseApplicationFormCategoryReceived,
  matchSingleCaseApplicationReceived,
} from './mortgageSlice';

export const requiredAppFormCategoryEntityAdapter = createEntityAdapter<ApplicationFormCategory>({ selectId: (category) => category.id });

export const bankDocumentsEntityAdapter = createEntityAdapter<ApplicationFormFileApiRes>({ selectId: (bankDocCategory) => bankDocCategory.id });

const caseBanksFormsCategoriesSlice = createSlice({
  name: 'caseBanksFormsCategories',
  initialState: {
    requiredApplicationFormsCategories: requiredAppFormCategoryEntityAdapter.getInitialState(),
    banksDocuments: bankDocumentsEntityAdapter.getInitialState(),
  },
  reducers: {
    bankFormsCategoryReceived(state, action) {
      requiredAppFormCategoryEntityAdapter.setAll(
        state.requiredApplicationFormsCategories,
        action.payload
      );
    },
    addBankDocument(state, action) {
      bankDocumentsEntityAdapter.addOne(state.banksDocuments, action.payload);
    },
    updateBankDocument(state, action) {
      bankDocumentsEntityAdapter.updateOne(state.banksDocuments, action.payload);
    },
    removeBankDocument(state, action) {
      bankDocumentsEntityAdapter.removeOne(state.banksDocuments, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(matchCaseApplicationFormCategoryReceived, (state, action) => {
        requiredAppFormCategoryEntityAdapter.setAll(
          state.requiredApplicationFormsCategories,
          action.payload as ApplicationFormCategory[]
        );
        // action is inferred correctly here if using TS
      })
      .addMatcher(matchSingleCaseApplicationReceived, (state, action) => {
        const { documentCategory: { categories } } = action.payload as MortgageCaseAPIFullRes;
        const appFormsCategories = categories
          ?.filter((cat) => cat?.name?.includes(' - APPLICATION FORM'))
          ?.map((c) => {
            const docsWithCategoryId = mapCategoryDocsWithCatId(c);
            return docsWithCategoryId?.documents;
          }) ?? [];

        bankDocumentsEntityAdapter.setAll(
          state.banksDocuments,
          appFormsCategories.flat() as ApplicationFormFileApiRes[]
        );
      });
  },
});

export const {
  bankFormsCategoryReceived,
  addBankDocument,
  removeBankDocument,
  updateBankDocument,
} = caseBanksFormsCategoriesSlice.actions;
export const selectCaseApplicationForms = (state: RootState) => state.mortgage.caseApplicationFormDetails;

export const {
  selectAll: selectAllBanksDocuments,
  selectById: selectBankDocumentsById,
  selectEntities: selectBankDocumentsEntities,
  // selectIds: selectRequiredApplicationFormsCategoryIds,
} = bankDocumentsEntityAdapter.getSelectors(
  (state: RootState) => state.mortgage.caseApplicationFormDetails.banksDocuments
);
export default caseBanksFormsCategoriesSlice.reducer;
