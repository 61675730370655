/* eslint-disable import/no-cycle */
// TODO: Cyclic dependenices
import { createSlice } from '@reduxjs/toolkit';
import { AnyAction, combineReducers } from 'redux';
import { documentsCategoriesApiSlice } from '@app/modules/core/infrastructure/api/documentsCategoriesApiSlice';
import type { RootState } from '@app/modules/core/infrastructure/store';
import { UserSectionsStepMapper } from '../domain/enums/caseApplicationUserSections';
import {
  MortgageCase, MortgageCaseApiRes, OperationalDataAPIRes
} from '../domain/mortgageCase';
import { extractCaseInfoFromApi } from './api/mortgageApiAdapters';
import { mortgageApiSlice } from './api/mortgageApiSlice';
import caseStatusSlice from './caseStatusSlice';
import createCaseApplicationFormsSlice from './createCaseApplicationFormsSlice';
import createCaseBanksSlice from './createCaseBanksSlice';
import createCaseClientDetailsSlice from './createCaseClientDetailsSlice';
import createCaseClientDocumentsSlice from './createCaseClientDocumentsSlice';
import createCaseIncomeMortgageSlice from './createCaseIncomeMortgageSlice';

export const matchSingleCaseApplicationReceived = mortgageApiSlice.endpoints.getCaseById.matchFulfilled;

export const matchSingleOriginalCaseApplicationReceived = mortgageApiSlice.endpoints.getOriginalCaseById.matchFulfilled;

export const matchOperationalDetailsReceived = mortgageApiSlice.endpoints.getOperationalDetailsOptions.matchFulfilled;

export const matchCaseCreated = mortgageApiSlice.endpoints.registerClient.matchFulfilled;

export const matchCaseApplicationFormCategoryReceived = mortgageApiSlice.endpoints.getRequiredBanksAppForms.matchFulfilled;

export const matchDocsCategoriesReceived = documentsCategoriesApiSlice.endpoints.getDocsCategoriesList.matchFulfilled;

export const matchSaveSelectedBanksUpdated = mortgageApiSlice.endpoints.saveSelectedBanks.matchFulfilled;

export const matchSaveIncomeDataUpdated = mortgageApiSlice.endpoints.saveIncomeInfo.matchFulfilled;

export const matchSaveMortgageDataUpdated = mortgageApiSlice.endpoints.saveMortgageInfo.matchFulfilled;

export const matchDocumentDeleted = mortgageApiSlice.endpoints.removeDocument.matchFulfilled;

export const matchCaseLastSavedSectionUpdated = mortgageApiSlice.endpoints.setCaseCurrentSection.matchFulfilled;

export const matchCaseApplicationFormFileUploading = mortgageApiSlice.endpoints.uploadBankDocument;

const INITIAL_CASES_STATE: { cases: MortgageCase[] } = { cases: [] };

const INITIAL_CASE_APPLICATION_STATE: {
  mortgageApplicationId: number | null;
  mainApplicantId: number | string | null;
  mainApplicantPersonalDetailsId: number | null;
  currentStep: number;
  lastSavedSection: number;
  additionalComment: string | null;
  operationalDetails?: OperationalDataAPIRes | null;
} = {
  mortgageApplicationId: null,
  mainApplicantId: null,
  mainApplicantPersonalDetailsId: null,
  currentStep: 0,
  lastSavedSection: 0,
  additionalComment: '',

};

const casesListSlice = createSlice({
  name: 'casesListSlice',
  initialState: INITIAL_CASES_STATE,
  reducers: {
    setCases: (state, action) => {
      const rawCases = action.payload;
      const formattedCases = rawCases.map((rawCase: MortgageCaseApiRes) => extractCaseInfoFromApi(rawCase));
      state.cases = formattedCases;
    },
  },
});

export const { setCases } = casesListSlice.actions;

const caseApplicationSlice = createSlice({
  name: 'caseApplicationSlice',
  initialState: INITIAL_CASE_APPLICATION_STATE,
  reducers: {
    setCaseMortgageApplicationId: (state, action) => {
      state.mortgageApplicationId = action.payload;
    },
    setCaseCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setCaseLastSavedSection: (state, action) => {
      state.lastSavedSection = action.payload;
    },
    resetCaseApplication: () => INITIAL_CASE_APPLICATION_STATE,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(matchSingleCaseApplicationReceived, (state, action) => {
        const { userSection, selectedBanks } = action.payload;

        // Forcing Banks Step if no Banks Selected and User on A step after Banks
        let currentUserSection = userSection;
        const isPostBankStep = +UserSectionsStepMapper[currentUserSection] > 2;
        if (isPostBankStep && !selectedBanks?.length) {
          currentUserSection = 'mb_portal_select_banks';
        }
        const userSectionStep: number = +UserSectionsStepMapper[currentUserSection];

        state.mortgageApplicationId = action.payload.propertyAndMortgage.id;
        state.mainApplicantId = action.payload.userData.cognitoId;
        state.mainApplicantPersonalDetailsId = action.payload.personalDetails.id;
        state.currentStep = userSectionStep; // userSectionStep;
        state.lastSavedSection = userSectionStep; // userSectionStep;
        state.additionalComment = action.payload.propertyAndMortgage.additionalComment;

        // action is inferred correctly here if using TS
      })
      .addMatcher(matchCaseCreated, (state, action) => {
        state.mortgageApplicationId = action.payload.mortgageApplicationId;
        state.mainApplicantId = action.payload.mainApplicant.cognitoId;
      })
      .addMatcher(matchCaseLastSavedSectionUpdated, (state, action) => {
        const userSection: keyof typeof UserSectionsStepMapper = action.payload.maSection;
        const userSectionStep: number = UserSectionsStepMapper[userSection];
        state.lastSavedSection = userSectionStep;
      })
      .addMatcher(matchOperationalDetailsReceived, (state, action) => {
        state.operationalDetails = action.payload;
      });
  },
});

export const {
  setCaseMortgageApplicationId,
  setCaseCurrentStep,
  setCaseLastSavedSection,
  resetCaseApplication,
} = caseApplicationSlice.actions;

export const selectCurrentCases = (state: RootState) => state.mortgage.cases.cases;
export const selectCaseApplication = (state: RootState) => state.mortgage.caseApplication;
export type CaseStatusType = ReturnType<typeof caseStatusSlice>;
// Root MortgageCases Reducer
const mortgageCasesReducer = combineReducers({
  // Define a top-level state field named `todos`, handled by `todosReducer`
  cases: casesListSlice.reducer,
  caseApplication: caseApplicationSlice.reducer,
  caseClientDetails: createCaseClientDetailsSlice,
  caseIncomeMortgageDetails: createCaseIncomeMortgageSlice,
  caseBanksDetails: createCaseBanksSlice,
  caseApplicationFormDetails: createCaseApplicationFormsSlice,
  caseClientDocumentsDetails: createCaseClientDocumentsSlice,
  mortgageCaseStatusDetails: caseStatusSlice,
});

const resettableMortgageCaseReducer = (
  state: ReturnType<typeof mortgageCasesReducer> | undefined,
  action: AnyAction
) => {
  if (action.type === 'mortgage/caseApplication/resetCaseApplication') {
    return mortgageCasesReducer(undefined, action);
  }

  return mortgageCasesReducer(state, action);
};

export default resettableMortgageCaseReducer;
