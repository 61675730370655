import { MortgageType } from '@app/modules/core/domain/enums/mortgageType';
import { TransactionType } from '@app/modules/core/domain/enums/transactionType';
import capitalizeString from '@app/utils/helpers/capitalizeString';
import formatMoneyAmount from '@app/utils/helpers/formatMoneyAmount';
import { getEmirateLabel } from '@app/utils/helpers/getEmirateLabel';
import { COUNTRIES_LIST } from '@app/utils/info/countries';
import { employmentStatus, transactionTypesReadyProperty } from '@app/utils/info/emirates';
import { ProposalData } from 'modules/proposal/domain/proposals';

import {
  Dependent,
  MortgageCaseAPIFullRes,
  PersonalDetails,
} from '../domain/mortgageApplicationApiRes';
import {
  ApplicationFormDocCategory,
  ApplicationFormFileApiRes,
  CaseTimeLineStatus,
  ClientDocsWithCatIdAndName,
  ClientDocsWithCognitoAndZoneInfo,
  DocumentCategoryFile,
} from '../domain/mortgageCase';
import { DependentDetailsInfo, DependentIncomeInfo } from '../domain/mortgageCaseStatus';

const mapCategoryDocsWithCatId = (cat: ApplicationFormDocCategory) => {
  const documentsWithID = cat?.documents?.map((doc) => ({ ...doc, categoryId: cat.id }));
  return {
    ...cat,
    documents: documentsWithID,
  };
};

type CountryOption = {
  label: string;
  value: number | string;
};

const getKeyByValue = <T>(value: string | any, type: T | any) => {
  const indexOfS = Object.values(type).indexOf(value as unknown as typeof type);
  return Object.keys(type)[indexOfS];
};

const getCountryByCode = (countiresArr: CountryOption[], countryCode: number | null) => {
  if (!countryCode) return '-';
  const nationalityLabel = countiresArr?.find((country) => country?.value === countryCode)?.label;
  return nationalityLabel ?? '-';
};

const getDateFormatted = (date: string | null) => {
  if (!date) return '-';
  return new Date(date).toLocaleDateString('en-GB');
};

const extractIncomeInfo = (personalDetails: PersonalDetails) => ({
  'Fixed monthly salary': formatMoneyAmount(personalDetails?.basicFixedSalary),
  'Additional income': formatMoneyAmount(personalDetails?.additionalMonthlyIncome),
  'Additional income type': capitalizeString(personalDetails?.additionalIncomeType),
  'Total credit card limits': formatMoneyAmount(personalDetails?.totalValueOfCreditCardLimits),
  'Monthly fixed installments': formatMoneyAmount(personalDetails?.totalValueExistingMortgage),
});

const extractAndMapFiles = (
  client: MortgageCaseAPIFullRes & Dependent,
  type: string
): ClientDocsWithCognitoAndZoneInfo => {
  const { firstName, lastName } = type.includes('Main ') ? client.userData : client.user;
  const mappedFiles: ClientDocsWithCatIdAndName[] = [];

  client?.documentCategory?.categories
    .filter((cat) => !cat?.isApplicationForm)
    .forEach((cat) => {
      const { id, documents, name } = cat;
      const files = documents.map((file) => ({
        ...file,
        categoryId: id,
        categoryName: capitalizeString(name),
        clientCognitoId: client?.personalDetails?.user,
      }));
      mappedFiles.push(...files);
    });

  return {
    clientCognitoId: client?.personalDetails?.user,
    files: mappedFiles,
    uploadZoneTitle: `${firstName} ${lastName}'s documents`,
    uploadZoneSubTitle: type,
  };
};

const extractAllClientsDocs = (client: MortgageCaseAPIFullRes & Dependent) => {
  const mainApplicantDocs = extractAndMapFiles(client, 'Main applicant');
  const dependentsDocs: ClientDocsWithCognitoAndZoneInfo[] = [];
  client.dependents.forEach((dependent) => {
    const docs = extractAndMapFiles(
      dependent as MortgageCaseAPIFullRes & Dependent,
      capitalizeString(dependent?.dependentType)
    );
    dependentsDocs.push(docs);
  });
  return [...dependentsDocs, mainApplicantDocs]?.sort((a, b) => (a.uploadZoneTitle < b.uploadZoneTitle ? 1 : -1));
};

const extractDependentsInfo = (dependent: Dependent) => {
  const {
    user: {
      firstName, lastName, mobile, email,
    } = {},
  } = dependent;
  const {
    personalDetails: {
      citizenState,
      typeOfEmployment,
      nationality: Nationality,
      birthDate,
    } = {},
  } = dependent;
  const relationShip = dependent?.relationWithMainApplicant;

  return {
    type: capitalizeString(dependent?.dependentType),
    info: {
      'First name': firstName || '-',
      'Last name': lastName || '-',
      Email: email,
      'Phone number': mobile || '-',
      'Date of birth': getDateFormatted(birthDate),
      Nationality: getCountryByCode(COUNTRIES_LIST, Nationality),
      'Employment status': capitalizeString(
        typeOfEmployment === 'SALARY'
          ? 'Salaried'
          : (typeOfEmployment === 'SELF EMPLOYMENT'
            ? 'Self employed'
            : typeOfEmployment)
      ),
      'Residency status': capitalizeString(citizenState).replace('Uae', 'UAE'),
      Relationship: capitalizeString(relationShip),
    },
  };
};

const extractClientDocs = (caseData: MortgageCaseAPIFullRes | Dependent) => {
  const categoryDocs: Record<string, DocumentCategoryFile[]> = {};

  caseData?.documentCategory?.categories
    .filter((cat) => !cat?.isApplicationForm && cat.documents.length > 0)
    .forEach((docCat) => {
      const categoryName = capitalizeString(docCat?.name);
      const files = docCat?.documents.map((doc) => ({
        nameOfFile: doc?.nameOfFile,
        url: doc?.documentLink,
        categoryName,
      }));
      // clientsDocs[categoryName] = files;
      if (categoryDocs[categoryName]) {
        categoryDocs[categoryName].push(...files);
      } else {
        categoryDocs[categoryName] = files;
      }
    });

  return categoryDocs;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const extractCaseStatusData = (caseData: Dependent & MortgageCaseAPIFullRes) => {
  // Client Data Extraction
  const {
    userData: {
      firstName, lastName, mobile, email, byExternalMbPortal, cognitoId,
    } = {},
  } = caseData;
  const {
    personalDetails: {
      id,
      citizenState,
      typeOfEmployment,
      nationality: Nationality,
      birthDate,
    } = {},
  } = caseData;
  const {
    propertyAndMortgage: {
      id: mortgageId,
      emirate,
      propertyStatus,
      valueOfProperty,
      loanAmount,
      downPaymentPercentage,
      typeOfMortgage,
      mortgageContractYears,
      isFeeFinanced,
      typeOfTransaction,
      additionalComment,
    } = {},
  } = caseData;

  const operationalDetails = {
    'Referrer email': caseData?.operationalData?.referrer?.email ?? '-',
    'Case source': caseData?.operationalData?.businessUnit ?? '-',
    'Deal owner': caseData?.operationalData?.opsOwner?.email ?? '-',
  };

  const dobFormatted = getDateFormatted(birthDate as string);
  const nationalityLabel = getCountryByCode(COUNTRIES_LIST, Nationality);

  const { currentStatus: caseCurrentStatus, dateJoined } = caseData;

  const applicationFormsObj: Record<string, any> = {};
  caseData?.documentCategory?.categories
    .filter((cat) => cat?.isApplicationForm)
    .forEach((bankCat) => {
      applicationFormsObj[bankCat?.name?.split(' - APPLICATION FORM')[0]] = bankCat?.documents.map(
        (doc: ApplicationFormFileApiRes) => ({
          nameOfFile: doc?.nameOfFile,
          id: doc?.id,
          url: doc?.documentLink,
          categoryId: bankCat.id,
        })
      );
    });

  // const selectedBanks = caseData.selectedBanks.map((bank) => bank?.title);
  const { selectedBanks } = caseData;
  const { bankApplicationType } = caseData;
  const { mainApplicantAllSubmittedBanks } = caseData;

  const clientDocs = extractClientDocs(caseData); // TODO Check if this is needed
  const clientDocsObj = {
    clientName: `${firstName} ${lastName}`,
    clientDocs,
  };

  // Client Data Extraction

  // Status History
  const statusTimeLineHistory: CaseTimeLineStatus[] = [];
  Object.entries(caseData.statusHistory).forEach(([key, value]) => {
    const time = new Date(key).toLocaleDateString('en-GB');
    if (value.length > 1) {
      const statusesInOneDay = value.map((statusValue: string) => ({
        time,
        // eslint-disable-next-line sonarjs/no-duplicate-string
        status: statusValue === 'Final documents checking' ? 'Final docs checking' : statusValue,
      }));
      statusTimeLineHistory.push(...statusesInOneDay);
    } else {
      statusTimeLineHistory.push({
        time,
        status: value[0] === 'Final documents checking' ? 'Final docs checking' : value[0],
      });
    }
  });

  const removeOldPendingQueriesStatuses = (statusHistory: CaseTimeLineStatus[]) => {
    if (statusHistory.length <= 1) {
      return statusHistory;
    }

    const pendingQueriesOccurs: number[] = [];

    statusHistory.forEach(
      (item, index) => item?.status === 'Pending queries' && pendingQueriesOccurs.push(index)
    );
    pendingQueriesOccurs.splice(-1);

    return statusHistory.filter((item, index) => !pendingQueriesOccurs.includes(index));
  };

  const refinedStatusHistory = removeOldPendingQueriesStatuses(statusTimeLineHistory);

  // Extract Dependents Info - Case Details Page
  const depenedentsDetails: DependentDetailsInfo[] = [];
  const incomeForCoBorrowers: { name: string; info: DependentIncomeInfo }[] = [];
  const coBorrowersDocs: Record<string, { [key: string]: DocumentCategoryFile[] }> = {};

  caseData?.dependents?.forEach((dependent) => {
    const dependentInfo = extractDependentsInfo(dependent);
    depenedentsDetails.push(dependentInfo);

    if (dependent?.dependentType === 'co-borrower') {
      const dependentIncomeInfo = extractIncomeInfo(dependent?.personalDetails);
      const clientName = `${dependentInfo?.info['First name']} ${dependentInfo?.info['Last name']}`;
      incomeForCoBorrowers.push({
        name: clientName,
        info: dependentIncomeInfo,
      });
      const clientDocs = extractClientDocs(dependent);
      coBorrowersDocs[clientName] = clientDocs;
    }
  });

  // Sort Co-borrowers before Co-applicants
  depenedentsDetails?.sort((a) => (a.type === 'Co-borrower' ? -1 : 1));

  const incomeforMainApplicant = {
    name: `${firstName} ${lastName}`,
    info: extractIncomeInfo(caseData?.personalDetails),
  };

  const downPaymentAmount = (downPaymentPercentage / 100) * valueOfProperty;

  return {
    mainApplicant: {
      'First name': firstName || '-',
      'Last name': lastName || '-',
      Email: email,
      'Phone number': mobile || '-',
      'Date of birth': dobFormatted,
      Nationality: nationalityLabel,
      'Employment status': capitalizeString(
        typeOfEmployment === 'SALARY'
          ? 'Salaried'
          : (typeOfEmployment === 'SELF EMPLOYMENT'
            ? 'Self employed'
            : typeOfEmployment)
      ),
      'Residency status': capitalizeString(citizenState)?.replace('Uae', 'UAE'),
      // TODO Applicant Type - Co-borrower Or Co-applicant
    },
    depenedentsDetails,
    incomeforMainApplicant,
    incomeForCoBorrowers,
    mortgageInfo: {
      Emirate: emirate || '-',
      'Transaction type': typeOfTransaction
        ? getKeyByValue(typeOfTransaction, TransactionType)
        : '-',
      'Mortgage type': typeOfMortgage ? getKeyByValue(typeOfMortgage, MortgageType) : '-',
      'Property status': propertyStatus || '-',
      'Property value': valueOfProperty ? formatMoneyAmount(valueOfProperty) : '-',
      'Downpayment amount': downPaymentAmount ? formatMoneyAmount(downPaymentAmount) : '-',
      'Downpayment percentage': `${
        downPaymentPercentage ? `${formatMoneyAmount(downPaymentPercentage, false)}%` : '-'
      }`,
      'Loan amount': loanAmount ? formatMoneyAmount(loanAmount) : '-',
      'Mortgage Length': mortgageContractYears ? `${mortgageContractYears} years` : '-',
      'Add Residual/Associate fee?': isFeeFinanced
        ? capitalizeString(isFeeFinanced as string)
        : '-',
    },
    personalDetailsId: id,
    mortgageId,
    mainApplicantId: cognitoId,
    banks: selectedBanks,
    bankApplicationType,
    mainApplicantAllSubmittedBanks,
    banksDocs: applicationFormsObj,
    mainApplicantDocs: clientDocsObj,
    coBorrowersDocs,
    statusHistory: refinedStatusHistory,
    caseComments: additionalComment,
    operationalDetails,
    caseCurrentStatus:
      caseCurrentStatus === 'Final documents checking' ? 'Final docs checking' : caseCurrentStatus,
    assignedAccountManager: caseData?.assignedAccountManager,
    submittedFromPortal: byExternalMbPortal,
    createdAt: dateJoined,
  };
};

const prepareReviewSubmitInfo = (caseData: MortgageCaseAPIFullRes, selectedBanks) => {
  const { userData: { firstName, lastName } = {} } = caseData;
  const { typeOfEmployment } = caseData.personalDetails;
  const { typeOfTransaction, loanAmount } = caseData.propertyAndMortgage;
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  caseData.dependents;
  // Prepare Case Overview Data
  const selectedBanksList = selectedBanks?.map((bank) => bank.name);
  const employmentStatusLabel = employmentStatus.find((item) => item?.value?.toLowerCase() === typeOfEmployment?.toLowerCase())
    ?.label || '-';
  const typeOfTransactionLabel = transactionTypesReadyProperty.find(
    (item) => item?.value?.toLowerCase() === typeOfTransaction?.toLowerCase()
  )?.label || '-';

  const dependants = caseData.dependents;

  const getApplicationTypes = () => {
    const dependantTypes = dependants.map((dependant) => dependant?.dependentType);

    return dependantTypes.length === 0
      ? null
      : (dependantTypes.length === 1
        ? dependantTypes[0]
        : `${dependantTypes[0]} & ${dependantTypes[1]}`);
  };

  return {
    'First name': firstName,
    'Last name': lastName,
    'Employment status': employmentStatusLabel,
    'Transaction type': typeOfTransactionLabel,
    'Bank(s)': selectedBanksList,
    'Loan amount': loanAmount,
    'Application types': getApplicationTypes(),
  };
};

const mapCoUserToCase = (coApplicant) => ({
  first_name: coApplicant.first_name,
  last_name: coApplicant.last_name,
  email: null,
  phone_number: null,
  citizen_state: coApplicant.residency_status,
  relation_with_main_applicant: coApplicant.relationship,
  dependent_type: coApplicant.applicant_type,
  ...(coApplicant.applicant_type === 'co-borrower'
    ? { employment_status: coApplicant.employment_status }
    : {}),
});

const populateCaseDataFromProposal = ({ clientInfo, mortgageInfo }: ProposalData) => {
  const {
    first_name,
    last_name,
    email,
    phone_number,
    employment_status,
    residency_status,
    coApplicants,
    coBorrowers,
  } = clientInfo;

  const {
    property_emirate,
    loan_amount,
    property_status,
    property_value,
    downpayment,
    mortgage_type,
    transaction_type,
    mortgage_length,
    residiual_fees,
  } = mortgageInfo;

  const mainApplicant = {
    first_name,
    last_name,
    email,
    phone_number,
    date_of_birth: null,
    country: null,
    employment_status,
    citizen_state: residency_status,
  };

  const coApplicantsInfo = coApplicants?.map((coApplicant) => mapCoUserToCase(coApplicant));
  const coBorrowersInfo = coBorrowers?.map((coBorrower) => mapCoUserToCase(coBorrower));

  const mortgageInfoData = {
    emirate: getEmirateLabel(property_emirate as any) ?? null,
    loan_amount: loan_amount ?? null,
    property_status: property_status ?? null,
    value_of_property: property_value ?? null,
    down_payment_percentage: downpayment ?? null,
    type_of_mortgage: mortgage_type ?? null,
    mortgage_contract_years: mortgage_length ?? null,
    type_of_transaction: transaction_type ?? null,
    is_fee_financed: residiual_fees ? 'yes' : 'no',
  };

  return {
    mainApplicant,
    dependants: { coBorrowers: coBorrowersInfo, coApplicants: coApplicantsInfo },
    mortgageInfoData,
  };
};

export {
  mapCategoryDocsWithCatId,
  extractAllClientsDocs,
  extractCaseStatusData,
  prepareReviewSubmitInfo,
  populateCaseDataFromProposal,
};
