const emirates = [
  {
    label: 'Dubai',
    value: 'Dubai',
  },
  {
    label: 'Abu Dhabi',
    value: 'Abu Dhabi',
  },
  {
    label: 'Sharjah',
    value: 'Sharjah',
  },
  {
    label: 'Ajman',
    value: 'Ajman',
  },
  {
    label: 'Ras Al Khaimah',
    value: 'Ras Al Khaimah',
  },
  {
    label: 'Fujairah',
    value: 'Fujairah',
  },
  {
    label: 'Umm Al Quwain',
    value: 'Umm Al Quwain',
  }
];
export const emiratesProposals = [
  {
    label: 'Dubai',
    value: 'dubai',
  },
  {
    label: 'Abu Dhabi',
    value: 'abu_dhabi',
  },
  {
    label: 'Sharjah',
    value: 'sharjah',
  },
  {
    label: 'Ajman',
    value: 'ajman',
  },
  {
    label: 'Ras Al Khaimah',
    value: 'ras_al_khaimah',
  },
  {
    label: 'Fujairah',
    value: 'fujairah',
  },
  {
    label: 'Umm Al Quwain',
    value: 'umm_al_quwain',
  }
];

export const employmentStatus = [
  {
    label: 'Salaried',
    value: 'SALARY',
  },
  {
    label: 'Self employed',
    value: 'SELF EMPLOYMENT',
  }
];

export const residencyStatus = [
  {
    label: 'UAE National',
    value: 'UAE NATIONAL',
  },
  {
    label: 'UAE Resident',
    value: 'UAE RESIDENT',
  },
  {
    label: 'Non Resident',
    value: 'NON RESIDENT',
  }
];

export const relationShips = [
  {
    label: 'Wife',
    value: 'wife',
  },
  {
    label: 'Husband',
    value: 'husband',
  },
  {
    label: 'Sister',
    value: 'sister',
  },
  {
    label: 'Brother',
    value: 'brother',
  },
  {
    label: 'Daughter',
    value: 'daughter',
  },
  {
    label: 'Son',
    value: 'son',
  },
  {
    label: 'Mother',
    value: 'mother',
  },
  {
    label: 'Father',
    value: 'father',
  }
];

export const additionalIncomeTypes = [
  {
    label: 'Rental',
    value: 'rental',
  },
  {
    label: 'Bonus',
    value: 'bonus',
  },
  {
    label: 'Commission',
    value: 'commission',
  },
  {
    label: 'Allowance',
    value: 'allowance',
  },
  {
    label: 'Other',
    value: 'other',
  }
];

export const propertyStatus = [
  {
    label: 'Ready',
    value: 'Ready',
  },
  {
    label: 'Under Construction',
    value: 'Under Construction',
  }
];

export const mortgageTypes = [
  {
    label: 'Conventional',
    value: 'CONVENTIONAL',
  },
  {
    label: 'Islamic',
    value: 'ISLAMIC',
  },
  {
    label: 'Best possible rate',
    value: 'BEST_POSSIBLE_RATE',
  }
];

export const transactionTypesReadyProperty = [
  {
    label: 'Primary purchase/handover',
    value: 'PRIMARY PURCHASE',
  },
  {
    label: 'Resale/handover resale',
    value: 'BUY A PROPERTY',
  },
  {
    label: 'Buyout + equity',
    value: 'BUYOUT + EQUITY',
  },
  {
    label: 'Buyout',
    value: 'TRANSFER OF EXISTING MORTGAGE',
  },
  {
    label: 'Equity',
    value: 'CASH OUT PROPERTY',
  }
];

export const transactionTypesNotReadyProperty = [
  {
    label: 'Primary purchase/handover',
    value: 'PRIMARY PURCHASE',
  },
  {
    label: 'Resale/handover resale',
    value: 'BUY A PROPERTY',
  }
];

export const banks = [
  {
    label: 'ABU DHABI COMMERCIAL BANK',
    value: 'ABU DHABI COMMERCIAL BANK',
  },
  {
    label: 'ABU DHABI ISLAMIC BANK',
    value: 'ABU DHABI ISLAMIC BANK',
  },
  {
    label: 'ARAB BANK',
    value: 'ARAB BANK',
  },
  {
    label: 'COMMERCIAL BANK OF DUBAI',
    value: 'COMMERCIAL BANK OF DUBAI',
  },
  {
    label: 'DUBAI ISLAMIC BANK',
    value: 'DUBAI ISLAMIC BANK',
  },
  {
    label: 'EMIRATES NBD',
    value: 'EMIRATES NBD',
  },
  {
    label: 'FIRST ABU DHABI BANK',
    value: 'FIRST ABU DHABI BANK',
  },
  {
    label: 'HSBC',
    value: 'HSBC',
  },
  {
    label: 'MASHREQ',
    value: 'MASHREQ',
  },
  {
    label: 'NATIONAL BANK OF FUJAIRAH',
    value: 'NATIONAL BANK OF FUJAIRAH',
  },
  {
    label: 'RAKBANK',
    value: 'RAKBANK',
  },
  {
    label: 'SAMBA',
    value: 'SAMBA',
  },
  {
    label: 'STANDARD CHARTERED BANK',
    value: 'STANDARD CHARTERED BANK',
  }
];

export default emirates;
