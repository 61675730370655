import React from 'react';

interface ApiError {
  status?: number | string;
  originalStatus?: number;
  data: string | any;
  error?: string | any;
}

enum AlertMethod {
  toast = 'toast',
  notification = 'notification'
}

// type AlertMethod = 'notification' | 'toast';
type AlertHandlerRes = {
  alertMethod: AlertMethod;
  errorMessage: string | React.ReactElement;
  errorStatus?: number;
};

const apiErrorAlertHandler = (error: ApiError | any): AlertHandlerRes => {
  let errorMessage = 'Oops! Something went wrong!, please try again later or report this issue to the our team.';
  const alertMethod = AlertMethod.notification;

  const errorStatus = Number(error?.status ?? error?.originalStatus);
  const isOffline = !window.navigator.onLine;
  const isServerNoResponse = !error?.data;
  const isServerError = String(errorStatus).startsWith('5') && !isOffline;
  const isNotAuthorized = errorStatus === 401;
  const hasNoReferrerObject = errorStatus === 403;

  if (isOffline) {
    errorMessage = 'No internet connection. Please reconnect and try again.';
  } else if (isServerNoResponse) {
    errorMessage = 'Server connection error, Please try again or contact support.';
  } else if (isServerError) {
    errorMessage = 'Server error, Please try again or contact support.';
  } else if (isNotAuthorized) {
    errorMessage = 'Your session has expired. Please relogin.';
  } else if (hasNoReferrerObject) {
    errorMessage = `There's something wrong with your account, please contact Huspy for
    support.`;
  }

  return {
    alertMethod,
    errorMessage,
    errorStatus,
  };
};

export { apiErrorAlertHandler };
