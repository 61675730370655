import Box from '@app/components/common/basic/Containers/Box';
import { IconProps } from '@app/modules/core/domain/svgIcon';
import theme from '@app/style/theme';

interface ArrowIconProps extends IconProps {
  direction: 'bottom' | 'left' | 'top';
}
const ArrowIcon = ({
  direction = 'left', size = 20, color = theme.colors.neutral900, ...props
}: ArrowIconProps) => (
  <Box
    m='0 0 -0.5rem'
    transform={ direction === 'bottom'
      ? 'rotate(90deg)'
      : direction === 'left'
        ? 'rotate(180deg)'
        // eslint-disable-next-line unicorn/no-nested-ternary
        : direction === 'top'
          ? 'rotate(-90deg)'
          : null }
    { ...props }
  >
    <svg
      width={ size }
      height={ size }
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5 12H19'
        stroke={ color }
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 5L19 12L12 19'
        stroke={ color }
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </Box>
);

export default ArrowIcon;
