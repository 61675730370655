import { css, keyframes } from 'styled-components';

const zoomIn = keyframes`
0% {
transform: scale(.5);
}

70% {
    transform: scale(1.05);
}
100% {
    transform: scale(1);
}
`;

export const customCss = css`
  animation: ${zoomIn} 0.5s forwards;
`;
