import { IconProps } from '@app/modules/core/domain/svgIcon';
import theme from '@app/style/theme';

const BottomOpenArrow = ({ size, color }: IconProps) => (
  <svg
    width={ size }
    height={ size }
    viewBox='0 0 14 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1 1L7 7L13 1'
      stroke={ color }
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

BottomOpenArrow.defaultProps = {
  size: 10,
  color: theme.colors.gray,
};

export default BottomOpenArrow;
