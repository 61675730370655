import {
  FunctionComponent, useContext, useEffect, useMemo, useState
} from 'react';
import { useSelector } from 'react-redux';
import Box from '@app/components/common/basic/Containers/Box';
import Text from '@app/components/common/basic/Typography/Text';
import { DivFlex } from '@app/style/styledComponents';
import theme from '@app/style/theme';
import assets from '@assets';

import Tooltip from '@app/components/common/basic/Popover/Tooltip';
import { User } from '@app/modules/core/domain/user';
import { selectCurrentUser } from '@app/modules/core/infrastructure/authSlice';
import { screenSizes } from '@app/style/constants';
import eventNames from '@app/utils/events/eventNames';
import logEvent from '@app/utils/events/logEvent';
import AlertSparks from '@assets/icons/Figma-SystemDesign/Alerts/AlertSparks';
import useMedia from '@hooks/useMedia';
import { GlobalContext } from 'contexts/GlobalContext';

const { Close } = assets;

const getOffsetY = (element: any) => {
  let offsetY = 0;
  while (element) {
    offsetY += element.offsetTop;
    // eslint-disable-next-line no-param-reassign
    element = element.offsetParent;
  }
  return offsetY;
};

const RenderTooltip: FunctionComponent<{
  placement: string,
  bankProductTooltipContent: FunctionComponent,
  visible?: boolean,
  isMiniLaptop?: boolean,
}> = ({
  placement = 'right', bankProductTooltipContent, visible, isMiniLaptop,
}) => (
  <Tooltip
    content={ bankProductTooltipContent }
    visible={ visible }
    placement={ placement }
    overlayClassName={ `mb-new-feature-tooltip ${
      isMiniLaptop && ' mb-new-feature-tooltip-mobile'
    }` }
    trigger='click'
    autoAdjustOverflow={ false }
  >
    {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
    <></>
  </Tooltip>
);
interface NewFeatureFixedNotificationProps {
  featureName: string;
  featureContent: string;
  containerId: string;
  mobileContainerId?: string;
  allowedRoles: string[];
}

const NewFeatureFixedNotification: FunctionComponent<NewFeatureFixedNotificationProps> = ({
  featureName,
  featureContent,
  containerId,
  mobileContainerId,
  allowedRoles,
}) => {
  const user = useSelector(selectCurrentUser) as User;
  const isAllowedRole = user?.roles?.find((role) => allowedRoles.includes(role));
  const isManager = user?.roles.includes('manager') || user?.roles.includes('broker_manager');
  const isMiniLaptop = useMedia({ maxWidth: screenSizes.miniLaptop });
  const {
    isSideBarOpen,
    setShowBankProductsDialog,
    welcomeModalVisible,
  } = useContext(GlobalContext);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);

  const [visible, setVisible] = useState(true);

  const hide = () => {
    setVisible(false);
    setShowBankProductsDialog(true);
    localStorage.setItem(featureName, 'visible');
    logEvent({ eventName: eventNames?.newFeatureTooltipCloseIconClicked });
  };

  // debugger
  const initialOffsetX = isMiniLaptop ? 70 : 80;
  const containerElId = isMiniLaptop ? mobileContainerId : containerId;
  const newFeatureInSidebar = document.querySelector(`#${containerElId}`) as HTMLElement;
  const parentMenuEl = isMiniLaptop
    ? newFeatureInSidebar
    : (newFeatureInSidebar?.closest('.ant-menu-item') as HTMLElement);
  let parentElementOffsetX = parentMenuEl?.offsetWidth ?? initialOffsetX;
  const memoizedOffsetY = useMemo(() => getOffsetY(parentMenuEl), [parentMenuEl]);
  let parentElementOffsetY = isMiniLaptop ? 30 : memoizedOffsetY ?? 60;

  const isFeatureHighlightClosed = localStorage.getItem(featureName);
  const managerWidgetHieght = 76;

  useEffect(() => {
    if (newFeatureInSidebar) {
      // TODO: Refactor this
      // eslint-disable-next-line react-hooks/exhaustive-deps
      parentElementOffsetY = isMiniLaptop ? 50 : memoizedOffsetY ?? 0;
      if (isSideBarOpen) {
        // eslint-disable-next-line max-len
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions, react-hooks/exhaustive-deps
        parentElementOffsetX < initialOffsetX && (parentElementOffsetX += isMiniLaptop ? 20 : 240);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        isManager && (parentElementOffsetY += managerWidgetHieght);
      } else {
        parentElementOffsetX = initialOffsetX;
      }
    }
    setOffsetX(parentElementOffsetX);
    setOffsetY(parentElementOffsetY);
  }, [newFeatureInSidebar, isSideBarOpen]);

  useEffect(() => {
    setVisible(!welcomeModalVisible);
  }, [welcomeModalVisible]);

  if (!isAllowedRole) {
    return null;
  }

  const bankProductTooltipContent: FunctionComponent = () => (
    <Box maxW='25rem'>
      <DivFlex alignItems='center'>
        <AlertSparks />
        <Text m='0 0 0 1rem ' fz='1.2rem' fw='600' color={ theme.colors.neutral900 }>
          {featureName}
        </Text>
      </DivFlex>

      <Box position='absolute' top='1.7rem' right='1.5rem' pointer onClick={ hide }>
        <Close color='#000' size='11.5' />
      </Box>
      <DivFlex justifyContent='space-between' alignItems='center'>
        <Text fz='1.2rem' fw='400' m='0' color={ theme.colors.neutral900 }>
          {featureContent}
        </Text>
      </DivFlex>
    </Box>
  );

  if (!newFeatureInSidebar || !!isFeatureHighlightClosed) {
    return null;
  }

  return (
    <div
      className={ isMiniLaptop ? 'zuz' : 'ttwt' }
      style={ {
        zIndex: 99_999_999,
        height: 0,
        width: 0,
        [isMiniLaptop ? 'right' : 'left']: `${offsetX}px`,
        // left: `${offsetX}px`,
        top: `${offsetY + 16}px`,
        position: 'fixed',
      } }
    >
      <RenderTooltip
        placement={ isMiniLaptop ? 'bottom' : 'right' }
        bankProductTooltipContent={ bankProductTooltipContent }
        isMiniLaptop={ isMiniLaptop }
        visible={ visible }
      />
    </div>
  );
};

export default NewFeatureFixedNotification;
