export enum MortgageTypes {
  Conventional = 'CONVENTIONAL',
  Islamic = 'ISLAMIC'
}

export enum EmploymentTypes {
  Salaried = 'SALARY',
  'Self employed' = 'SELF EMPLOYMENT'
}
export enum EmirateType {
  'dubai' = 'Dubai',
  'abu_dhabi' = 'Abu Dhabi',
  'sharjah' = 'Sharjah',
  'ajman' = 'Ajman',
  'ras_al_khaimah' = 'Ras Al Khaimah',
  'fujairah' = 'Fujairah',
  'umm_al_quwain' = 'Umm Al Quwain'
}

export type EmirateTypeValue = `${EmirateType}`;

export type EmirateName =
  | 'Abu Dhabi'
  | 'Dubai'
  | 'Sharjah'
  | 'Ajman'
  | 'Ras Al Khaimah'
  | 'Fujairah'
  | 'Umm Al Quwain';
