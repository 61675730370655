import Box from '@app/components/DesignSystem/Box';
import promotionalPicture from '@assets/promotional.jpg';
import ArrowDiagonalIcon from '@app/assets/icons/ArrowDiagonal';
import { useEffect, useState } from 'react';
import logEvent from '@app/utils/events/logEvent';
import {
  Title, Percentage, Rate, RateComment, Picture, Comment, Link
} from './style';
import CustomModalLayout from '../common/basic/Modal/CustomModalLayout';

const PROMOTION_END_DATE = new Date('2024-05-31');
export const PROMOTION_PERSIST_KEY = 'is_promotion_shown';

const onLinkClick = () => {
  logEvent({ eventName: 'Bank Rate Modal Bank Products Clicked', eventParams: {} });
};

const PromotionalModal = () => {
  const [opened, setOpened] = useState(false);

  const onClose = () => {
    logEvent({ eventName: 'Bank Rate Modal Closed', eventParams: {} });
    setOpened(false);
  };

  useEffect(() => {
    const now = new Date();
    const isShown = localStorage.getItem(PROMOTION_PERSIST_KEY);

    if (now < PROMOTION_END_DATE && !isShown) {
      setOpened(true);
      localStorage.setItem(PROMOTION_PERSIST_KEY, 'true');
    }
  }, []);

  return (
    <CustomModalLayout
      isOpen={ opened }
      onClose={ onClose }
      w='644px'
      h='fit-content'
    >
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
        width='100%'
        textAlign='center'
      >
        <Box mb={ 32 }>
          <Title>Exclusive with Huspy</Title>

          <Box textAlign='center'>
            <Percentage>3.89%</Percentage>
          </Box>

          <Rate>Lowest 1 or 2-year fixed rate</Rate>

          <RateComment>without salary transfer</RateComment>
        </Box>

        <Box mb={ 32 } width='100%'>
          <Picture src={ promotionalPicture } alt='promotional' />
        </Box>

        <Box mb={ 32 }>
          <Comment>Valid for disbursals till 31 may 2024</Comment>
        </Box>

        <Link
          href='/bank-products?is_exclusive=true'
          target='__blank'
          onClick={ onLinkClick }
        >
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection='row'
            width='100%'
            textAlign='center'
          >
            Go to bank products
            <Box ml={ 16 }>
              <ArrowDiagonalIcon />
            </Box>
          </Box>
        </Link>
      </Box>
    </CustomModalLayout>
  );
};

export default PromotionalModal;
