const CallIcon = () => (
  <svg
    width='48'
    height='48'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle opacity='0.1' cx='24' cy='24' r='24' fill='#205EFF' />
    <path
      d='M32.3333 28.1V30.6C32.3343 30.8321 32.2867 31.0618 32.1938 31.2744C32.1008 31.4871 31.9644 31.678 31.7934 31.8349C31.6224 31.9918 31.4205 32.1112 31.2006 32.1856C30.9808 32.2599 30.7478 32.2875 30.5167 32.2666C27.9524 31.988 25.4892 31.1118 23.325 29.7083C21.3115 28.4289 19.6045 26.7218 18.325 24.7083C16.9167 22.5343 16.0402 20.0591 15.7667 17.4833C15.7458 17.2529 15.7732 17.0206 15.8471 16.8013C15.9209 16.5821 16.0397 16.3806 16.1956 16.2097C16.3516 16.0388 16.5415 15.9022 16.7532 15.8087C16.9648 15.7153 17.1936 15.6669 17.425 15.6666H19.925C20.3294 15.6627 20.7215 15.8059 21.0281 16.0696C21.3348 16.3333 21.5351 16.6995 21.5917 17.1C21.6972 17.9 21.8929 18.6856 22.175 19.4416C22.2871 19.7399 22.3114 20.0641 22.2449 20.3757C22.1785 20.6874 22.0241 20.9734 21.8 21.2L20.7417 22.2583C21.928 24.3446 23.6554 26.072 25.7417 27.2583L26.8 26.2C27.0266 25.9759 27.3126 25.8215 27.6243 25.7551C27.9359 25.6886 28.2601 25.7129 28.5583 25.825C29.3144 26.1071 30.1 26.3028 30.9 26.4083C31.3048 26.4654 31.6745 26.6693 31.9388 26.9812C32.2031 27.2931 32.3435 27.6913 32.3333 28.1Z'
      stroke='#205EFF'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default CallIcon;
