/* eslint-disable sonarjs/cognitive-complexity */
import { AUTHENTICATION_COOKIE, AUTHENTICATION_REFRESH_COOKIE } from '@app/utils/constants';
import Cookies from '@app/utils/cookies';
// eslint-disable-next-line import/no-cycle
import { dataDogErrorLogger, dataDogInfoLogger } from '@app/modules/core/infrastructure/api/apiErrorsLogger';
import { REFRESH_TOKEN_ENDPOINT } from '@app/modules/core/infrastructure/api/constants';
import { getAuthenticatorIdFromToken, setAuthCookies } from '@app/modules/core/infrastructure/authHelpers';
import { logOut, setCredentials } from '@app/modules/core/infrastructure/authSlice';
import { store } from '@app/modules/core/infrastructure/store';
// eslint-disable-next-line import/no-cycle
import { fetchBrokerProfile } from '../hooks/useFetchBrokerProfile';
import { HuspyApiError } from './error';

type GetNewTokenResponse = {
  access_token: string;
  expires_in: number;
  token_type: string;
};

const ContentTypeApplicationJson = 'application/json';

export class HttpClient {
  private readonly loggerClientName = 'react-query';

  async get<T extends {}>(url: RequestInfo | URL, headers?: HeadersInit) {
    return this.request<T>(url, 'GET', null, headers);
  }

  async post<T extends {}>(url: RequestInfo | URL, body: {}, headers?: HeadersInit, sendAuthorizationHeaders?: boolean) {
    return this.request<T>(url, 'POST', body, headers, sendAuthorizationHeaders);
  }

  async update<T extends {}>(url: RequestInfo | URL, body: {}, headers?: HeadersInit) {
    return this.request<T>(url, 'PUT', body, headers);
  }

  async delete<T extends {}>(url: RequestInfo | URL, headers?: HeadersInit) {
    return this.request<T>(url, 'DELETE', null, headers);
  }

  async patch<T extends {}>(url: RequestInfo | URL, body: {}, headers?: HeadersInit) {
    return this.request<T>(url, 'PATCH', body, headers);
  }

  private async request<T extends {}>(
    url: RequestInfo | URL,
    method: string,
    body: {},
    headers: HeadersInit = {},
    sendAuthorizationHeaders = true
  ): Promise<T> {
    let defaultHeaders = {};
    if (sendAuthorizationHeaders) {
      const token = Cookies.get(AUTHENTICATION_COOKIE);
      defaultHeaders = {
        Authorization: `Bearer ${token}`,
        'x-source-product': 'brokers',
      };
    }

    if (!(body instanceof FormData)) {
      defaultHeaders['Content-Type'] = ContentTypeApplicationJson;
    }

    const config: RequestInit = {
      method,
      headers: {
        ...defaultHeaders,
        ...headers,
      },
    };

    if (body instanceof FormData) {
      config.body = body;
    } else if (body) {
      config.body = JSON.stringify(body);
    } else {
      config.body = null;
    }

    const response = await fetch(url, config);

    if (response.status === 503) {
      window.location.replace('/maintenance/index.html');
    }

    if (!response.ok && response.status === 401) {
      const reauthData = await this.reauth();
      if (reauthData.access_token) {
        setAuthCookies(reauthData.access_token);
        const authenticatorId = getAuthenticatorIdFromToken(reauthData.access_token);
        const user_profile = await fetchBrokerProfile(authenticatorId);
        store.dispatch(setCredentials({ token: reauthData.access_token, user: user_profile.data }));
      } else {
        store.dispatch(logOut());
        const { user } = store.getState().auth;
        dataDogErrorLogger(user?.profile?.raw, reauthData, { url, httpClient: this.loggerClientName });
        throw new HuspyApiError('Couldn\'t reauthenticate', reauthData, 400);
      }
    }

    if (!response.ok && response.status === 500) {
      throw new HuspyApiError(
        'Server error, Please try again or contact support.',
        null,
        response.status
      );
    }

    try {
      if (response?.headers?.get('Content-Type')?.includes(ContentTypeApplicationJson)) {
        const data = await response.json();
        if (!response.ok) {
          throw new HuspyApiError(
            'Failed to fetch API endpoint',
            data,
            response.status
          );
        }
        const { user } = store.getState().auth;
        dataDogInfoLogger(user?.profile?.raw, {
          httpClient: this.loggerClientName,
          url,
        });
        return data;
      }
      // Handle non-JSON responses here, for example:
      const text = await response.text();
      if (text.includes('Not Found')) {
        throw new HuspyApiError(
          'Failed to fetch API endpoint',
          {},
          response.status
        );
      }
      throw new Error(`Expected JSON, but received: ${text}`);
    } catch (error) {
      const { user } = store.getState().auth;
      dataDogErrorLogger(user?.profile?.raw, error, {
        body,
        url,
        httpClient: this.loggerClientName,
      });
      throw error;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private async reauth(): Promise<GetNewTokenResponse | null> {
    const refreshToken = Cookies.get(AUTHENTICATION_REFRESH_COOKIE);

    const res = await fetch(REFRESH_TOKEN_ENDPOINT, {
      method: 'POST',
      headers: { 'Content-Type': ContentTypeApplicationJson },
      body: JSON.stringify({ refresh_token: refreshToken }),
    });

    return res.json();
  }
}

export default new HttpClient();
