import styled from 'styled-components';
import commonCss from '@components/common/basic/common';

export const DivFlex = styled.div`
  ${commonCss}
  display: ${({ display }) => display || 'flex'};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  flex-basis: ${({ flexBasis }) => flexBasis};
  flex-grow: ${({ flexGrow }) => flexGrow};
  flex: ${({ flex }) => flex};
  gap: ${({ gap }) => gap};

  &:hover {
    background-color: ${({ hbgc }) => hbgc};
  }

  ${({ customCss }) => customCss};
`;

DivFlex.defaultProps = {
  m: 0,
  md: null,
  ml: null,
  mt: null,
  mm: null,
  fullHeight: false,
};
