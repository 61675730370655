/* eslint-disable import/no-cycle */
// TODO: Dependency cycle
import { configureStore } from '@reduxjs/toolkit';
import bankProductsReducer from '@app/modules/bankProducts/infrastructure/bankProductsSlice';
import { documentsCenterApiSlice } from '@app/modules/documents/infrastructure/api/documentCenterApiSlice';
import mortgageReducer from '@app/modules/mortgage/infrastructure/mortgageSlice';
import proposalReducer from '@app/modules/proposal/infrastructure/proposalSlice';
import opportunityReducer from '@app/modules/superMortgage/store/opportunitySlice';
import alertReducer from './alertSlice';
import { apiSlice } from './api/apiSlice';
import { countriesApiSlice } from './api/countriesApiSlice';
import authReducer from './authSlice';
import commonSliceReducer from './commonSlice';

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [documentsCenterApiSlice.reducerPath]: documentsCenterApiSlice.reducer,
    [countriesApiSlice.reducerPath]: countriesApiSlice.reducer,
    common: commonSliceReducer,
    auth: authReducer,
    alert: alertReducer,
    mortgage: mortgageReducer,
    proposal: proposalReducer,
    bankProducts: bankProductsReducer,
    opportunity: opportunityReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
    // eslint-disable-next-line unicorn/prefer-spread
    .concat(apiSlice.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
