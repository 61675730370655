import jwtDecode from 'jwt-decode';
import { TokenUser } from '@app/modules/core/domain/user';
import {
  AUTHENTICATION_COOKIE,
  AUTHENTICATION_REFRESH_COOKIE,
  AUTHENTICATION_USER_ACCESS,
  AUTHENTICATION_USER_HS_ID,
  AUTHENTICATION_USER_ROLES,
  SAVED_USER_UTM_SOURCE,
} from '@app/utils/constants';
import Cookies from '@app/utils/cookies';
import envProvider from '@app/utils/helpers/envProvider';
import { PROMOTION_PERSIST_KEY } from '@app/components/PromotionalModal';

const INVALID_CREDENTIALS = 'Incorrect email or password';
const EMAIL_NOT_CONFIRMED = 'Please confirm your email address and try again';
const NEW_PASSWORD_NOT_VALID = 'Password should be at least 8 characters, including one uppercase letter, one lowercase letter, one number and one special character.';

export const mapTokenToUser = (token: string) => {
  const user: TokenUser = jwtDecode(token);

  return {
    firstName: user?.['custom:first_name'],
    lastName: user?.['custom:last_name'],
    username: user?.['cognito:username'],
    mobile: user?.['custom:mobile'],
    phone_number: user?.phone_number,
    email: user?.email,
    isEmailVerified: user?.email_verified,
    raw: { ...user },
  };
};

export const getAuthenticatorIdFromToken = (token: string) => {
  const decodeParams = jwtDecode(token);

  return decodeParams?.['cognito:username'];
};

const setAuthCookies = (token: string, refreshToken?: string, user?: Record<string, any>) => {
  Cookies.remove(AUTHENTICATION_COOKIE);
  Cookies.remove(AUTHENTICATION_REFRESH_COOKIE);
  const cookieAttributes = { domain: envProvider.AUTHENTICATION_COOKIE_DOMAIN_ATTRIBUTE };

  Cookies.set(AUTHENTICATION_USER_ROLES, ['broker', 'sales']?.join(','), cookieAttributes);
  Cookies.set(AUTHENTICATION_COOKIE, token, cookieAttributes);
  if (refreshToken) {
    Cookies.set(AUTHENTICATION_REFRESH_COOKIE, refreshToken, cookieAttributes);
  }
  if (user) {
    Cookies.set(AUTHENTICATION_USER_HS_ID, user?.hsObjectId);
    Cookies.set(AUTHENTICATION_USER_ACCESS, user.isGrantedAccessToMbPortal, cookieAttributes);
  }
};

const getAuthCookies = () => {
  const token = Cookies.get(AUTHENTICATION_COOKIE);
  const refreshToken = Cookies.get(AUTHENTICATION_REFRESH_COOKIE);
  const hubSpotId = Cookies.get(AUTHENTICATION_USER_HS_ID);
  const isGrantedFullAccess = Cookies.get(AUTHENTICATION_USER_ACCESS) === 'true' ?? false;
  const roles = Cookies.get(AUTHENTICATION_USER_ROLES)?.split(',');

  if (!token) return {};
  return {
    token,
    refreshToken,
    roles,
    hubSpotId,
    isGrantedFullAccess,
  };
};

const removeAuthCookies = () => {
  Cookies.remove(AUTHENTICATION_COOKIE);
  Cookies.remove(AUTHENTICATION_REFRESH_COOKIE);
  Cookies.remove(AUTHENTICATION_USER_ROLES);
  Cookies.remove(AUTHENTICATION_USER_HS_ID);
  Cookies.remove(AUTHENTICATION_USER_ACCESS);
  const cookieAttributes = { domain: envProvider.AUTHENTICATION_COOKIE_DOMAIN_ATTRIBUTE };
  Cookies.remove(AUTHENTICATION_COOKIE, cookieAttributes);
  Cookies.remove(AUTHENTICATION_REFRESH_COOKIE, cookieAttributes);
  Cookies.remove(AUTHENTICATION_USER_ROLES, cookieAttributes);
  Cookies.remove(AUTHENTICATION_USER_HS_ID, cookieAttributes);
  Cookies.remove(AUTHENTICATION_USER_ACCESS, cookieAttributes);
};

const removePersistedData = () => {
  localStorage.setItem(PROMOTION_PERSIST_KEY, '');
};

const saveUserUtmSource = ({ location }: { location: any }) => {
  const queryParams = new URLSearchParams(location?.search);
  const userSource = {
    utm_source: queryParams.get('utm_source'),
    utm_medium: queryParams.get('utm_medium'),
    utm_campaign: queryParams.get('utm_campaign') || queryParams.get('campaign'),
    keyword: queryParams.get('keyword') || queryParams.get('utm_term'),
    adset: queryParams.get('adset'),
    content: queryParams.get('content'),
    campaign: '',
  };
  if (Object.values(userSource).some(Boolean)) {
    userSource.campaign = window.location.href;
    localStorage.setItem(SAVED_USER_UTM_SOURCE, JSON.stringify(userSource));
  }
};

const getErrorMessageByCode = (number: number, originalMessage: string) => {
  switch (number) {
    case 1001: {
      return INVALID_CREDENTIALS;
    }
    case 1002: {
      return INVALID_CREDENTIALS;
    }
    case 1003: {
      return INVALID_CREDENTIALS;
    }
    case 1004: {
      return EMAIL_NOT_CONFIRMED;
    }
    case 1005: {
      return EMAIL_NOT_CONFIRMED;
    }
    case 1016: {
      return NEW_PASSWORD_NOT_VALID;
    }
    case 9999: {
      return `Currently, you don't have access to the Brokers Portal. Please
      send an email message to ma-uae@huspy.io`;
    }
    default: {
      return originalMessage ?? 'Unknown Error';
    }
  }
};

export {
  getAuthCookies,
  setAuthCookies,
  removeAuthCookies,
  removePersistedData,
  saveUserUtmSource,
  getErrorMessageByCode,
};
