const envProvider = {
  PUBLIC_URL: process.env.PUBLIC_URL,
  SERVERPATH: process.env.REACT_APP_SERVERPATH ?? '',
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT ?? '',
  RECAPTCHA_KEY: process.env.REACT_APP_RECAPTCHA_KEY ?? '',
  DATADOG_APP_ID: process.env.REACT_APP_DATADOG_APP_ID ?? '',
  DATADOG_CLIENT_TOKEN: process.env.REACT_APP_DATADOG_CLIENT_TOKEN ?? '',
  DATADOG_ENV: process.env.REACT_APP_DATADOG_ENV ?? '',
  AMPLITUDE_FEATURE_FLAGS_API_URL: process.env.REACT_APP_AMPLITUDE_FEATURE_FLAGS_API_URL ?? '',
  AMPLITUDE_FEATURE_FLAGS_API_KEY: process.env.REACT_APP_AMPLITUDE_FEATURE_FLAGS_API_KEY ?? '',
  AMPLITUDE_API_KEY: process.env.REACT_APP_AMPLITUDE_API_KEY ?? '',
  ID_2_0_REDIRECT_URL: process.env.REACT_APP_ID_2_0_REDIRECT_URL ?? '',
  ID_2_0_SIGNUP_REDIRECT_URL: process.env.REACT_APP_ID_2_0_SIGNUP_REDIRECT_URL ?? '',
  ID_2_0_FORGOT_PASSWORD_REDIRECT_URL: process.env.REACT_APP_ID_2_0_FORGOT_PASSWORD_REDIRECT_URL ?? '',
  ID_2_0_FE_BASE_URL: process.env.REACT_APP_ID_2_0_FE_BASE_URL ?? '',
  CLIENT_HUB_URL: process.env.REACT_APP_CLIENT_HUB_URL ?? '',
  AUTHENTICATION_COOKIE_DOMAIN_ATTRIBUTE: process.env.REACT_APP_AUTHENTICATION_COOKIE_DOMAIN_ATTRIBUTE ?? '',
};

export default envProvider;
