import React, { FunctionComponent } from 'react';
import { DivFlex } from '@app/style/styledComponents';
import figmaTheme from '@app/style/theme';
import Close from '@assets/icons/Figma-SystemDesign/Form-Validation/Close';
import Box from '../../Containers/Box';
import { customCss } from './style';

interface ModalProps {
  children: React.ReactNode;
  modalTitle?: React.ReactNode;
  onClose: Function;
  isOpen: Boolean;
  hideCloseButton?: Boolean;
  w?: string;
  wML?: string;
  wT?: string;
  minW?: string;
  h?: string;
}
const CustomModalLayout: FunctionComponent<ModalProps> = ({
  children,
  onClose,
  isOpen,
  hideCloseButton,
  modalTitle,
  minW,
  w = 'max-content',
  wML = '70vw',
  wT,
  h,
}) => (
  <>
    {isOpen && (
      <DivFlex
        position='fixed'
        top='0'
        right='0'
        bottom='0'
        left='0'
        bgc='rgba(0,0,0,0.75)'
        zIndex='10000'
        justifyContent='center'
        alignItems='center'
      >
        <Box position='relative' customCss={ customCss } w={ w } minW={ minW } wML={ wML } wT={ wT }>
          <Box
            bgc={ figmaTheme.colors.neutral100 }
            h={ h || '95vh' }
            w='100%'
            p='2.5rem'
            overflow='auto'
            overflowX='hidden !important'
          >
            <DivFlex
              justifyContent={ modalTitle ? 'space-between' : 'flex-end' }
              alignItems='center'
              h='max-content'
              position='absolute'
              right='2rem'
              zIndex='1000'
              customCss={ {} }
            >
              {modalTitle && <Box>{modalTitle}</Box>}

              {!hideCloseButton && (
                <Box
                  bgc={ figmaTheme.colors.neutral900 }
                  w='4.8rem'
                  h='4.8rem'
                  borderRadius='50%'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  pointer
                  onClick={ onClose }
                >
                  <Close color='#fff' size='16' />
                </Box>
              )}
            </DivFlex>

            {children}
          </Box>
        </Box>
      </DivFlex>
    )}
  </>
);

export default CustomModalLayout;
