import Box from '@app/components/common/basic/Containers/Box';
import theme from '@app/style/theme';
import CheckCircle from '../../icons/CheckCircle';

const MortgageOverviewIcon = ({ type }) => {
  let hollowColor = theme.colors.purple700;
  let objectColor = theme.colors.purple700;

  let hollowOpacity = 0.1;

  switch (type) {
    case 'main': {
      hollowColor = theme.colors.purple700;
      objectColor = theme.colors.purple700;
      break;
    }
    case 'blurred': {
      hollowColor = theme.colors.gray;
      objectColor = theme.colors.gray;
      break;
    }
    case 'selected': {
      hollowColor = theme.colors.purple700;
      objectColor = theme.colors.white;
      hollowOpacity = 1;
      break;
    }
    case 'success': {
      hollowColor = theme.colors.green;
      objectColor = theme.colors.green;
      break;
    }
    case 'progress': {
      hollowColor = theme.colors.orangeLight;
      objectColor = theme.colors.orangeLight;
      break;
    }
    case 'white': {
      hollowColor = theme.colors.white;
      objectColor = theme.colors.white;
      break;
    }
    default: {
      break;
    }
  }

  return (
    <Box position='relative'>
      <Box position='absolute' top='-4px' right='-4px' hidden={ type !== 'accomplished' }>
        <CheckCircle />
      </Box>

      <svg
        width='32'
        height='32'
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle opacity={ hollowOpacity } cx='16' cy='16' r='16' fill={ hollowColor } />
        <path
          d='M16 9.33334L9.33333 12.6667L16 16L22.6667 12.6667L16 9.33334Z'
          stroke={ objectColor }
          strokeWidth='1.4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.33333 19.3333L16 22.6667L22.6667 19.3333'
          stroke={ objectColor }
          strokeWidth='1.4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.33333 16L16 19.3333L22.6667 16'
          stroke={ objectColor }
          strokeWidth='1.4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Box>
  );
};

MortgageOverviewIcon.defaultProps = { type: 'main', // main, blurred, selected
};

export default MortgageOverviewIcon;
