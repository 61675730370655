import styled from 'styled-components';

const Span = styled.span`
  display: ${({ display, hidden }) => (hidden ? 'none' : display)};
  color: ${({ color }) => color};
  cursor: ${({ pointer }) => pointer && 'pointer'};
  font-weight: ${({ fw }) => fw};
  margin: ${({ m }) => m};
`;

export default Span;
