import Box from '@app/components/common/basic/Containers/Box';
import theme from '@app/style/theme';
import CheckCircle from '../../icons/CheckCircle';

const PersonalDetailsIcon = ({ type }) => {
  let hollowColor = theme.colors.purple700;
  let objectColor = theme.colors.purple700;

  let hollowOpacity = 0.1;

  switch (type) {
    case 'main': {
      hollowColor = theme.colors.purple700;
      objectColor = theme.colors.purple700;
      break;
    }
    case 'blurred': {
      hollowColor = theme.colors.gray;
      objectColor = theme.colors.gray;
      break;
    }
    case 'selected': {
      hollowColor = theme.colors.purple700;
      objectColor = theme.colors.white;
      hollowOpacity = 1;
      break;
    }
    case 'success': {
      hollowColor = theme.colors.green;
      objectColor = theme.colors.green;
      break;
    }
    case 'progress': {
      hollowColor = theme.colors.orangeLight;
      objectColor = theme.colors.orangeLight;
      break;
    }
    case 'white': {
      hollowColor = theme.colors.white;
      objectColor = theme.colors.white;
      break;
    }
    default: {
      break;
    }
  }

  return (
    <Box position='relative'>
      <Box position='absolute' top='-4px' right='-4px' hidden={ type !== 'accomplished' }>
        <CheckCircle />
      </Box>

      <svg
        width='32'
        height='32'
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle opacity={ hollowOpacity } cx='16' cy='16' r='16' fill={ hollowColor } />
        <path
          d='M21.3333 22V20.6667C21.3333 19.9594 21.0524 19.2811 20.5523 18.781C20.0522 18.281 19.3739 18 18.6667 18H13.3333C12.6261 18 11.9478 18.281 11.4477 18.781C10.9476 19.2811 10.6667 19.9594 10.6667 20.6667V22'
          stroke={ objectColor }
          strokeWidth='1.4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M16 15.3333C17.4728 15.3333 18.6667 14.1394 18.6667 12.6667C18.6667 11.1939 17.4728 10 16 10C14.5272 10 13.3333 11.1939 13.3333 12.6667C13.3333 14.1394 14.5272 15.3333 16 15.3333Z'
          stroke={ objectColor }
          strokeWidth='1.4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Box>
  );
};

PersonalDetailsIcon.defaultProps = { type: 'main', // main, blurred, selected
};

export default PersonalDetailsIcon;
