import antNotification, { NotificationApi } from 'antd/lib/notification';

type NotificationTypes = Omit<NotificationApi, 'close' | 'useNotification'>;
type NotificationOptions<T extends keyof NotificationTypes> = Parameters<NotificationTypes[T]> extends [infer U] ? U : null;

const notify = <T extends keyof NotificationTypes>(type: T, options: NotificationOptions<T>) => {
  (antNotification[type])({
    // There's something wrong with TS here, as it seems to think that options is not an object,
    // even though autocomplete works
    // @ts-ignore
    ...options,
    key: `${Date.now()}`,
  });
};

export default notify;
