const apiMoneyFormatter = (amount: string | number | null): string | number | null => {
  if (!amount) {
    return null;
  }
  const result = Number(amount)
    ?.toFixed(2)
    ?.replace(/\.00.*$/, '');

  return result === '0' ? '' : +result;
};

export { apiMoneyFormatter };
