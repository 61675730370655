/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { datadogLogs } from '@datadog/browser-logs';
import { isSensetiveDataEndpoint } from '@app/modules/core/infrastructure/api/apiErrorsLogger';
import envProvider from '@app/utils/helpers/envProvider';

export function initDatadogLogger() {
  datadogLogs.init({
    clientToken: envProvider.DATADOG_CLIENT_TOKEN!,
    site: 'datadoghq.eu',
    service: 'huspy-ma-web',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    env: envProvider.DATADOG_ENV,
    beforeSend: (log) => {
      if ((log.body as any)?.password) {
        // eslint-disable-next-line no-param-reassign
        (log.body as any).password = '*****';
      }
      // Discard sensetiveDataEndpoints req body
      if (log.url && isSensetiveDataEndpoint(log.url as string)) {
        // eslint-disable-next-line no-param-reassign
        log.body = {};
      }
    },
  });
}
