enum TransactionType {
  'Primary/Resale/Handover' = 'PRIMARY/RESALE/HANDOVER',
  'Handover payment' = 'FINALE HANDOVER PAYMENT',
  'Primary purchase' = 'PRIMARY PURCHASE',
  'Resale' = 'BUY A PROPERTY',
  'Buyout + equity' = 'BUYOUT + EQUITY',
  'Buyout' = 'TRANSFER OF EXISTING MORTGAGE',
  'Equity' = 'CASH OUT PROPERTY',
  'Handover resale' = 'HANDOVER RESALE'
}

// type TransactionValueType = typeof TransactionType[keyof typeof TransactionType];
type TransactionValueType = `${TransactionType}`;

export { TransactionType };
export type { TransactionValueType };
